import { useContent } from '@hooks/use-server-content';

export interface CommunicationPreferenceLabels {
    hide: boolean;
    pageHeader: string;
    pageDescription: string;
    preferencesText: string;
    editButtonText: string;
    editButtonAriaLabel: string;
    sectionHeader: string;
    emailOptionHeader: string;
    emailOptionDescription: string;
    confirmUnsubcribeModalHeader: string;
    confirmUnsubscribeModalDescription: string;
    successModalHeader: string;
    btnSave: string;
    btnCancel: string;
    btnConfirm: string;
    successModalBtn: string;
    apiErrorMessageForECPTitle: string;
    apiErrorMessageForECPDescription: string;
    successModalAriaLabel: string;
    unsubscribeModalAriaLabel: string;
    preferencesSubHeader: string;
    yesSubscribe: string;
    noUnSubscribe: string;
    unSubscribeBanner: string;
    subscribeBanner: string;
    subscribeUpdateErrorBanner: string;
    editButton: string;
    cancelButtonLabelText: string;
    cancelButtonAriaLabel: string;
}

export const useCommunicationPreferencesContent =
    (): CommunicationPreferenceLabels | null => {
        const [content, getValueByName] = useContent(
            'common',
            'communication-preferences'
        );
        const contentFragment: any = {};
        if (content) {
            content?.elements.forEach((element) => {
                contentFragment[`${element.name}`] = getValueByName(
                    `${element.name}`
                );
            });
        }
        return contentFragment as CommunicationPreferenceLabels;
    };
