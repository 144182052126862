import { useContent } from '@hooks/use-server-content';

export interface ConnectedServicesErrorContent {
    vinNotFoundInUserGarageText: string;
    primaryButtonLabelText: string;
    primaryButtonAriaLabel: string;
}

export const useConnectedServicesErrorContent =
    (): ConnectedServicesErrorContent | null => {
        const [content, getValueByName] = useContent(
            'common',
            'connected-services-errors'
        );
        const contentFragment: any = {};
        if (content) {
            content?.elements.forEach((element) => {
                contentFragment[`${element.name}`] = getValueByName(
                    `${element.name}`
                );
            });
        }
        return contentFragment as ConnectedServicesErrorContent;
    };
