import { AppEnvironmentConfiguration } from '../app-configuration';

export const appConfigurationPerf: AppEnvironmentConfiguration = {
    countryConfigurations: [
        {
            name: 'Mothersite',
            countryCode: 'mothersite',
            threeLetterCountryCode: 'gbr',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-658deca8deb3-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.brandeulb.ford.com',
                    root: '/content/guxeu/mothersite/en_gb/home/owners/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'USA',
            countryCode: 'us',
            threeLetterCountryCode: 'usa',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/11f5ac9aea6e/launch-9c6de279ddb8-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/en-us/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/es-us/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'esperf.globalaccount.ford.com',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa.ford.com',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'esqa.ford.com',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/lincoln/en-us/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 3,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/lincoln/es-us/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 3,
                },
                {
                    domain: 'wwwperf.globalaccount.lincoln.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 3,
                },
                {
                    domain: 'esperf.globalaccount.lincoln.com',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 3,
                },
                {
                    domain: 'wwwqa.lincoln.com',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 2,
                },
                {
                    domain: 'esqa.lincoln.com',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-us',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Canada',
            countryCode: 'ca',
            threeLetterCountryCode: 'can',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-658deca8deb3-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/en-ca/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/fr-ca/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.ca',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'frperf.globalaccount.ford.ca',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa.ford.ca',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'frqa.ford.ca',
                    root: '/myaccount/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/lincoln/en-ca/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 3,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/lincoln/fr-ca/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 3,
                },
                {
                    domain: 'wwwperf.globalaccount.lincolncanada.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 3,
                },
                {
                    domain: 'frperf.globalaccount.lincolncanada.com',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 3,
                },
                {
                    domain: 'wwwqa.lincolncanada.com',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 3,
                },
                {
                    domain: 'frqa.lincolncanada.com',
                    root: '/myaccount/',
                    language: 'Français',
                    languageRegionCode: 'fr-ca',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 3,
                },
            ],
        },
        {
            name: 'Sweden',
            countryCode: 'se',
            threeLetterCountryCode: 'swe',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl: '',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/sv-se/',
                    language: 'English',
                    languageRegionCode: 'sv-se',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.se',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'sv-se',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa.seaccount.ford.com',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'sv-se',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'United Kingdom',
            countryCode: 'gb',
            threeLetterCountryCode: 'gbr',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/en-gb/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13891,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.co.uk',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13891,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/account-management/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-gb',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Ireland',
            countryCode: 'ie',
            threeLetterCountryCode: 'irl',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/en-ie/',
                    language: 'English',
                    languageRegionCode: 'en-ie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.ie',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.ie',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-ie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Switzerland',
            countryCode: 'ch',
            threeLetterCountryCode: 'che',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/de-ch/',
                    language: 'German',
                    languageRegionCode: 'de-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'deperf.globalaccount.ford.ch',
                    root: '/',
                    language: 'German',
                    languageRegionCode: 'de-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'deqa.ford.ch',
                    root: '/myaccount/',
                    language: 'German',
                    languageRegionCode: 'de-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/fr-ch/',
                    language: 'Français',
                    languageRegionCode: 'fr-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'frperf.globalaccount.ford.ch',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'frqa.ford.ch',
                    root: '/myaccount/',
                    language: 'Français',
                    languageRegionCode: 'fr-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/it-ch/',
                    language: 'Italian',
                    languageRegionCode: 'it-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'itperf.globalaccount.ford.ch',
                    root: '/',
                    language: 'Italian',
                    languageRegionCode: 'it-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'itqa.ford.ch',
                    root: '/myaccount/',
                    language: 'Italian',
                    languageRegionCode: 'it-ch',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Austria',
            countryCode: 'at',
            threeLetterCountryCode: 'aut',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.jss',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/de-at/',
                    language: 'German',
                    languageRegionCode: 'de-at',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.at',
                    root: '/',
                    language: 'German',
                    languageRegionCode: 'de-at',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.at',
                    root: '/myaccount/',
                    language: 'German',
                    languageRegionCode: 'de-at',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Germany',
            countryCode: 'de',
            threeLetterCountryCode: 'deu',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/de-de/',
                    language: 'German',
                    languageRegionCode: 'de-de',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13855,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.de',
                    root: '/',
                    language: 'German',
                    languageRegionCode: 'de-de',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13855,
                },
                {
                    domain: 'wwwqa.ford.de',
                    root: '/myaccount/',
                    language: 'German',
                    languageRegionCode: 'de-de',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'France',
            countryCode: 'fr',
            threeLetterCountryCode: 'fra',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/fr-fr/',
                    language: 'Français',
                    languageRegionCode: 'fr-fr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13873,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.fr',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-fr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13873,
                },
                {
                    domain: 'wwwqa.ford.fr',
                    root: '/myaccount/',
                    language: 'Français',
                    languageRegionCode: 'fr-fr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13873,
                },
            ],
        },
        {
            name: 'Belgium',
            countryCode: 'be',
            threeLetterCountryCode: 'bel',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/fr-be/',
                    language: 'Français',
                    languageRegionCode: 'fr-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'frperf.globalaccount.ford.be',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'frqa.ford.be',
                    root: '/myaccount/',
                    language: 'Français',
                    languageRegionCode: 'fr-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/nl-be/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'nlperf.globalaccount.ford.be',
                    root: '/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'nlqa.ford.be',
                    root: '/myaccount/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-be',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Luxembourg',
            countryCode: 'lu',
            threeLetterCountryCode: 'lux',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/fr-lu/',
                    language: 'Français',
                    languageRegionCode: 'fr-lu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.lu',
                    root: '/',
                    language: 'Français',
                    languageRegionCode: 'fr-lu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.lu',
                    root: '/myaccount/',
                    language: 'Français',
                    languageRegionCode: 'fr-lu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Italy',
            countryCode: 'it',
            threeLetterCountryCode: 'ita',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/it-it/',
                    language: 'Italian',
                    languageRegionCode: 'it-it',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13882,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.it',
                    root: '/',
                    language: 'Italian',
                    languageRegionCode: 'it-it',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13882,
                },
                {
                    domain: 'wwwqa.ford.it',
                    root: '/myaccount/',
                    language: 'Italian',
                    languageRegionCode: 'it-it',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13882,
                },
            ],
        },
        {
            name: 'Spain',
            countryCode: 'es',
            threeLetterCountryCode: 'esp',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/es-es/',
                    language: 'Spanish',
                    languageRegionCode: 'es-es',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13864,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.es',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-es',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13864,
                },
                {
                    domain: 'wwwqa.ford.es',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-es',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                    medalliaCommentCardId: 13864,
                },
            ],
        },
        {
            name: 'Portugal',
            countryCode: 'pt',
            threeLetterCountryCode: 'prt',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/pt-pt/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-pt',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.pt',
                    root: '/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-pt',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.pt',
                    root: '/myaccount/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-pt',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Norway',
            countryCode: 'no',
            threeLetterCountryCode: 'nor',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/no-no/',
                    language: 'Norwegian',
                    languageRegionCode: 'no-no',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.no',
                    root: '/',
                    language: 'Norwegian',
                    languageRegionCode: 'no-no',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.no',
                    root: '/myaccount/',
                    language: 'Norwegian',
                    languageRegionCode: 'no-no',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Nederland',
            countryCode: 'nl',
            threeLetterCountryCode: 'nld',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/nl-nl/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-nl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.nl',
                    root: '/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-nl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.nl',
                    root: '/myaccount/',
                    language: 'Dutch',
                    languageRegionCode: 'nl-nl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Finland',
            countryCode: 'fi',
            threeLetterCountryCode: 'fin',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/fi-fi/',
                    language: 'Finnish',
                    languageRegionCode: 'fi-fi',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.fi',
                    root: '/',
                    language: 'Finnish',
                    languageRegionCode: 'fi-fi',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.fi',
                    root: '/myaccount/',
                    language: 'Finnish',
                    languageRegionCode: 'fi-fi',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Denmark',
            countryCode: 'dk',
            threeLetterCountryCode: 'dnk',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/da-dk/',
                    language: 'Danish',
                    languageRegionCode: 'da-dk',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.dk',
                    root: '/',
                    language: 'Danish',
                    languageRegionCode: 'da-dk',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.dk',
                    root: '/myaccount/',
                    language: 'Danish',
                    languageRegionCode: 'da-dk',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Czech Republic',
            countryCode: 'cz',
            threeLetterCountryCode: 'cze',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/cs-cz/',
                    language: 'Czech',
                    languageRegionCode: 'cs-cz',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.cz',
                    root: '/',
                    language: 'Czech',
                    languageRegionCode: 'cs-cz',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.cz',
                    root: '/myaccount/',
                    language: 'Czech',
                    languageRegionCode: 'cs-cz',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Greece',
            countryCode: 'gr',
            threeLetterCountryCode: 'grc',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/el-gr/',
                    language: 'Greek',
                    languageRegionCode: 'el-gr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.gr',
                    root: '/',
                    language: 'Greek',
                    languageRegionCode: 'el-gr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.gr',
                    root: '/myaccount/',
                    language: 'Greek',
                    languageRegionCode: 'el-gr',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Hungary',
            countryCode: 'hu',
            threeLetterCountryCode: 'hun',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/hu-hu/',
                    language: 'Hungarian',
                    languageRegionCode: 'hu-hu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.hu',
                    root: '/',
                    language: 'Hungarian',
                    languageRegionCode: 'hu-hu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.hu',
                    root: '/myaccount/',
                    language: 'Hungarian',
                    languageRegionCode: 'hu-hu',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Poland',
            countryCode: 'pl',
            threeLetterCountryCode: 'pol',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/pl-pl/',
                    language: 'Polish',
                    languageRegionCode: 'pl-pl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.pl',
                    root: '/',
                    language: 'Polish',
                    languageRegionCode: 'pl-pl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.pl',
                    root: '/myaccount/',
                    language: 'Polish',
                    languageRegionCode: 'pl-pl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Romania',
            countryCode: 'ro',
            threeLetterCountryCode: 'rou',
            appId: '8ce10c1d-ce69-4769-a1ee-b72de8aaa2c2',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'eu',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/1db89c73e928/944ff4276cc0/launch-efeb6e71adbd-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/ro-ro/',
                    language: 'Romanian',
                    languageRegionCode: 'ro-ro',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.ro',
                    root: '/',
                    language: 'Romanian',
                    languageRegionCode: 'ro-ro',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.ro',
                    root: '/myaccount/',
                    language: 'Romanian',
                    languageRegionCode: 'ro-ro',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 1,
                },
            ],
        },
        {
            name: 'Australia',
            countryCode: 'au',
            threeLetterCountryCode: 'aus',
            appId: 'e7404663-cb79-4f8b-a4f0-9a02241436ae',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            dslUrl: 'https://api.stg01e.gcp.ford.com/digitalservices',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/en-au/',
                    language: 'English',
                    languageRegionCode: 'en-au',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com.au',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-au',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.com.au',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-au',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'New Zealand',
            countryCode: 'nz',
            threeLetterCountryCode: 'nzl',
            appId: 'e7404663-cb79-4f8b-a4f0-9a02241436ae',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            dslUrl: 'https://api.stg01e.gcp.ford.com/digitalservices',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            walletUrl:
                'https://fordpay-components-uat.ford.com/js/fordpay-ui-wallet-transactions-bundle.js',
            subscriptionEnv: 'STG',
            subscriptionUrl:
                'https://wwwqa.subscriptionslb.ford.com/etc.clientlibs/owner-subscription/clientlibs/subscriptions-web.js',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/en-nz/',
                    language: 'English',
                    languageRegionCode: 'en-nz',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.co.nz',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-nz',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.co.nz',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-nz',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'South Africa',
            countryCode: 'za',
            threeLetterCountryCode: 'zaf',
            appId: 'e7404663-cb79-4f8b-a4f0-9a02241436ae',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            dslUrl: 'https://api.stg01e.gcp.ford.com/digitalservices',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/en-za/',
                    language: 'English',
                    languageRegionCode: 'en-za',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.co.za',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-za',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.co.za',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-za',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Vietnam',
            countryCode: 'vn',
            threeLetterCountryCode: 'vnm',
            appId: 'e7404663-cb79-4f8b-a4f0-9a02241436ae',
            fmaUrl: 'https://wwwperf.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://perf.api.mps.ford.com',
            dslUrl: 'https://api.qa01e.gcp.ford.com/digitalservices',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/vi-vn/',
                    language: 'Vietnamese',
                    languageRegionCode: 'vi-vn',
                    threeLetterLangCode: 'vie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com.vn',
                    root: '/',
                    language: 'Vietnamese',
                    languageRegionCode: 'vi-vn',
                    threeLetterLangCode: 'vie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.com.vn',
                    root: '/myaccount/',
                    language: 'Vietnamese',
                    languageRegionCode: 'vi-vn',
                    threeLetterLangCode: 'vie',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Philippines',
            countryCode: 'ph',
            threeLetterCountryCode: 'phl',
            appId: 'e7404663-cb79-4f8b-a4f0-9a02241436ae',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://perf.api.mps.ford.com',
            dslUrl: 'https://api.qa01e.gcp.ford.com/digitalservices',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/en-ph/',
                    language: 'English',
                    languageRegionCode: 'en-ph',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com.ph',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-ph',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.com.ph',
                    root: '/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-ph',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Thailand',
            countryCode: 'th',
            threeLetterCountryCode: 'tha',
            appId: 'e7404663-cb79-4f8b-a4f0-9a02241436ae',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'apa',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            dslUrl: 'https://api.stg01e.gcp.ford.com/digitalservices',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/effe41ce5952/46977b13675a/launch-dd06cd5351b7-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/th-th/',
                    language: 'Thai',
                    languageRegionCode: 'th-th',
                    threeLetterLangCode: 'tha',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.co.th',
                    root: '/',
                    language: 'Thai',
                    languageRegionCode: 'th-th',
                    threeLetterLangCode: 'tha',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.co.th',
                    root: '/myaccount/',
                    language: 'Thai',
                    languageRegionCode: 'th-th',
                    threeLetterLangCode: 'tha',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/en-th/',
                    language: 'English',
                    languageRegionCode: 'en-th',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'enperf.globalaccount.ford.co.th',
                    root: '/',
                    language: 'English',
                    languageRegionCode: 'en-th',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 1,
                },
                {
                    domain: 'wwwqa.ford.co.th',
                    root: '/en/myaccount/',
                    language: 'English',
                    languageRegionCode: 'en-th',
                    threeLetterLangCode: 'eng',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'ce85a806-1ea3-4160-ab3e-1bbd0a5dbf76',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Mexico',
            countryCode: 'mx',
            threeLetterCountryCode: 'mex',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/e3a6ea31e0d0/launch-aae7acc8859b-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/es-mx/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.mx',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa.ford.mx',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/lincoln/es-mx/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'b4f9cfa1-ae5f-4db6-b4eb-8910c6ce660e',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.lincoln.mx',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa.lincoln.mx',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-mx',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'lincoln',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Argentina',
            countryCode: 'ar',
            threeLetterCountryCode: 'arg',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-459519130f89-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/es-ar/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ar',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com.ar',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ar',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa.ford.com.ar',
                    root: '/myaccount/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ar',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Brazil',
            countryCode: 'br',
            threeLetterCountryCode: 'bra',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/56d24011b956/launch-459519130f89-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/pt-br/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-br',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com.br',
                    root: '/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-br',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwqa.ford.com.br',
                    root: '/myaccount/',
                    language: 'Portuguese',
                    languageRegionCode: 'pt-br',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Chile',
            countryCode: 'cl',
            threeLetterCountryCode: 'chl',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-658deca8deb3-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/es-cl/',
                    language: 'Spanish',
                    languageRegionCode: 'es-cl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.cl',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-cl',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Colombia',
            countryCode: 'co',
            threeLetterCountryCode: 'col',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-658deca8deb3-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/es-co/',
                    language: 'Spanish',
                    languageRegionCode: 'es-co',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com.co',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-co',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Peru',
            countryCode: 'pe',
            threeLetterCountryCode: 'per',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-658deca8deb3-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/es-pe/',
                    language: 'Spanish',
                    languageRegionCode: 'es-pe',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.pe',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-pe',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
            ],
        },
        {
            name: 'Venezuela',
            countryCode: 've',
            threeLetterCountryCode: 'ven',
            appId: '7000421f-6474-4f4b-9c2f-9df6df14e694',
            fmaUrl: 'https://wwwqa.account.ford.com/etc/fd/fma/bundle.js',
            fmaRegion: 'na',
            fmaFsUrl: 'https://stg.api.mps.ford.com',
            analyticsEmbedScriptUrl:
                '//assets.adobedtm.com/248d63c2ff6b/8225bb6ac821/launch-658deca8deb3-staging.min.js',
            walletUrl: '',
            subscriptionEnv: '',
            subscriptionUrl: '',
            regionConfigurations: [
                {
                    domain: 'wwwperf.globalaccount.ford.com',
                    root: '/ford/es-ve/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ve',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
                {
                    domain: 'wwwperf.globalaccount.ford.com.ve',
                    root: '/',
                    language: 'Spanish',
                    languageRegionCode: 'es-ve',
                    langScript: 'LATN',
                    blockSearchIndexing: true,
                    brand: 'ford',
                    clientId: 'a4df0ae0-ce72-41b4-bb8b-278fa3061ca4',
                    languageSetId: 2,
                },
            ],
        },
    ],
};
