import { useContent } from '@hooks/use-server-content';

export interface AccountSettingsLabels {
    addressOne: string;
    addressTwo: string;
    btnEdit: string;
    editPersonalInfoAriaLabel?: string;
    email: string;
    memberId: string;
    name: string;
    nationalIdName?: string;
    pageDescription: string;
    pageTitle: string;
    personalInformation: string;
    phoneNumber: string;
    returnLinkLabel: string;
    title?: string;
    hideUnitsOfMeasurement?: string;
    deleteMyAccountLabel?: string;
}

export const useAccountSettingsContent = (): AccountSettingsLabels | null => {
    const [content, getValueByName] = useContent('common', 'account-settings');
    const contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    return contentFragment as AccountSettingsLabels;
};
