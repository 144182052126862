import { useContent } from '@hooks/use-server-content';

export interface AccountSettingsFormsLabels {
    addressField: string;
    addressTwoField: string;
    addressTwoSection?: string;
    btnAddAddress: string;
    btnCancel: string;
    btnUpdate: string;
    cityField: string;
    cityPatternRules: string;
    emailField: string;
    emailPatternRules: string;
    firstNameField: string;
    lastNameField: string;
    middleNameField: string;
    namePatternRules: string;
    addressPatternRules: string;
    countryCodeFieldLabel?: string;
    countryCodeInputPlaceholder: string;
    mobilePhoneField: string;
    confirmMobilePhoneField: string;
    confirmEmailField: string;
    phoneNumberPatternRules: string;
    postalCodeField: string;
    postalCodePatternRules: string;
    primaryPhoneField: string;
    title: string;
    titleOptionMr: string;
    titleOptionMrs: string;
    titleOptionMiss: string;
    titleOptionMs: string;
    titleOptionDr: string;
    usernameField: string;
    validationEnter: string;
    validationErrorIcon: string;
    validPhoneNumber: string;
    validationPhoneNumberMatch: string;
    validationEmailsMatch: string;
    validCharacterLength: string;
    companyField: string;
    titleField: string;
    titlePatternRules: string;
    secondLastNameField?: string;
    nationalIdType: string;
    nationalIdNumber: string;
    numberPatternRules?: string;
    firstNameCharacterLength?: string;
    interiorNumberField?: string;
    suburbField?: string;
    customValidPhoneNumberValidationMessage?: string;
}
export const useAccountSettingsFormsContent =
    (): AccountSettingsFormsLabels | null => {
        const [content, getValueByName] = useContent(
            'common',
            'account-settings-forms'
        );
        const contentFragment: any = {};
        if (content) {
            content?.elements.forEach((element) => {
                contentFragment[`${element.name}`] = getValueByName(
                    `${element.name}`
                );
            });
        }
        return contentFragment as AccountSettingsFormsLabels;
    };
