import { useContent } from '@hooks/use-server-content';

export interface GdprBannerProps {
    hide: boolean;
    fordLogoImagePath: string;
    paragraphOne: string;
    paragraphTwo: string;
    paragraphThree: string;
    acceptCookiesButtonLabel: string;
    acceptCookiesButtonAriaLabel: string;
    declineCookiesButtonLabel: string;
    declineCookiesButtonAriaLabel: string;
}

export const useGdprBannerContent = (): GdprBannerProps | null => {
    const [content, getValueByName] = useContent('common', 'gdpr-banner');
    let contentFragment: any = {};
    content?.elements.forEach((element) => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as GdprBannerProps;
};
