import React, { useState, useEffect } from 'react';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { useAnalytics } from '@hooks/use-analytics';
import { useProfileData } from '../account-portal/hooks/use-profile-data';
import DeleteAccountService from '@services/delete-account-service/delete-account-service';
import ApiResponsePage from '../delete-account/apiResponsePage/apiResponsePage';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { DeleteAccountContent } from '../delete-account/hooks/use-delete-account-content';
import './delete-account-modal.scss';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

interface DeleteAccountModalProps {
    modalContent: DeleteAccountContent;
    isModalVisible: boolean;
    handleClose: () => void;
    setApiResponsePage: any;
}

export enum Action {
    success,
    error,
    duplicate,
}

const DeleteAccountModal = (props: DeleteAccountModalProps) => {
    const componentName = 'delete-account-modal';
    const [fireAnalytics] = useAnalytics();
    const { modalContent } = props;
    const { setContext, resetContext } = useModalContext();
    const profile = useProfileData();
    const appConfig = new AppConfigurationService();
    const isLincoln = appConfig.brand === 'lincoln';
    const [memberId, setMemberId] = useState('');
    const [responseAction, setResponseAction] = useState<Action>(null);
    const deleteAccountService = new DeleteAccountService();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (profile) {
            setMemberId(profile.profile.memberId.toString());
        }
    }, [profile]);

    const apiResponse = (
        deleteAccountTitle,
        deleteAccountIcon,
        deleteAccountDescription
    ) => {
        return (
            <>
                {props.modalContent && (
                    <ApiResponsePage
                        deleteAccountTitle={deleteAccountTitle}
                        deleteAccountIcon={deleteAccountIcon}
                        deleteAccountDescription={deleteAccountDescription}
                    />
                )}
            </>
        );
    };

    const deleteAccount = async () => {
        const scrollUtil = new ScrollUtil();
        fireAnalytics('deleteMyAccountConfirmButton');
        setIsLoading(true);
        // given a member id, delete the user...
        if (memberId !== '') {
            props.handleClose();
            props.setApiResponsePage(false);
        }

        const response = await deleteAccountService.delete();
        if (response && props.modalContent) {
            setIsLoading(false);
        }

        if (response.status === 200) {
            setResponseAction(Action.success);
        } else if (response.status === 409) {
            setResponseAction(Action.duplicate);
        } else {
            setResponseAction(Action.error);
        }
        scrollUtil.scrollPageToTop();
    };

    const onCancelAndClose = (): void => {
        fireAnalytics('deleteMyAccountCancelButton');
        props.handleClose();
    };

    const deleteAccountModalChildren = () => {
        return (
            <>
                {isLincoln && (
                    <span className="fds-align--center fds-icon fds-icon--32 fds-font--ford-icons__warning-filled"></span>
                )}
                <h2
                    className={`fmc-type--heading6 ${
                        isLincoln
                            ? 'fds-color__text--primary fds-weight--bold fds-align--center'
                            : ''
                    }`}
                    data-testid="modal-header"
                >
                    {modalContent?.modalHeader}
                </h2>
                <div
                    className={`fmc-type--body1 ${
                        isLincoln
                            ? 'fds-color__text--gray3 fds-align--center'
                            : ''
                    }`}
                    data-testid="modal-body-text"
                >
                    {modalContent?.modalBodyText}
                </div>
            </>
        );
    };

    const deleteAccountModalProps: ModalContextProps = {
        modalType: {
            name: componentName,
            onAfterClose: onCancelAndClose,
            primaryButtonLabel: modalContent?.modalPrimaryBtnLabel,
            onPrimaryButtonClick: deleteAccount,
            secondaryButtonLabel: modalContent?.modalCancelBtnLabel,
            onSecondaryButtonClick: onCancelAndClose,
            modalContentClass: `${componentName}-content`,
            modalFooterClass: `${componentName}-footer`,
            children: deleteAccountModalChildren(),
        },
    };

    useEffect(() => {
        props.isModalVisible
            ? setContext(deleteAccountModalProps)
            : resetContext();
    }, [props.isModalVisible]);

    return (
        <>
            {isLoading && <ActivityIndicator className="full-height" />}

            {responseAction === Action.success &&
                apiResponse(
                    props.modalContent.deleteAccountSuccessTitle,
                    props.modalContent.deleteAccountSuccessIcon,
                    props.modalContent.deleteAccountSuccessDescription
                )}
            {responseAction === Action.error &&
                apiResponse(
                    props.modalContent.deleteAccountErrorTitle,
                    props.modalContent.deleteAccountErrorIcon,
                    props.modalContent.deleteAccountErrorDescription
                )}
            {responseAction === Action.duplicate &&
                apiResponse(
                    props.modalContent.deleteAccountDuplicateTitle,
                    props.modalContent.deleteAccountDuplicateIcon,
                    props.modalContent.deleteAccountDuplicateDescription
                )}
        </>
    );
};

export default DeleteAccountModal;
