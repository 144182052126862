import { AnalyticsService } from '@services/analytics-service/analytics-service';
import { useEffect, useState } from 'react';
import { VehicleAttributes } from '@models/vehicle-attributes';

export interface ShortcodeProviders {
    ymm?: VehicleAttributes | Promise<VehicleAttributes>;
    nameplate?: string;
    orderType?: string;
    addVehicleStatus?: string;
    languageRegionCode?: string;
    category?: string;
    cvotOrderStatus?: string;
    fprButton?: string;
}

async function fireEvents(
    eventNames: string | string[],
    targetViewName?: string,
    shortcodeProviders?: ShortcodeProviders,
    clearDigitaldata = true
) {
    const analyticsService: AnalyticsService = new AnalyticsService(
        targetViewName,
        shortcodeProviders
    );
    if (typeof eventNames !== 'undefined') {
        analyticsService.fireEvents(eventNames, clearDigitaldata);
    }
}

export function useAnalytics(
    onLoadEvents?: string | string[],
    targetViewName?: string,
    shortcodeProviders?: ShortcodeProviders,
    clearDigitaldata = true
): [
    (
        eventNames: string | string[],
        targetViewName?: string,
        shortcodeProviders?: ShortcodeProviders,
        clearDigitaldata?: boolean
    ) => void,
    boolean
] {
    const [onLoadFinished, setOnLoadFinished] = useState<boolean>(false);
    useEffect(() => {
        if (onLoadEvents) {
            fireEvents(
                onLoadEvents,
                targetViewName,
                shortcodeProviders,
                clearDigitaldata
            ).then(() => {
                setOnLoadFinished(true);
            });
        } else {
            setOnLoadFinished(true);
        }
    }, []);
    return [fireEvents, onLoadFinished];
}
