import React from 'react';
import TertiaryButton from '@/components/common/tertiary-button/tertiary-button';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import { OrderHistoryContent } from '../hooks/use-order-history-content';
import { OrderHistoryOrder } from '@services/oms-service/oms-service';
import { useNavigate } from 'react-router-dom';
import { findPathByAlias } from '@routes/routesList';

interface Props {
    order: OrderHistoryOrder;
    index: number;
    orderHistoryContent: OrderHistoryContent;
    appConfig: AppConfigurationService;
    className: string;
    isActive: boolean;
    totalOrders: string;
    alwaysIncludeBottomBorder?: boolean;
}

const OrderHistoryItem = (props: Props) => {
    const {
        order,
        index,
        orderHistoryContent,
        appConfig,
        className,
        isActive,
        totalOrders,
        alwaysIncludeBottomBorder,
    } = props;

    const navigate = useNavigate();

    const convertTimeStampToFormattedDate = (timestamp: string) => {
        const date = new Date(timestamp);
        const languageRegionCode = appConfig
            .getLanguageRegionCode()
            .toLowerCase();
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        };
        return date.toLocaleDateString(languageRegionCode, options);
    };

    return (
        <>
            <div
                className={`${className}-item`}
                data-testid={`${className}-item-${
                    isActive ? 'active' : 'past'
                }-${index}`}
            >
                <p
                    className={`${className}-order-date`}
                    data-testid={`${className}-item-${
                        isActive ? 'active' : 'past'
                    }-${index}-order-date-label`}
                >
                    {orderHistoryContent.orderDateLabelText}
                </p>
                <p
                    className={`${className}-date`}
                    data-testid={`${className}-item-${
                        isActive ? 'active' : 'past'
                    }-${index}-order-date`}
                >
                    {convertTimeStampToFormattedDate(order.OrderDate)}
                </p>
                <p
                    className={`${className}-number`}
                    data-testid={`${className}-item-${
                        isActive ? 'active' : 'past'
                    }-${index}-order-number`}
                >
                    {'#'}
                    {order.OrderNo}
                </p>
                <p
                    className={`${className}-order-total`}
                    data-testid={`${className}-item-${
                        isActive ? 'active' : 'past'
                    }-${index}-order-total`}
                >
                    {'$'}
                    {order.OriginalTotalAmount}{' '}
                    {orderHistoryContent.orderTotalLabelText}
                </p>
                <p
                    className={`${className}-order-items`}
                    data-testid={`${className}-item-${
                        isActive ? 'active' : 'past'
                    }-${index}-total-records`}
                >
                    {orderHistoryContent.orderNumberOfItemsLabelText}{' '}
                    {order.OrderLines?.TotalNumberOfRecords}
                </p>
                <TertiaryButton
                    labelText={orderHistoryContent.viewDetailsButtonLabelText}
                    ariaLabel={orderHistoryContent.viewDetailsButtonAriaLabel}
                    dataTestId={`${className}-item-${
                        isActive ? 'active' : 'past'
                    }-${index}-view-details-button`}
                    internal={true}
                    chevronDirection={'right'}
                    link={findPathByAlias(
                        'OrderDetailsViewWithoutOrderNumber',
                        order.OrderNo
                    )}
                ></TertiaryButton>
            </div>
            {alwaysIncludeBottomBorder ||
            index !== parseInt(totalOrders) - 1 ? (
                <hr className="hr-line" aria-hidden={true} />
            ) : null}
        </>
    );
};

export default OrderHistoryItem;
