import RandomNumberUtil from '@utils/random-number-util/random-number-util';

const getUuid = () => {
    // https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
    let dt = new Date().getTime();

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (dt + RandomNumberUtil.getRandomNumber() * 16) % 16 | 0;

        dt = Math.floor(dt / 16);

        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};
export default getUuid;
