import { useContent } from '@hooks/use-server-content';

export interface OrderDetailsContent {
    orderDetailsbreadcrumbTitle: string;
    orderDetailspageTitle: string;
    order: string;
    orderDate: string;
    billingAddress: string;
    shippingAddress: string;
    startReturn: string;
    returnPolicy: string;
    returnPolicyPageUrl?: string;
    multipleQuantityPriceDescriptor: string;
    quantity: string;
    cancel: string;
    buyAgain: string;
    orderSubTotal: string;
    orderShipping: string;
    orderSalesTax: string;
    orderTotal?: string;
    returnsbreadcrumbTitle?: string;
    returnspageTitle?: string;
    returnspageHeader?: string;
    nonReturnable?: string;
    freeShipping?: string;
    returnsDropdown?: string;
    continue?: string;
    returnsModalTitle?: string;
    returnsModalSubtitle?: string;
    changeSelection?: string;
    confirmReturn?: string;
    faqTitle?: string;
    faqItem?: string;
    completeReturnPageHeaderText?: string;
    completeReturnPageSubheaderText?: string;
    completeReturnPageItemsToReturnText?: string;
    completeReturnPageReturnSlipButtonText?: string;
    completeReturnPageReturnSlipButtonAriaLabel?: string;
    completeReturnPageReturnLabelButtonText?: string;
    completeReturnPageReturnLabelButtonAriaLabel?: string;
    createReturnBreadcrumbTitle?: string;
    createReturnBreadcrumbPreviousTitle?: string;
    createReturnBreadcrumbSecondPreviousTitle?: string;
    errorNotificationMessage?: string;
    createReturnErrorMessage?: string;
    packingSlipErrorNotificationMessage?: string;
    returnLabelErrorNotificationMessage?: string;
    guestReturnBreadcrumbTitle?: string;
    guestDetailsBreadcrumbTitle?: string;
    productPageUrl?: string;
    accessoriesUrl?: string;
    accessoriesImageUrl?: string;
    performancePartsImageUrl?: string;
}

export const useOrderDetailsContent = (): OrderDetailsContent | null => {
    const [content, getValueByName] = useContent('common', 'nvc-order-detail');
    const contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    return contentFragment as OrderDetailsContent;
};
