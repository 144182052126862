import { useContent } from '@hooks/use-server-content';

export interface ServiceUnavailableContent {
    hide: boolean;
    serviceUnavailableFieldTitle: string;
    serviceUnavailableFieldTitleDescription: string;
}

export const useServiceUnavailableContent =
    (): ServiceUnavailableContent | null => {
        const [content, getValueByName] = useContent(
            'common',
            'service-unavailable'
        );
        const contentFragment: any = {};
        if (content && content.elements) {
            content.elements.forEach((element) => {
                contentFragment[`${element.name}`] = getValueByName(
                    `${element.name}`
                );
            });
        }
        return contentFragment as ServiceUnavailableContent;
    };
