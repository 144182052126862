import httpAdapter from 'axios/lib/adapters/http';
import { HttpsProxyAgent } from 'https-proxy-agent';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '@services/http-service/http-service';
import { ConnectedVehicleResponse } from '@models/connected-vehicle';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export default class ConnectedVehicleService {
    private httpService: any;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getVehicleStatus(
        vin: string
    ): Promise<ConnectedVehicleResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.CONNECTED_VEHICLE;

        const { currentRegionCode } = new AppConfigurationService();
        const params = {
            countryCode: currentRegionCode,
        };

        const config = {
            headers: {
                ...this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                vin,
            },
            params,
            adapter: httpAdapter,
            proxy: false,
            httpAgent: proxyAgent,
        };

        return this.httpService
            .get(url, false, config)
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
            .catch(() => {
                return {};
            });
    }
}
