import React, { Dispatch, SetStateAction } from 'react';
import { PreferredDealerContent } from '@views/preferred-dealer-view/hooks/use-preferred-dealer-content';
import { Dealer } from '@models/preferred-dealer';
import { SecondaryButton } from '@common/index';
import StarIconFilled from '@views/preferred-dealer-view/components/star-icon-filled/star-icon-filled';
import StarIconOutlined from '@views/preferred-dealer-view/components/star-icon-outlined/star-icon-outlined';
import { PreferredDealerItem } from '@views/preferred-dealer-view/preferred-dealer-view';

import './individual-dealer.scss';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { useAnalytics } from '@hooks/use-analytics';
import { LinkTargetValues } from '@constants';
import ReplaceDealerModalContent from '@views/preferred-dealer-view/components/replace-dealer-modal-content/replace-dealer-modal-content';

interface IndividualDealerProps {
    id: number;
    brand: string;
    preferredDealerContent: PreferredDealerContent;
    dealerInfo: Dealer;
    vin: string;
    isPreferredDealer: boolean;
    selectedModel: string;
    instantPreferredDealer: string;
    setInstantPreferredDealer: Dispatch<SetStateAction<string>>;
    updatePreferredDealer: (item: PreferredDealerItem) => void;
    scrollToTopOfDealerResultsList: () => void;
}

const IndividualDealer = (props: IndividualDealerProps) => {
    const [fireAnalytics] = useAnalytics();
    const isLincoln: boolean = props.brand === 'lincoln';
    const isCanada: boolean =
        new AppConfigurationService().get2LetterCountryCode() === 'ca';
    const { setContext, resetContext } = useModalContext();

    const replacePreferredDealerModalProps: ModalContextProps = {
        modalType: {
            name: 'replace-preferred-dealer-modal',
            'aria-label': 'Replace Preferred Dealer Modal',
            primaryButtonLabel:
                props.preferredDealerContent
                    .replaceMyDealerModalConfirmButtonLabelText,
            primaryButtonAriaLabel:
                props.preferredDealerContent
                    .replaceMyDealerModalConfirmButtonAriaLabel,
            secondaryButtonLabel:
                props.preferredDealerContent
                    .replaceMyDealerModalCancelButtonLabelText,
            secondaryButtonAriaLabel:
                props.preferredDealerContent
                    .replaceMyDealerModalCancelButtonAriaLabel,
            closeButtonAriaLabel:
                props.preferredDealerContent
                    .replaceMyDealerModalCloseButtonAriaLabel,
            onPrimaryButtonClick: () => {
                props.setInstantPreferredDealer(props.dealerInfo.cupid);
                props.updatePreferredDealer({
                    vin: props.vin,
                    model: props.selectedModel,
                    preferredDealerCode: props.dealerInfo.cupid,
                });
                fireAnalytics('replaceMyDealerPreferredDealerOnclickEvent');
                resetContext();
                props.scrollToTopOfDealerResultsList();
            },
            onSecondaryButtonClick: () => resetContext(),
            onAfterClose: () => resetContext(),
            children: () => (
                <ReplaceDealerModalContent
                    preferredDealerContent={props.preferredDealerContent}
                />
            ),
        },
    };

    return (
        <>
            {props.preferredDealerContent && (
                <div
                    className="individual-dealer__container"
                    data-testid="individual-dealer-container"
                >
                    <h2
                        className="individual-dealer__header"
                        data-testid="individual-dealer-header"
                    >
                        {props.id}. {props.dealerInfo.name}
                    </h2>
                    <button
                        data-testid="my-dealer-button"
                        className={`individual-dealer__my-dealer-button ${
                            props.isPreferredDealer ? 'is-my-dealer' : ''
                        }`}
                        onClick={() => {
                            if (!props.instantPreferredDealer) {
                                props.setInstantPreferredDealer(
                                    props.dealerInfo.cupid
                                );
                                props.updatePreferredDealer({
                                    vin: props.vin,
                                    model: props.selectedModel,
                                    preferredDealerCode: props.dealerInfo.cupid,
                                });
                                fireAnalytics(
                                    'makeMyDealerPreferredDealerOnclickEvent'
                                );
                                props.scrollToTopOfDealerResultsList();
                            } else if (!props.isPreferredDealer) {
                                setContext(replacePreferredDealerModalProps);
                            }
                        }}
                    >
                        <span>
                            {props.isPreferredDealer ? (
                                <StarIconFilled
                                    color={`${
                                        isLincoln ? '#F26147' : '#066FEF'
                                    }`}
                                />
                            ) : (
                                <StarIconOutlined
                                    color={`${
                                        isLincoln ? '#F26147' : '#066FEF'
                                    }`}
                                />
                            )}
                        </span>
                        <span>
                            {props.isPreferredDealer
                                ? props.preferredDealerContent
                                      .myDealerButtonLabelText
                                : props.preferredDealerContent
                                      .makeMyDealerButtonLabelText}
                        </span>
                    </button>
                    <p
                        className="individual-dealer__address"
                        data-testid="individual-dealer-address"
                    >
                        {props.dealerInfo.address.street1},{' '}
                        {props.dealerInfo.address.city},{' '}
                        {isCanada
                            ? props.dealerInfo.address.province
                            : props.dealerInfo.address.state}{' '}
                        {props.dealerInfo.address.postalCode}
                    </p>
                    <p
                        className="individual-dealer__phone-number"
                        data-testid="individual-dealer-phone-number"
                    >
                        {props.dealerInfo.phone}
                    </p>
                    {props.dealerInfo.url && (
                        <SecondaryButton
                            aria-label={
                                props.preferredDealerContent
                                    .dealerWebsiteButtonAriaLabel
                            }
                            data-testid="individual-dealer-dealer-website-button"
                            className="dealer-website-button"
                            linkTarget={LinkTargetValues.BLANK}
                            link={props.dealerInfo.url}
                            onClick={() =>
                                fireAnalytics(
                                    'dealerWebsitePreferredDealerReferralExit'
                                )
                            }
                        >
                            <span>
                                {
                                    props.preferredDealerContent
                                        .dealerWebsiteButtonLabelText
                                }
                            </span>
                            {isLincoln && (
                                <div className="lincoln-underline-button-overlay"></div>
                            )}
                        </SecondaryButton>
                    )}
                </div>
            )}
        </>
    );
};

export default IndividualDealer;
