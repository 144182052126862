import React, { ReactElement } from 'react';
import { ProfileWithVehiclesResponse } from '@models/profile-with-vehicles';
import { InputField, SelectBox } from '@common/form-fields/form-fields';
import { AccountSettingsFormsLabels } from '../hooks/use-personal-information-content';
import { UserPersonalData } from '../personal-information';

interface InputValidation {
    [key: string]: {
        validationRules: any;
        validationPattern: string;
        label: string;
    };
}
interface Error {
    [key: string]: {
        status: boolean;
        message: string;
    };
}
interface FieldConfig {
    state: boolean;
    country: boolean;
    companyName: boolean;
    middleName: boolean;
    title: boolean;
    address1Line2: boolean;
    additionalAddress: boolean;
}
interface AddressProps {
    appConfig: any;
    userData: UserPersonalData;
    focus: any;
    setFocusToField: (field: string, status: boolean) => void;
    personalInfoLabels: AccountSettingsFormsLabels;
    inputValidations: InputValidation;
    error: Error;
    handleChange: (e: any) => void;
    addressNumber: number | string;
    fieldsConfig: FieldConfig;
    experienceFragmentTerritory: any;
    user: ProfileWithVehiclesResponse['profile'] | any;
    transformDropdownOptions: any;
}

const noFields = 'two-fields';

const Address = (props: AddressProps) => {
    const {
        userData,
        focus,
        setFocusToField,
        personalInfoLabels,
        inputValidations,
        error,
        handleChange,
        addressNumber,
        fieldsConfig,
        experienceFragmentTerritory,
        transformDropdownOptions,
    } = props;

    const getSelectBox = (
        name: string,
        value: string,
        options: any,
        label: string,
        testId?: string
    ): ReactElement => {
        return (
            <SelectBox
                name={name}
                id={name}
                onChange={handleChange}
                value={value}
                className="user-input-select"
                label={label || ''}
                options={options}
                validationRules={inputValidations[name].validationRules}
                error={error[name]}
                testId={testId}
            />
        );
    };

    return (
        <div data-testid="address">
            <>
                {personalInfoLabels?.companyField && (
                    <div
                        className={`input-section-container user-address${
                            fieldsConfig?.companyName
                                ? ' se-field-alignment'
                                : ''
                        }`}
                    >
                        <InputField
                            id={`companyName`}
                            dataTestId={`companyName`}
                            label={personalInfoLabels?.companyField}
                            showLabel={focus.companyName}
                            handleChange={handleChange}
                            name={'companyName'}
                            value={userData['companyName']}
                            className="two-fields address"
                            validationRules={
                                inputValidations['companyName'].validationRules
                            }
                            error={error['companyName']}
                            onFocus={() => setFocusToField('companyName', true)}
                            onBlur={() =>
                                userData['companyName'].length == 0 &&
                                setFocusToField('companyName', false)
                            }
                            placeholder={personalInfoLabels?.companyField}
                        />
                    </div>
                )}
                <div className={`input-section-container user-address' : ''`}>
                    <InputField
                        id={`address${addressNumber}Line1`}
                        dataTestId={`address${addressNumber}Line1`}
                        label={personalInfoLabels?.addressField}
                        showLabel={focus.address1Line1}
                        handleChange={handleChange}
                        name={`address${addressNumber}Line1`}
                        value={userData[`address${addressNumber}Line1`]}
                        className="address"
                        validationRules={
                            inputValidations[`address${addressNumber}Line1`]
                                .validationRules
                        }
                        validationPattern={
                            inputValidations[`address${addressNumber}Line1`]
                                .validationPattern
                        }
                        error={error[`address${addressNumber}Line1`]}
                        onFocus={() =>
                            setFocusToField(
                                `address${addressNumber}Line1`,
                                true
                            )
                        }
                        onBlur={() =>
                            userData[`address${addressNumber}Line1`].length ==
                                0 &&
                            setFocusToField(
                                `address${addressNumber}Line1`,
                                false
                            )
                        }
                        placeholder={personalInfoLabels?.addressField}
                    />
                    {personalInfoLabels?.addressTwoField && (
                        <InputField
                            id={`address${addressNumber}Line2`}
                            dataTestId={`address${addressNumber}Line2`}
                            label={personalInfoLabels?.addressTwoField}
                            showLabel={focus.address1Line2}
                            handleChange={handleChange}
                            name={`address${addressNumber}Line2`}
                            value={userData[`address${addressNumber}Line2`]}
                            className="address"
                            validationRules={
                                inputValidations[`address${addressNumber}Line2`]
                                    .validationRules
                            }
                            validationPattern={
                                inputValidations[`address${addressNumber}Line2`]
                                    .validationPattern
                            }
                            error={error[`address${addressNumber}Line2`]}
                            onFocus={() =>
                                setFocusToField(
                                    `address${addressNumber}Line2`,
                                    true
                                )
                            }
                            onBlur={() =>
                                userData[`address${addressNumber}Line2`]
                                    .length == 0 &&
                                setFocusToField(
                                    `address${addressNumber}Line2`,
                                    false
                                )
                            }
                            placeholder={personalInfoLabels?.addressTwoField}
                        />
                    )}
                </div>
                <div className="input-section-container input-city-state">
                    {personalInfoLabels?.interiorNumberField && (
                        <InputField
                            id={`address${addressNumber}InteriorNumber`}
                            dataTestId={`address${addressNumber}InteriorNumber`}
                            label={personalInfoLabels?.interiorNumberField}
                            showLabel={focus.city}
                            handleChange={handleChange}
                            name={`address${addressNumber}InteriorNumber`}
                            value={
                                userData[
                                    `address${addressNumber}InteriorNumber`
                                ]
                            }
                            className={`${noFields} city`}
                            validationRules={
                                inputValidations[
                                    `address${addressNumber}InteriorNumber`
                                ].validationRules
                            }
                            validationPattern={
                                inputValidations[
                                    `address${addressNumber}InteriorNumber`
                                ].validationPattern
                            }
                            error={
                                error[`address${addressNumber}InteriorNumber`]
                            }
                            onFocus={() =>
                                setFocusToField('interiorNumber', true)
                            }
                            onBlur={() =>
                                userData[
                                    `address${addressNumber}InteriorNumber`
                                ].length == 0 &&
                                setFocusToField('interiorNumber', false)
                            }
                            placeholder={
                                personalInfoLabels?.interiorNumberField
                            }
                        />
                    )}
                    {personalInfoLabels?.suburbField && (
                        <InputField
                            id={`address${addressNumber}SuburbField`}
                            dataTestId={`address${addressNumber}SuburbField`}
                            label={personalInfoLabels?.suburbField}
                            showLabel={focus.city}
                            handleChange={handleChange}
                            name={`address${addressNumber}SuburbField`}
                            value={
                                userData[`address${addressNumber}SuburbField`]
                            }
                            className={`${noFields} city`}
                            validationRules={
                                inputValidations[
                                    `address${addressNumber}SuburbField`
                                ].validationRules
                            }
                            validationPattern={
                                inputValidations[
                                    `address${addressNumber}SuburbField`
                                ].validationPattern
                            }
                            error={error[`address${addressNumber}SuburbField`]}
                            onFocus={() => setFocusToField('suburbField', true)}
                            onBlur={() =>
                                userData[`address${addressNumber}SuburbField`]
                                    .length == 0 &&
                                setFocusToField('suburbField', false)
                            }
                            placeholder={personalInfoLabels?.suburbField}
                        />
                    )}
                    <InputField
                        id={`address${addressNumber}City`}
                        dataTestId={`address${addressNumber}City`}
                        label={personalInfoLabels?.cityField}
                        showLabel={focus.city}
                        handleChange={handleChange}
                        name={`address${addressNumber}City`}
                        value={userData[`address${addressNumber}City`]}
                        className={`${noFields} city`}
                        validationRules={
                            inputValidations[`address${addressNumber}City`]
                                .validationRules
                        }
                        validationPattern={
                            inputValidations[`address${addressNumber}City`]
                                .validationPattern
                        }
                        error={error[`address${addressNumber}City`]}
                        onFocus={() => setFocusToField('city', true)}
                        onBlur={() =>
                            userData[`address${addressNumber}City`].length ==
                                0 && setFocusToField('city', false)
                        }
                        placeholder={personalInfoLabels?.cityField}
                    />
                    {experienceFragmentTerritory &&
                        experienceFragmentTerritory.options.length &&
                        experienceFragmentTerritory.options[0].name && (
                            <div className="user-input-select-container three-fields fmc-floating-label--error">
                                {getSelectBox(
                                    `address${addressNumber}State`,
                                    userData[`address${addressNumber}State`],
                                    transformDropdownOptions(
                                        experienceFragmentTerritory?.options
                                    ),
                                    experienceFragmentTerritory?.dropdownFieldLabel,
                                    'territory-field'
                                )}
                            </div>
                        )}

                    <InputField
                        id={`address${addressNumber}Zip`}
                        dataTestId={`address${addressNumber}Zip`}
                        label={
                            inputValidations[`address${addressNumber}Zip`].label
                        }
                        showLabel={focus.zip}
                        handleChange={handleChange}
                        name={`address${addressNumber}Zip`}
                        value={userData[`address${addressNumber}Zip`]}
                        className={`${noFields}`}
                        validationRules={
                            inputValidations[`address${addressNumber}Zip`]
                                .validationRules
                        }
                        validationPattern={
                            inputValidations[`address${addressNumber}Zip`]
                                .validationPattern
                        }
                        error={error[`address${addressNumber}Zip`]}
                        onFocus={() => setFocusToField(`zip`, true)}
                        onBlur={() =>
                            userData[`address${addressNumber}Zip`].length ==
                                0 && setFocusToField(`zip`, false)
                        }
                        placeholder={
                            inputValidations[`address${addressNumber}Zip`].label
                        }
                    />
                </div>
            </>
        </div>
    );
};

export default Address;
