import { useContent } from '@hooks/use-server-content';

export interface VehicleOrderTrackingContent {
    headerText: string;
    accessorySubtitleText: string;
    accessoryDescriptionText: string;
    accessoryOrdersButtonLabelText: string;
    accessoryOrdersButtonAriaLabel: string;
    accessoryOrdersNavPath: string;
    subtitleText: string;
    numberedInstructionsSection: string;
    customerOrderTrackingServiceError: string;
    customerOrderNumberLabelText: string;
    customerOrderNumberPlaceholderText: string;
    customerOrderNumberValidationPattern: string;
    customerOrderNumberErrorMessage: string;
    vinLabelText: string;
    vinPlaceholderText: string;
    vinValidationPattern: string;
    vinErrorMessage: string;
    recaptchaAriaLabel: string;
    termsAndConditionsHeaderText: string;
    termsAndConditionsBodyText: string;
    acceptButtonLabelText: string;
    acceptButtonAriaLabel: string;
    orderTrackerStatus1Text: string;
    orderTrackerStatus1Sentence: string;
    orderTrackerStatus1Subcopy: string;
    orderTrackerStatus2Text: string;
    orderTrackerStatus2Sentence: string;
    orderTrackerStatus2Subcopy: string;
    orderTrackerStatus3Text: string;
    orderTrackerStatus3Sentence: string;
    orderTrackerStatus3Subcopy: string;
    orderTrackerStatus4Text: string;
    orderTrackerStatus4Sentence: string;
    orderTrackerStatus4Subcopy: string;
    orderTrackerStatus5Text: string;
    orderTrackerStatus5Sentence: string;
    orderTrackerStatus5Subcopy: string;
    orderTrackerStatus6Text: string;
    orderTrackerStatus6Sentence: string;
    orderTrackerStatus6Subcopy: string;
    statusPageGreetingText: string;
    statusPageYourText: string;
    vehicleDetailsSectionHeader: string;
    deliveryDetailsSectionHeader: string;
    orderedOnText: string;
    orderNumberText: string;
    vinText: string;
    estimatedDeliveryText?: string;
    lastUpdatedText: string;
    accessoriesLinkLabelText: string;
    accessoriesLinkHref: string;
    accessoriesLinkAriaLabel: string;
    partsAccordionAccessoriesTitle: string;
    partsAccordionPackagesTitle: string;
    partsAccordionInteriorTitle: string;
    partsAccordionModelInfoTitle: string;
    partsAccordionExteriorTitle: string;
    partInfoModalCloseButtonAriaLabel: string;
    windowStickerButtonLabelText: string;
    windowStickerButtonAriaLabel: string;
    ownerRegistrationButtonLabelText: string;
    ownerRegistrationButtonHref: string;
    ownerRegistrationButtonAriaLabel: string;
    vehicleGuideButtonLabelText: string;
    vehicleGuideButtonAriaLabel: string;
    breadcrumbStatusTitle: string;
    breadcrumbTrackingTitle: string;
}

export const useVehicleOrderTrackingContent =
    (): VehicleOrderTrackingContent | null => {
        const [content, getValueByName] = useContent(
            'common',
            'vehicle-order-tracking'
        );
        let contentFragment: any;

        if (content) {
            contentFragment = {};
            content?.elements.forEach((element) => {
                contentFragment[`${element.name}`] = getValueByName(
                    `${element.name}`
                );
            });
            if (contentFragment) {
                if (contentFragment.hide) {
                    contentFragment = null;
                }
            }
        }
        return contentFragment as VehicleOrderTrackingContent;
    };
