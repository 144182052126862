import React, { ReactElement, useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@common/index';
import { RadioButtonGroup } from '@common/form-fields/form-fields';
import { LinkButton } from '@common/modal/link-button/link-button';
import '@views/marketing-options-view/marketing-options-view.scss';
import EuPreferenceService from '@services/eu-preference-service/eu-preference-service';
import HttpService from '@services/http-service/http-service';
import { CacheService } from '@services/cache-service/cache-service';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { ExperienceFragmentModel } from '@services/content-service/content-service.interface';
import { useAnalytics } from '@hooks/use-analytics';
import PostRegistrationFields from './post-registration-fields';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { ProfileWithVehiclesResponse } from '@models/profile-with-vehicles';
import { ReturnButtonProps } from '@sections/return-button/hook/use-return-button-content';
import { DynamicDropdownContent } from '@common/dynamic-dropdown/dynamic-dropdown';
import { findPathByAlias } from '@routes/routesList';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import { useNotificationBannerContent } from '@sections/notification-banner/hooks/use-notification-banner-content';
import MarketingOptionsDetails from '@sections/marketing-options/marketing-options-details/marketing-options-details';
import ReturnButton from '@sections/return-button/return-button';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { FORDPASS_PREFERENCES_DEEPLINK } from '@constants';
import { AccountSettingsFormsLabels } from '@sections/personal-information/hooks/use-personal-information-content';
import { MarketingOptionsMessagingProps } from '@sections/marketing-options-messaging-modal/hook/use-marketing-options-messaging';
import { removeATags } from '@/components/utils/validator/validator';
import serverSideService from '@/services/server-side-service/server-side-service';

interface Props {
    showPostRegistrationForm: boolean;
    marketingOptionsContent: MarketingOptionContent;
    profileData: ProfileWithVehiclesResponse;
    returnButtonContent: ReturnButtonProps;
    dynamicDropdownContent: DynamicDropdownContent;
    fieldsLabels?: AccountSettingsFormsLabels;
    marketingOptionsMessagingContent?: MarketingOptionsMessagingProps;
}

export interface MarketingOptionContent extends ExperienceFragmentModel {
    sectionHeading: string;
    sectionDescription: string;
    yesOptionLabel: string;
    noOptionLabel: string;
    selectAll: string;
    deselectAll: string;
    saveButtonLabel: string;
    contactDetailsModalHeader: string;
    options: Options[];
    preferencesText: string;
    editButtonText: string;
    editButtonAriaLabel: string;
    cancelButtonLabelText: string;
    cancelButtonAriaLabel: string;
}

interface Options {
    optionName: string;
    optionDescription?: string;
    apiVariable?: string;
    optionShow: boolean;
}

const classname = 'marketing-options';
const selected = '1';
const unSelected = '0';

const MarketingOptionSection = ({
    showPostRegistrationForm,
    marketingOptionsContent,
    profileData,
    returnButtonContent,
    dynamicDropdownContent,
    fieldsLabels,
    marketingOptionsMessagingContent,
}: Props): ReactElement => {
    const [fireAnalytics] = useAnalytics();
    const { setNotificationContext } = useNotificationContext();
    const notificationBannerContent = useNotificationBannerContent();
    const [optionValues, setOptionValues] = useState({});
    const [radioButtonOptions, setRadioButtonOptions] = useState({});
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const cacheService = new CacheService();
    const scrollUtil = new ScrollUtil();
    const options =
        marketingOptionsContent && marketingOptionsContent.options
            ? marketingOptionsContent.options.map((option) => {
                  if (showPostRegistrationForm && option.optionShow === true) {
                      return option.apiVariable;
                  }
                  if (!showPostRegistrationForm) return option.apiVariable;
              })
            : null;
    // remove empty, null and undefined values from options
    const marketingOptionNames =
        options?.length > 0 ? options.filter(Boolean) : [];
    const MarketingOptionService = new EuPreferenceService(HttpService);
    const [subscriptionToggleLabel, setSubscriptionToggleLabel] = useState('');
    const [notificationStatus, setNotificationStatus] =
        useState<NotificationType>(null);
    const location = useLocation();
    const isWebview =
        serverSideService.isClientSide() && location.search.includes('webview');
    const appConfig = new AppConfigurationService();
    const brand = appConfig.getBrand() === 'ford';

    const subscribeAll = () => {
        fireAnalytics('subscribeMarketingOptionsOnclickEvent');
        const marketingOptionValues = {};
        marketingOptionNames.forEach((option) => {
            marketingOptionValues[option] = selected;
        });
        setOptionValues(marketingOptionValues);
    };

    const unsubscribeAll = () => {
        fireAnalytics('unsubscribeMarketingOptionsOnclickEvent');
        const marketingOptionValues = {};
        marketingOptionNames.forEach((option) => {
            marketingOptionValues[option] = unSelected;
        });
        setOptionValues(marketingOptionValues);
    };

    const toggleSubscriptionLabel = () => {
        if (subscriptionToggleLabel === marketingOptionsContent?.selectAll) {
            subscribeAll();
            setSubscriptionToggleLabel(marketingOptionsContent?.deselectAll);
        }
        if (subscriptionToggleLabel === marketingOptionsContent?.deselectAll) {
            unsubscribeAll();
            setSubscriptionToggleLabel(marketingOptionsContent?.selectAll);
        }
    };

    const getUserPreferences = (data, option: string) =>
        data?.privacyPreferences?.filter(
            (preference) => preference?.preferenceName === option
        );

    const getOptionsData = (optionsData) => {
        if (profileData && profileData.profile) {
            const preselectedValues = { ...optionsData };
            Object.keys(optionsData).length > 0 &&
                Object.keys(optionsData).map((option) => {
                    const userPreference = getUserPreferences(
                        profileData.profile,
                        option
                    );
                    const [preference] = userPreference || [];
                    preselectedValues[option] =
                        preference?.preferenceCode === '1'
                            ? selected
                            : preference?.preferenceCode === '0'
                            ? unSelected
                            : null;
                });
            return preselectedValues;
        }
        return optionsData;
    };

    useEffect(() => {
        removeMobilePhoneOptionIfMissingFromProfile();
        if (marketingOptionNames && Object.keys(optionValues).length === 0) {
            const marketingOptionValues = {};
            const marketingRadioOption = {};

            marketingOptionNames.forEach((option) => {
                marketingOptionValues[option] = null; // need to set initial selected value
                marketingRadioOption[option] = [
                    {
                        name: `${option}-radio-option`,
                        value: selected,
                        displayName: `${marketingOptionsContent?.yesOptionLabel}`,
                        id: `${option}-yes`,
                    },
                    {
                        name: `${option}-radio-option`,
                        value: unSelected,
                        displayName: marketingOptionsContent?.noOptionLabel,
                        id: `${option}-no`,
                    },
                ];
            });

            const marketingOptions = getOptionsData({
                ...marketingOptionValues,
            });

            setOptionValues(marketingOptions);
            setRadioButtonOptions(marketingRadioOption);
        }
    }, [marketingOptionsContent, profileData]);

    useEffect(() => {
        removeMobilePhoneOptionIfMissingFromProfile();
    }, [optionValues]);

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
    }, []);

    const removeMobilePhoneOptionIfMissingFromProfile = () => {
        if (
            profileData &&
            marketingOptionsContent &&
            !profileData.profile.mobilePhoneNumber
        ) {
            marketingOptionsContent.options =
                marketingOptionsContent.options.filter(
                    (option) => option.apiVariable !== 'MobilePermission'
                );
            delete optionValues['MobilePermission'];
        }
    };

    const handleSave = async (isPostRegistration = false, inputData = {}) => {
        setNotificationStatus(null);
        fireAnalytics('saveMarketingOptionsOnclickEvent');

        let userSelectionOptions;
        if (isPostRegistration) {
            userSelectionOptions = { ...inputData };
        } else {
            userSelectionOptions = { ...optionValues };
        }
        if (
            !Object.values(userSelectionOptions).some((value) => value === null)
        ) {
            setIsLoading(true);
            const updateData = Object.keys(userSelectionOptions).map(
                (optionName) => {
                    return {
                        preferenceType: optionName,
                        preferenceCode: Number(
                            userSelectionOptions[optionName]
                        ),
                    };
                }
            );
            const payload = updateData.filter(
                (option) => option.preferenceType != 'undefined'
            );
            const response = await MarketingOptionService.updatePreferences(
                payload
            );
            if (response.error === null) {
                cacheService.evictProfileServiceCache();
                setNotificationStatus(NotificationType.Success);
                setIsEdit(false);
                scrollUtil.scrollPageToTop();
                isWebview &&
                    brand &&
                    window.location.assign(FORDPASS_PREFERENCES_DEEPLINK);
                if (isPostRegistration) {
                    navigate(findPathByAlias('AccountDashboardView'));
                }
            } else if (response.status !== '200') {
                setNotificationStatus(NotificationType.Error);
                scrollUtil.scrollPageToTop();
            }
            setIsLoading(false);
        }
    };

    const handleChange = (value: any, option: any) => {
        setOptionValues((prevState) => ({
            ...prevState,
            [option.apiVariable]: value,
        }));
    };

    useEffect(() => {
        if (Object.values(optionValues).every((value) => value === '1')) {
            setSubscriptionToggleLabel(marketingOptionsContent?.deselectAll);
        } else {
            setSubscriptionToggleLabel(marketingOptionsContent?.selectAll);
        }
    }, [optionValues]);

    const isSaveButtonDisabled = !Object.values(optionValues).some(
        (value) => value === null
    );

    useEffect(() => {
        showPostRegistrationForm &&
            fireAnalytics('ownerAccountEUPostRegistration');
    }, [showPostRegistrationForm]);

    const MarketingOptionsFields = () => {
        return (
            <Fragment>
                {marketingOptionsContent.options.map((options, index) => {
                    return (
                        <Fragment key={`option-${index}`}>
                            <div
                                className={`${classname}-list list-item ${
                                    marketingOptionsContent.options.length -
                                        1 ===
                                    index
                                        ? 'last-item'
                                        : ''
                                }`}
                                data-testid="marketing-option"
                            >
                                <div
                                    className={`${classname}-name-description-ctn`}
                                >
                                    <div className={`${classname}-name`}>
                                        {options.optionName}
                                    </div>
                                    {options.optionDescription && (
                                        <div
                                            className={`${classname}-description`}
                                        >
                                            {options.optionDescription}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <RadioButtonGroup
                                        RadioButtonOptions={
                                            radioButtonOptions[
                                                options.apiVariable
                                            ]
                                        }
                                        defaultCheckedOption={
                                            optionValues[options.apiVariable]
                                        }
                                        handleChange={(value) =>
                                            handleChange(value, options)
                                        }
                                        isHorizontal={false}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </Fragment>
        );
    };

    const MarketingOptionsForm = () => {
        return (
            <>
                {!marketingOptionsContent && <ActivityIndicator />}
                {marketingOptionsContent &&
                returnButtonContent &&
                Object.keys(radioButtonOptions).length > 0 ? (
                    <div className={`${classname}-container`}>
                        {isLoading && (
                            <ActivityIndicator
                                className={'fds-activity-indicator__center'}
                            />
                        )}
                        {!showPostRegistrationForm && (
                            <ReturnButton
                                returnButtonContent={returnButtonContent}
                            />
                        )}

                        <div className={`${classname}-content-container`}>
                            {notificationStatus && (
                                <div
                                    className={`${classname}-notification-container`}
                                    data-testid={`${classname}-notification-container`}
                                >
                                    <Notification
                                        status={notificationStatus}
                                        mainCopy={
                                            notificationStatus ===
                                            NotificationType.Success
                                                ? notificationBannerContent.notificationSuccessText
                                                : notificationBannerContent.notificationFailureText
                                        }
                                        hideBorder={true}
                                        hideAfterTimeout={true}
                                        onHideNotification={() => {
                                            setNotificationStatus(null);
                                        }}
                                    />
                                </div>
                            )}
                            <h2 className={`${classname}-page-header`}>
                                {marketingOptionsContent.sectionHeading}
                            </h2>
                            <div
                                className={`${classname}-page-description`}
                                dangerouslySetInnerHTML={{
                                    __html: isWebview
                                        ? removeATags(
                                              marketingOptionsContent?.sectionDescription
                                          )
                                        : marketingOptionsContent?.sectionDescription,
                                }}
                            />

                            <div className="marketing-options__header-and-button-container">
                                <h2 className="marketing-options__header">
                                    {marketingOptionsContent.preferencesText}
                                </h2>
                                {!isEdit && !showPostRegistrationForm ? (
                                    <LinkButton
                                        ariaControls={'marketing-options-form'}
                                        ariaExpanded={isEdit}
                                        label={
                                            marketingOptionsContent.editButtonText
                                        }
                                        ariaLabel={
                                            marketingOptionsContent.editButtonAriaLabel
                                        }
                                        dataTestId="marketing-options-edit-button"
                                        class={`marketing-options-edit-button ${
                                            isEdit ? 'hide' : ''
                                        }`}
                                        onClick={() => setIsEdit(true)}
                                        id="marketing-options-edit-button"
                                        ariaHidden={isEdit}
                                        tabIndex={isEdit && -1}
                                        isFromMarketingOptions
                                    />
                                ) : null}
                            </div>

                            <hr className={`${isEdit ? 'edit-state' : ''}`} />

                            {!isEdit && !showPostRegistrationForm ? (
                                <MarketingOptionsDetails
                                    optionValues={optionValues}
                                    marketingOptionsContent={
                                        marketingOptionsContent
                                    }
                                />
                            ) : (
                                <div className={`${classname}-section-wrapper`}>
                                    <div
                                        className={`${classname}-select-deselect-button-container`}
                                    >
                                        <LinkButton
                                            label={subscriptionToggleLabel}
                                            class={`marketing-options-select-deselect-all-button`}
                                            dataTestId="select-deselect-btn"
                                            onClick={() => {
                                                toggleSubscriptionLabel();
                                            }}
                                            isFromMarketingOptions
                                            tabIndex={0}
                                        />
                                    </div>
                                    {showPostRegistrationForm &&
                                    fieldsLabels &&
                                    marketingOptionsMessagingContent ? (
                                        <PostRegistrationFields
                                            options={optionValues}
                                            marketingOptionsContent={
                                                marketingOptionsContent
                                            }
                                            dynamicDropdownContent={
                                                dynamicDropdownContent
                                            }
                                            radioButtonOptions={
                                                radioButtonOptions
                                            }
                                            handleSave={handleSave}
                                            fieldsLabels={fieldsLabels}
                                            marketingOptionsMessagingContent={
                                                marketingOptionsMessagingContent
                                            }
                                        />
                                    ) : (
                                        <div className="marketing-options-fields-plus-buttons-container">
                                            <MarketingOptionsFields />
                                            <div
                                                className={`${classname}-button-container`}
                                            >
                                                <LinkButton
                                                    label={
                                                        marketingOptionsContent.cancelButtonLabelText
                                                    }
                                                    onClick={() =>
                                                        setIsEdit(false)
                                                    }
                                                    ariaLabel={
                                                        marketingOptionsContent.cancelButtonAriaLabel
                                                    }
                                                    dataTestId="edit-marketing-options-cancel-button"
                                                    class="edit-marketing-options-cancel-button"
                                                    isFromMarketingOptions
                                                />
                                                <PrimaryButton
                                                    onClick={async () =>
                                                        await handleSave()
                                                    }
                                                    disabled={
                                                        !isSaveButtonDisabled
                                                    }
                                                    testId={`${classname}-button-save`}
                                                >
                                                    {
                                                        marketingOptionsContent.saveButtonLabel
                                                    }
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </>
        );
    };

    return (
        <Fragment>
            <MarketingOptionsForm />
        </Fragment>
    );
};

export default MarketingOptionSection;
