import React, { useEffect, useRef, useState } from 'react';
import { useOrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { SearchBar } from '@/components/sections';
import './return-confirmation-view.scss';
import { Breadcrumbs } from '@sections/breadcrumbs/breadcrumbs';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { findPathByAlias } from '@routes/routesList';
import { useLocation } from 'react-router-dom';
import SecondaryButton from '@/components/common/secondary-button/secondary-button';
import OmsService from '@/services/oms-service/oms-service';
import HttpService from '@/services/http-service/http-service';
import { NotificationType } from '@/contexts/notificationContext';
import { Notification } from '@/components/sections/account-portal/components/notification-message/notification';
import { useAnalytics } from '@/hooks/use-analytics';

const ReturnConfirmationView = () => {
    useAnalytics('completeReturn');
    const [fireAnalytics] = useAnalytics();
    const appConfig = new AppConfigurationService();
    const location = useLocation();
    const didMount = useRef(false);
    const orderDetailsContent = useOrderDetailsContent();
    const [print, setPrint] = useState(false);
    const [printInfo, setPrintInfo] = useState('');
    const [returnItems, setReturnItems] = useState(
        location.state?.returnResponseContent
    );
    const hasOrderDetailsContent: boolean =
        Object.keys(orderDetailsContent).length > 0;
    const [returnLabelFinishLoading, setReturnLabelFinishLoading] =
        useState(false);
    const [packingSlipFinishLoading, setPackingSlipFinishLoading] =
        useState(false);
    const [notificationStatus, setNotificationStatus] =
        useState<NotificationType>(null);
    const [notificationMessage, setNotificationMessage] =
        useState<string>(null);
    const omsService = new OmsService(HttpService);

    useEffect(() => {
        getReturnLabels(returnItems);
        getPackingSlip(returnItems);
    }, []);

    const getReturnLabels = (returnItems) => {
        returnItems?.orderLine.map((item) => {
            omsService
                .getReturnLabel(returnItems?.orderHeaderKey, item.orderLineKey)
                .then((returnLabel) => {
                    item['returnLabel'] = returnLabel?.returnLabel;
                });
        });
        setReturnLabelFinishLoading(true);
        setReturnItems(returnItems);
    };

    const getPackingSlip = (returnItems) => {
        omsService
            .getPackingSlip(returnItems?.orderHeaderKey)
            .then((packingSlip) => {
                returnItems['packingSlip'] = packingSlip?.base64ByteCode;
                setPackingSlipFinishLoading(true);
            })
            .catch(() => {
                setPackingSlipFinishLoading(true);
            });
        setReturnItems(returnItems);
    };

    const generateImageSrc = (orderLineItem) => {
        if (orderLineItem.itemDetails.primaryInformation?.imageID) {
            if (orderLineItem.itemDetails.extn?.extnItemSubType === 'FPP') {
                return orderDetailsContent.performancePartsImageUrl.replace(
                    /:partNumber/g,
                    orderLineItem.itemDetails.primaryInformation?.imageID
                );
            } else {
                return orderDetailsContent.accessoriesImageUrl.replace(
                    /:partNumber/g,
                    orderLineItem.itemDetails.primaryInformation?.imageID
                );
            }
        } else {
            return orderDetailsContent.accessoriesImageUrl.replace(
                /:partNumber/g,
                orderLineItem.item.itemId + '_0.jpg'
            );
        }
    };

    function printReturnLabel(index: string) {
        fireAnalytics('completeReturnReturnLabelOnclickEvent');
        if (returnItems?.orderLine[index]?.returnLabel) {
            setPrintInfo(returnItems?.orderLine[index].returnLabel);
            setPrint(true);
        } else {
            setNotificationStatus(NotificationType.Error);
            setNotificationMessage(
                orderDetailsContent.returnLabelErrorNotificationMessage
            );
        }
    }

    function printPackingSlip() {
        fireAnalytics('completeReturnReturnSlipOnclickEvent');
        if (returnItems?.packingSlip) {
            setPrintInfo(returnItems?.packingSlip);
            setPrint(true);
        } else {
            setNotificationStatus(NotificationType.Error);
            setNotificationMessage(
                orderDetailsContent.packingSlipErrorNotificationMessage
            );
        }
    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }
        let originalWindowTitleBeforePrint: string;
        if (typeof window !== 'undefined') {
            if (print) {
                originalWindowTitleBeforePrint = window.document.title;
                window.onbeforeprint = () => {
                    window.document.title = '';
                };

                window.onafterprint = () => {
                    window.document.title = originalWindowTitleBeforePrint;
                    setPrint(false);
                };
                window.print();
            }
        }
    }, [print]);

    return (
        <>
            {hasOrderDetailsContent &&
            packingSlipFinishLoading &&
            returnLabelFinishLoading ? (
                <div className="return-confirmation-view">
                    {print && (
                        <div className="print-return">
                            <img
                                src={`data:image/jpeg;base64,${printInfo}`}
                                width={'700px'}
                                height={'830px'}
                            />
                        </div>
                    )}
                    <div
                        className="return-confirmation-view__container"
                        data-testid="return-confirmation-view-container"
                    >
                        {location.state?.guestReturn && (
                            <Breadcrumbs
                                currentPage={
                                    orderDetailsContent.createReturnBreadcrumbTitle
                                }
                                previousPage={
                                    orderDetailsContent.guestReturnBreadcrumbTitle
                                }
                                previousLink={findPathByAlias(
                                    'GuestReturnView'
                                )}
                                hideBaseBreadcrumb={true}
                                crumbSeparator={
                                    appConfig.brand === 'ford' ? '/' : '>'
                                }
                            ></Breadcrumbs>
                        )}
                        {location.state?.guestOrder && (
                            <Breadcrumbs
                                currentPage={
                                    orderDetailsContent.createReturnBreadcrumbTitle
                                }
                                previousPage={
                                    orderDetailsContent.guestDetailsBreadcrumbTitle
                                }
                                previousLink={findPathByAlias('GuestOrderView')}
                                hideBaseBreadcrumb={true}
                                crumbSeparator={
                                    appConfig.brand === 'ford' ? '/' : '>'
                                }
                            ></Breadcrumbs>
                        )}
                        {!location.state?.guestOrder &&
                            !location.state?.guestReturn && (
                                <Breadcrumbs
                                    currentPage={
                                        orderDetailsContent.createReturnBreadcrumbTitle
                                    }
                                    previousPage={
                                        orderDetailsContent.createReturnBreadcrumbPreviousTitle
                                    }
                                    secondPreviousPage={
                                        orderDetailsContent.createReturnBreadcrumbSecondPreviousTitle
                                    }
                                    previousLink={
                                        location.state?.orderDetails
                                            ? findPathByAlias(
                                                  'OrderDetailsViewWithoutOrderNumber',
                                                  location.state?.orderDetails
                                                      .orderNo
                                              )
                                            : findPathByAlias(
                                                  'OrderHistoryView'
                                              )
                                    }
                                    secondPreviousLink={findPathByAlias(
                                        'OrderHistoryView'
                                    )}
                                    crumbSeparator={
                                        appConfig.brand === 'ford' ? '/' : '>'
                                    }
                                ></Breadcrumbs>
                            )}
                        {notificationStatus && (
                            <div
                                className={`return-confirmation-view-notification-container`}
                                data-testid={`return-confirmation-view-notification-container`}
                            >
                                <Notification
                                    status={notificationStatus}
                                    mainCopy={notificationMessage}
                                    hideBorder={true}
                                    hideAfterTimeout={false}
                                    onHideNotification={() => {
                                        setNotificationStatus(null);
                                    }}
                                />
                            </div>
                        )}
                        <div
                            className="return-confirmation-view__content"
                            data-testid="return-confirmation-view-content"
                        >
                            <h1
                                className="return-confirmation-view__header"
                                data-testid="return-confirmation-view-header"
                            >
                                {
                                    orderDetailsContent.completeReturnPageHeaderText
                                }
                            </h1>
                            <p
                                className="return-confirmation-view__subheader"
                                data-testid="return-confirmation-view-subheader"
                            >
                                {
                                    orderDetailsContent.completeReturnPageSubheaderText
                                }
                            </p>
                            {returnItems.orderLine.map(
                                (orderLineItem, index) => (
                                    <div
                                        className="return-confirmation-item__container"
                                        data-testid="return-confirmation-item__container"
                                        id={orderLineItem.item.itemId}
                                        key={orderLineItem.item.itemId}
                                    >
                                        <div
                                            className={`item__status`}
                                            data-testid="item-status"
                                        >
                                            <p>
                                                {
                                                    orderDetailsContent.completeReturnPageItemsToReturnText
                                                }
                                            </p>
                                        </div>
                                        <img
                                            src={generateImageSrc(
                                                orderLineItem
                                            )}
                                            className={`item__image`}
                                            alt="item image"
                                            data-testid="item-image"
                                        />
                                        <div className="item__info-container">
                                            <p
                                                className="item__title"
                                                data-testid="item-title"
                                            >
                                                {
                                                    orderLineItem.itemDetails
                                                        .primaryInformation
                                                        .description
                                                }
                                            </p>
                                            <p
                                                className="item__id"
                                                data-testid="item-id"
                                            >
                                                {orderLineItem.item.itemId}
                                            </p>
                                            <p
                                                className="item__price"
                                                data-testid="item-price"
                                            >
                                                $
                                                {
                                                    orderLineItem
                                                        .lineOverallTotals
                                                        .unitPrice
                                                }
                                            </p>
                                            <p
                                                className="item__quantity"
                                                data-testid="item-quantity"
                                            >
                                                {orderDetailsContent.quantity}{' '}
                                                {Math.floor(
                                                    Number(
                                                        orderLineItem.orderedQty
                                                    )
                                                )}
                                            </p>
                                        </div>
                                        <div className="item__buttons-container">
                                            <SecondaryButton
                                                className="print-button"
                                                data-testid="print-button"
                                                internal={false}
                                                onClick={printPackingSlip}
                                                aria-label={
                                                    orderDetailsContent.completeReturnPageReturnSlipButtonAriaLabel
                                                }
                                            >
                                                {
                                                    orderDetailsContent.completeReturnPageReturnSlipButtonText
                                                }
                                            </SecondaryButton>
                                            <SecondaryButton
                                                className="print-button"
                                                data-testid="print-button"
                                                internal={false}
                                                onClick={() =>
                                                    printReturnLabel(index)
                                                }
                                                aria-label={
                                                    orderDetailsContent.completeReturnPageReturnLabelButtonAriaLabel
                                                }
                                            >
                                                {
                                                    orderDetailsContent.completeReturnPageReturnLabelButtonText
                                                }
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <ActivityIndicator className="full-height" />
            )}
            <hr className="return-confirmation-view__divider" />
            <SearchBar />
        </>
    );
};

export default ReturnConfirmationView;
