import { useContent } from '@hooks/use-server-content';

export interface SecurityContent {
    returnButtonText: string;
    brandLogo: string;
    signInCredentialsSectionHeader: string;
    emailSectionLabel: string;
    passwordSectionLabel: string;
    MFASectionHeader: string;
    MFADescription: string;
    MFAEmailAuthenticationSectionLabel: string;
    MFAEmailAuthenticationDescriptionText: string;
    MFAAppAuthenticationSectionLabel: string;
    MFAAppAuthenticationDescriptionText: string;
    MFASmsAuthenticationSectionLabel: string;
    MFASmsAuthenticationDescriptionText: string;
    editButtonText: string;
    addButtonText: string;
    removeButtonText: string;
    errorPageReturnButtonText: string;
    errorPageReturnButtonAriaLabel: string;
    errorPageAlertIcon: string;
    errorPageHeaderText: string;
    errorPageDescriptionText: string;
    errorPageCancelButtonText: string;
    errorPageCancelButtonAriaLabel: string;
}

export const useSecurityContent = (): SecurityContent | null => {
    const [content, getValueByName] = useContent('common', 'security');
    const contentFragment: any = {};

    if (content) {
        content?.elements?.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }

    return contentFragment as SecurityContent;
};
