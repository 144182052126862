import { useContent } from '@hooks/use-server-content';

export interface MarketingOptionsMessagingProps {
    inAppMessageModalHeader: string;
    contactDetailsModalHeader: string;
    btnSave: string;
    phoneNumberPrivacyDescription: string;
}
export const useMarketingOptionsMessaging =
    (): MarketingOptionsMessagingProps | null => {
        const [content, getValueByName] = useContent(
            'common',
            'marketing-options-modal-forms'
        );
        const contentFragment: any = {};
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
        return contentFragment as MarketingOptionsMessagingProps;
    };
