import AuthenticationService from '@services/authentication-service/authentication-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '@services/http-service/http-service';
import httpAdapter from 'axios/lib/adapters/http';
import { HttpsProxyAgent } from 'https-proxy-agent';
import { PrognosticsServiceResponse } from '@models/vehicle-health';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export default class PrognosticsService {
    private httpService: any;
    private authenticationService: any;
    private appConfig = new AppConfigurationService();
    private dslUrl = this.appConfig.getAppConfiguration().dslUrl
        ? this.appConfig.getAppConfiguration().dslUrl
        : DSL_BASE_URL;
    private url: string = this.dslUrl + DSL_API_PATH.PROGNOSTICS;
    public config: any;

    constructor(
        httpService: HttpService,
        authenticationService: AuthenticationService
    ) {
        this.httpService = httpService;
        this.authenticationService = authenticationService;

        this.config = {
            headers: {
                ...this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                vin: '',
            },
            adapter: httpAdapter,
            proxy: false,
            httpAgent: proxyAgent,
        };
    }

    public async getVehiclePrognosticsAlerts(
        vin: string
    ): Promise<PrognosticsServiceResponse> {
        const appConfig = new AppConfigurationService();

        this.url = `${this.dslUrl + DSL_API_PATH.PROGNOSTICS}?countryCode=${
            appConfig.current3LetterCountryCapitalizedCode
        }&languageCode=${appConfig.currentLanguageRegionCode}&brand=${
            appConfig.brand
        }`;

        this.config.headers.vin = vin;

        try {
            const response = await this.httpService.get(
                this.url,
                true,
                this.config
            );
            return response.data;
        } catch (e) {
            return e.message;
        }
    }
}
