import { useContent } from '@hooks/use-server-content';

export interface OrderHistoryContent {
    breadcrumbTitle: string;
    pageTitle: string;
    subtitleText: string;
    ordersName: string;
    pastOrdersHeader: string;
    orderDateLabelText: string;
    orderTotalLabelText: string;
    orderNumberOfItemsLabelText: string;
    viewDetailsButtonLabelText: string;
    viewDetailsButtonAriaLabel: string;
    showMoreOrdersButtonLabelText: string;
    showMoreOrdersButtonAriaLabel: string;
    paginationLabelText: string;
    paginationSeparator: string;
    breadcrumbTitleOrderDetails?: string;
    orderNumber?: string;
    billingAddress?: string;
    shippingAddress?: string;
    startAReturn?: string;
    returnsPolicy?: string;
    errorNotificationMessage?: string;
}
export const useOrderHistoryContent = (): OrderHistoryContent | null => {
    const [content, getValueByName] = useContent('common', 'nvc-order-history');
    const contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    return contentFragment as OrderHistoryContent;
};
