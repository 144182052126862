import { useEffect, useRef, useCallback, useState, RefObject } from 'react';

export interface SwipePoint {
    x: number;
    y: number;
}

export const useSwipe = (
    isMobile: boolean,
    onSwipeStart: (swipeStartPoint: SwipePoint) => void,
    onSwipeMove: (swipeCurrentPoint: SwipePoint) => void,
    onSwipeEnd: () => void
): RefObject<HTMLDivElement> => {
    const [isSwiping, setIsSwiping] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    function isPointerEvent(
        event: PointerEvent | TouchEvent | MouseEvent
    ): event is PointerEvent {
        return typeof (event as PointerEvent).pointerId !== 'undefined';
    }

    function isTouchEvent(
        event: PointerEvent | TouchEvent | MouseEvent
    ): event is TouchEvent {
        return !!(event as TouchEvent).touches;
    }

    function moreThanOneTouchPoint(event: TouchEvent): boolean {
        return event.touches && event.touches.length > 1;
    }

    function browserSupportsPointerEvents(): boolean {
        return !!window.PointerEvent;
    }

    function startCapturingPointerMovement(event: PointerEvent) {
        (event.target as HTMLDivElement).setPointerCapture(event.pointerId);
    }

    function stopCapturingPointerMovement(event: PointerEvent) {
        (event.target as HTMLDivElement).releasePointerCapture(event.pointerId);
    }

    function addMouseEventListeners(
        onMouseMove: (event: MouseEvent) => void,
        onMouseUp: (event: MouseEvent) => void
    ) {
        document.addEventListener('mousemove', onMouseMove, true);
        document.addEventListener('mouseup', onMouseUp, true);
    }

    function removeMouseEventListeners(
        onMouseMove: (event: MouseEvent) => void,
        onMouseUp: (event: MouseEvent) => void
    ) {
        document.removeEventListener('mousemove', onMouseMove, true);
        document.removeEventListener('mouseup', onMouseUp, true);
    }

    const getEventPoint = useCallback(
        (event: PointerEvent | TouchEvent | MouseEvent): SwipePoint => {
            if (isTouchEvent(event)) {
                return {
                    x: event.targetTouches[0].clientX,
                    y: event.targetTouches[0].clientY,
                };
            } else {
                return {
                    x: event.clientX,
                    y: event.clientY,
                };
            }
        },
        []
    );

    const handleSwipeMove = useCallback(
        (event: PointerEvent | TouchEvent | MouseEvent) => {
            if (isSwiping) {
                onSwipeMove(getEventPoint(event));
            }
        },
        [onSwipeMove, isSwiping, getEventPoint]
    );

    const handleSwipeEnd = useCallback(
        (event: PointerEvent | TouchEvent | MouseEvent) => {
            event.preventDefault();

            if (isTouchEvent(event) && event.touches.length > 0) {
                return;
            }

            if (browserSupportsPointerEvents() && isPointerEvent(event)) {
                stopCapturingPointerMovement(event);
            } else {
                removeMouseEventListeners(handleSwipeMove, handleSwipeEnd);
            }

            setIsSwiping(false);
            onSwipeEnd();
        },
        [onSwipeEnd, handleSwipeMove, setIsSwiping]
    );

    const handleSwipeStart = useCallback(
        (event: PointerEvent | TouchEvent | MouseEvent) => {
            event.preventDefault();

            if (isTouchEvent(event) && moreThanOneTouchPoint(event)) {
                return;
            }

            if (browserSupportsPointerEvents() && isPointerEvent(event)) {
                startCapturingPointerMovement(event);
            } else {
                addMouseEventListeners(handleSwipeMove, handleSwipeEnd);
            }

            setIsSwiping(true);
            onSwipeStart(getEventPoint(event));
        },
        [
            onSwipeStart,
            handleSwipeMove,
            handleSwipeEnd,
            setIsSwiping,
            getEventPoint,
        ]
    );

    const addPointerEvents = (currentRef) => {
        currentRef.addEventListener('pointerdown', handleSwipeStart, true);
        currentRef.addEventListener('pointermove', handleSwipeMove, true);
        currentRef.addEventListener('pointerup', handleSwipeEnd, true);
        currentRef.addEventListener('pointercancel', handleSwipeEnd, true);
    };

    const removePointerEvents = (currentRef) => {
        currentRef.removeEventListener('pointerdown', handleSwipeStart, true);
        currentRef.removeEventListener('pointermove', handleSwipeMove, true);
        currentRef.removeEventListener('pointerup', handleSwipeEnd, true);
        currentRef.removeEventListener('pointercancel', handleSwipeEnd, true);
    };

    const addTouchEvents = (currentRef) => {
        currentRef.addEventListener('touchstart', handleSwipeStart, true);
        currentRef.addEventListener('touchmove', handleSwipeMove, true);
        currentRef.addEventListener('touchend', handleSwipeEnd, true);
        currentRef.addEventListener('touchcancel', handleSwipeEnd, true);
        currentRef.addEventListener('mousedown', handleSwipeStart, true);
    };

    const removeTouchEvents = (currentRef) => {
        currentRef.removeEventListener('touchstart', handleSwipeStart, true);
        currentRef.removeEventListener('touchmove', handleSwipeMove, true);
        currentRef.removeEventListener('touchend', handleSwipeEnd, true);
        currentRef.removeEventListener('touchcancel', handleSwipeEnd, true);
        currentRef.removeEventListener('mousedown', handleSwipeStart, true);
    };

    useEffect(() => {
        if (isMobile) {
            let currentRef = null;
            if (ref.current) {
                currentRef = ref.current;
                if (window.PointerEvent) {
                    addPointerEvents(ref.current);
                } else {
                    addTouchEvents(ref.current);
                }
            }
            return () => {
                if (window.PointerEvent) {
                    removePointerEvents(currentRef);
                } else {
                    removeTouchEvents(currentRef);
                }
            };
        }
    }, [ref, isMobile, handleSwipeStart, handleSwipeMove, handleSwipeEnd]);

    return ref;
};
