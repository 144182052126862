import { useContent } from '@hooks/use-server-content';

export interface PreferredDealerModalContentProps {
    headerText: string;
    vinLabelText: string;
    noDealerSelectedText: string;
    dealerInfoRetrievalError: string;
    btnSelect: string;
    btnSelectAriaLabel: string;
    btnEdit: string;
    btnEditAriaLabel: string;
    btnCancel: string;
}

export const usePreferredDealerModalContent =
    (): PreferredDealerModalContentProps | null => {
        const [content, getValueByName] = useContent(
            'common',
            'preferred-dealer-modal'
        );
        let contentFragment: any = {};
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
        if (contentFragment) {
            if (contentFragment.hide) {
                contentFragment = null;
            }
        }
        return contentFragment as PreferredDealerModalContentProps;
    };
