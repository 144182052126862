import React, { useEffect, useRef, useState } from 'react';
import { useAnalytics } from '@hooks/use-analytics';
import ProfileService from '@/services/profile-service/profile-service';
import { InputField } from '@/components/common/form-fields/form-fields';

import { SwipePoint } from '../hooks/use-swipe';
import { VehicleCarouselContent } from '../../vehicle-tabs/hooks/use-vehicle-carousel-content';
import { Card } from '../../card/card';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { CustomerBooking } from '@services/gcct-service/gcct-service';
import SecondaryButton from '@common/secondary-button/secondary-button';
import ConnectedVehicleService from '@services/connected-vehicle-service/connected-vehicle-service';
import HttpService from '@services/http-service/http-service';
import {
    setLocalStorageWithSelectedVIN,
    setSessionStorageWithSelectedVIN,
    VEHICLE_CARD_STORAGE_KEY,
} from '@constants';
import StatusChip from '@sections/account-portal/components/vehicle-carousel/vehicle-card/vha-notification/status-chip';
import PrognosticsService from '@services/prognostics-service/prognostics-service';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { Nhtsa, PrognosticsData, Recalls } from '@models/vehicle-health';
import RecallsService from '@services/recalls-service/recalls-service';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

export interface CardData {
    hide?: boolean;
    title?: string;
    nickname?: string;
    description?: string;
    imageSrc?: string;
    imageAlt?: string;
    ctaHref?: string;
    ctaTarget?: string;
    ctaLabel?: string;
    ctaAriaLabel?: string;
    year?: number;
    vehicleAttributes?: any;
    myLabel?: string;
    orderStatus?: string;
    orderNumber?: string;
}

export interface MmotaData {
    tirePressureStatus: string;
    oilLifeStatus: string;
    oilLifeValue: string;
    fuelLevelValue: string;
    fuelLevelDTE: string;
    chargeLevelValue: string;
    electricDTE: string;
    odometerValue: number;
    uomDistanceUnit: string;
}

interface Props {
    data: any;
    updateVehiclesData?: (cachable: boolean) => void;
    identification?: number;
    cardContentFragments?: VehicleCarouselContent;
    onRemove?: () => void;
    onTouchStart?: (swipePoint: SwipePoint) => void;
    onTouchEnd?: () => void;
    onTabNavigation?: (index: number) => void;
    booking: CustomerBooking;
}

export const VehicleCard = ({
    data,
    cardContentFragments,
    updateVehiclesData,
    onRemove,
    identification,
    onTabNavigation,
    booking,
}: Props) => {
    const [fireAnalytics] = useAnalytics();
    const profileService = new ProfileService();
    const httpService = HttpService;
    const authenticationService = new AuthenticationService();
    const { setContext, resetContext } = useModalContext();
    const [isConnectedVehicle, setIsConnectedVehicle] =
        useState<boolean>(false);
    const appConfig = new AppConfigurationService();
    const isLincoln = appConfig.brand === 'lincoln';
    const isFord = appConfig.brand === 'ford';
    const isConnectedVehicleAuthored =
        cardContentFragments.mmotaOdometerMilesText &&
        cardContentFragments.mmotaOdometerKilometersText &&
        cardContentFragments.mmotaFuelLevelLabelText &&
        cardContentFragments.mmotaOilLifeLabelText &&
        cardContentFragments.mmotaOilLifeNormalValue &&
        cardContentFragments.mmotaOilLifeLowValue &&
        cardContentFragments.mmotaTirePressureLabelText &&
        cardContentFragments.mmotaTirePressureValueNormal &&
        cardContentFragments.mmotaTirePressureValueLow &&
        cardContentFragments.mmotaChargeLevelLabelText &&
        cardContentFragments.mmotaRemainingRangeLabelText;
    const isBatteryElectricVehicle = data.fuelType === 'E';
    const isEditVehicleModalVisible = useRef(false);
    const vehicleNicknameRef = useRef(data.nickname || null);
    const [vehicleNickname, setVehicleNickname] = useState<string>(
        data.nickname || null
    );
    const [vehiclePrognosticsAlerts, setVehiclePrognosticsAlerts] =
        useState<PrognosticsData[]>(null);

    const [recallsNhtsaVehicleAlerts, setRecallsNhtsaVehicleAlerts] =
        useState<Nhtsa[]>(null);

    const [recallsVehicleAlerts, setRecallsVehicleAlerts] =
        useState<Recalls[]>(null);

    const [showToolTip, setToolTipState] = useState(false);
    const [editVehicle] = [`edit-nic-modal-${identification} edit-modal`];
    const [editVehicleModal, deleteVehicleModal] = [
        'edit-vehicle-modal',
        'delete-vehicle-modal',
    ];
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tooltipCaretHighlight, setTooltipCaretHighlight] =
        useState<boolean>(false);
    const tooltipRef = useRef(null);
    const [tabbedFromEditButton, setTabbedFromEditButton] = useState(false);
    const displayCTA: boolean = data.ctaLabel && data.ctaHref;
    const [mmotaData, setMmotaData] = useState<MmotaData>(null);
    const openToolTipHandler = () => {
        setTimeout(() => {
            setToolTipState(true);
        }, 0);
    };

    const closeToolTipHandler = () => {
        setTooltipCaretHighlight(false);
        setToolTipState(false);
    };

    const focusNextElement = (isUpdate = false, isDelete = false) => {
        if (isUpdate && !isDelete && data.ctaLabel && data.ctaHref) {
            document
                .getElementById(`view-vehicle-btn-${identification}`)
                ?.focus();
        } else {
            const elementCount = isDelete ? identification : identification + 1;
            const kababMenu = document.getElementById(
                `tooltip-menu-${elementCount}`
            );
            if (kababMenu) {
                kababMenu?.focus();
            } else {
                document.getElementById('add-vehicle-btn')?.focus();
            }
        }
    };

    const toggleUpdateBtnState = (state: string) => {
        const updateButton = document.querySelector(
            '.modal-footer .fmc-button'
        ) as HTMLButtonElement;
        if (state === 'disabled') {
            updateButton.disabled = true;
            updateButton.classList.add('disabled');
        } else {
            updateButton.disabled = false;
            updateButton.classList.remove('disabled');
        }
    };
    const onCardRemove = async () => {
        const vinStorage = sessionStorage.getItem('isEditId');
        setIsLoading(true);
        fireAnalytics(['removeVehicleOnclickEvent']);
        toggleUpdateBtnState('disabled');
        if (vinStorage) {
            const response = await profileService.deleteVehicle(vinStorage);
            if (response.status == 200 || response.status == '200') {
                if (updateVehiclesData) {
                    updateVehiclesData(false);
                }
                onRemove();
                resetContext();
            }
        }
        setTimeout(() => {
            focusNextElement(false, true);
            setIsLoading(false);
        }, 2500);
    };

    const onUpdate = async () => {
        fireAnalytics('editVehicleOnclickEvent');
        toggleUpdateBtnState('disabled');
        const vinStorage = sessionStorage.getItem('isEditId');

        if (vinStorage) {
            const response = await profileService.updateVehicle(
                vinStorage,
                vehicleNicknameRef.current
            );
            if (response.status === 200 || response.status === '200') {
                updateVehiclesData && updateVehiclesData(false);
            }
        }
        resetContext();
        setTimeout(() => focusNextElement(true, false), 1000);
    };

    const onModalClose = (isEditModal = false) => {
        isEditVehicleModalVisible.current = false;
        resetContext();
        if (isEditModal) {
            vehicleNicknameRef.current = data.nickname || null;
            setVehicleNickname(data.nickname || null);
            setTimeout(() => {
                const editButton = document.getElementById(
                    `edit-vehicle-btn-${identification}`
                );
                editButton?.focus();
            }, 1000);
        } else {
            setTimeout(() => {
                const deleteButton = document.getElementById(
                    `delete-vehicle-btn-${identification}`
                );
                deleteButton?.focus();
            }, 1000);
        }
        setTooltipCaretHighlight(false);
        focusNextElement(false, true);
    };

    const cardTitle =
        data.nickname ||
        `${
            cardContentFragments?.myLabel ? cardContentFragments?.myLabel : ''
        } ${data.title}`;

    const handleNickNameChange = (event) => {
        const nickname = event.target.value
            .replace(/[$&+,:;=?@#|'<>/\\.^*()%!{}-]/g, '')
            .trimStart();
        vehicleNicknameRef.current = nickname;
        setVehicleNickname(nickname);
    };

    const getInput = () => {
        return (
            <InputField
                name={`update-vehicle-nickname-${identification}`}
                id={`edit-vehicle-vin-${identification}`}
                className="user-input"
                maxLength={25}
                value={vehicleNickname}
                label={cardContentFragments?.nicknamefieldlabel}
                showLabel={false}
                key={`edit-vehicle-vin-${identification}`}
                handleChange={handleNickNameChange}
                dataTestId="edit-vehicle-vin"
            />
        );
    };

    const editVehicleModalProps: ModalContextProps = {
        modalType: {
            name: editVehicleModal,
            isVisible: true,
            onPrimaryButtonClick: () => onUpdate(),
            onSecondaryButtonClick: () => {
                fireAnalytics('cancelEditVehicleOnclickEvent');
                onModalClose(true);
            },
            onAfterClose: () => onModalClose(true),
            primaryButtonLabel: cardContentFragments?.btnUpdate,
            primaryButtonAriaLabel: cardContentFragments?.btnUpdateAriaLabel,
            secondaryButtonLabel: cardContentFragments?.btnCancel,
            secondaryButtonAriaLabel:
                cardContentFragments?.updateVehicleBtnCancelAriaLabel,
            closeButtonAriaLabel:
                cardContentFragments?.modalCloseButtonAriaLabel,
            children: (
                <>
                    <h2 className="fmc-type--heading5 fds-color__text--primary">
                        {cardContentFragments?.editvehiclemodalheader}
                    </h2>
                    <div className="user-input-container">
                        {getInput()}
                        <br />
                    </div>
                </>
            ),
        },
    };

    const deleteVehicleModalProps: ModalContextProps = {
        modalType: {
            name: deleteVehicleModal,
            isVisible: true,
            onPrimaryButtonClick: () => onCardRemove(),
            onSecondaryButtonClick: () => onModalClose(),
            onAfterClose: () => onModalClose(),
            primaryButtonLabel: cardContentFragments?.btnConfirm,
            primaryButtonAriaLabel: cardContentFragments?.btnConfirmAriaLabel,
            secondaryButtonLabel: cardContentFragments?.btnCancel,
            secondaryButtonAriaLabel:
                cardContentFragments?.deleteVehicleBtnCancelAriaLabel,
            closeButtonAriaLabel:
                cardContentFragments?.modalCloseButtonAriaLabel,
            children: (
                <>
                    <h2
                        className={`fmc-type--heading5 ${
                            isLincoln
                                ? 'fds-color__text--primary delete-vehicle-modal-header'
                                : ''
                        }`}
                    >
                        {cardContentFragments?.deletevehiclemodalheader}
                    </h2>
                    {isFord && (
                        <p className="fmc-type--body1 delete-vehicle-modal-body1">
                            {cardContentFragments.deleteVehicleModalBody}
                        </p>
                    )}
                </>
            ),
        },
    };
    const handleClickOutsideTooltip = (e) => {
        if (tooltipRef) {
            if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
                closeToolTipHandler();
            }
        }
    };

    const convertMMOTAStatusValue = (
        value: string,
        normalStatusText: string,
        lowStatusText: string
    ): string => {
        if (value && value.includes('GOOD')) {
            return normalStatusText;
        } else if (value) {
            return lowStatusText;
        } else {
            return '-';
        }
    };

    //mapping distance string value with the received number
    const convertDistanceValueToUnit = (number) => {
        if (number === 2) {
            return cardContentFragments?.mmotaOdometerKilometersText;
        }
        if (number === 1) {
            return cardContentFragments?.mmotaOdometerMilesText;
        }
        return '';
    };

    const convertMMOTANumberValue = (value: any, isPercentage: boolean) => {
        if (value === null || value === undefined || value === '') {
            return '-';
        } else if (isPercentage) {
            return Math.round(value) + '%';
        } else {
            return value;
        }
    };

    useEffect(() => {
        if (isEditVehicleModalVisible.current) {
            setContext(editVehicleModalProps);
        }
    }, [vehicleNickname]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideTooltip, true);
        return () => {
            document.removeEventListener(
                'click',
                handleClickOutsideTooltip,
                true
            );
        };
    }, []);

    useEffect(() => {
        localStorage.removeItem(VEHICLE_CARD_STORAGE_KEY);

        const connectedVehicleService = new ConnectedVehicleService(
            httpService
        );
        connectedVehicleService
            .getVehicleStatus(data.description)
            .then((response) => {
                if (response.vehiclestatus) {
                    setIsConnectedVehicle(true);
                    const mmotaData: MmotaData = {
                        tirePressureStatus: convertMMOTAStatusValue(
                            response.vehiclestatus.tirePressure?.value,
                            cardContentFragments.mmotaTirePressureValueNormal,
                            cardContentFragments.mmotaTirePressureValueLow
                        ),
                        oilLifeStatus: convertMMOTAStatusValue(
                            response.vehiclestatus.oil?.oilLife,
                            cardContentFragments.mmotaOilLifeNormalValue,
                            cardContentFragments.mmotaOilLifeLowValue
                        ),
                        oilLifeValue: convertMMOTANumberValue(
                            response.vehiclestatus.oil?.oilLifeActual,
                            true
                        ),
                        fuelLevelValue: convertMMOTANumberValue(
                            response.vehiclestatus.fuel?.fuelLevel,
                            true
                        ),
                        fuelLevelDTE: convertMMOTANumberValue(
                            response.vehiclestatus.fuel?.distanceToEmpty,
                            false
                        ),
                        chargeLevelValue: convertMMOTANumberValue(
                            response.vehiclestatus.batteryFillLevel?.value,
                            true
                        ),
                        electricDTE: convertMMOTANumberValue(
                            response.vehiclestatus.elVehDTE?.value,
                            false
                        ),
                        odometerValue: Number(
                            response.vehiclestatus.odometer?.value
                        ),
                        uomDistanceUnit: convertDistanceValueToUnit(
                            response.vehiclestatus.uomDistance
                        ),
                    };
                    setMmotaData(mmotaData);
                }
            });
    }, []);

    useEffect(() => {
        const service = new PrognosticsService(
            httpService,
            authenticationService
        );

        service
            .getVehiclePrognosticsAlerts(data.description)
            .then((response) => {
                setVehiclePrognosticsAlerts(response?.prognosticsData);
            });
    }, []);

    useEffect(() => {
        const service = new RecallsService(httpService);

        service.getRecallsAlerts(data.description).then((response) => {
            setRecallsNhtsaVehicleAlerts(response?.nhtsa);
            setRecallsVehicleAlerts(response?.recalls);
        });
    }, []);

    const buildRemainingRangeString = (
        remainingRangeValue: string,
        uomDistanceUnit: string
    ) => {
        if (
            remainingRangeValue &&
            remainingRangeValue !== '-' &&
            uomDistanceUnit
        ) {
            return `${remainingRangeValue} ${uomDistanceUnit}`.trim();
        }
        return '-';
    };

    const showVehicleServiceAlerts = vehiclePrognosticsAlerts
        ? vehiclePrognosticsAlerts?.map((prognosticsData) => {
              return prognosticsData?.shouldShow;
          })
        : null;

    const showPrognosticsVehicleAlertsLink = showVehicleServiceAlerts
        ? showVehicleServiceAlerts[0]
        : false;

    const checkNhtsaAndRecalls =
        recallsNhtsaVehicleAlerts?.length > 0 ||
        recallsVehicleAlerts?.length > 0;

    const showVehicleCardAlerts =
        showPrognosticsVehicleAlertsLink || checkNhtsaAndRecalls;

    return (
        <>
            <Card
                data={data}
                identification={identification}
                cardContentFragments={cardContentFragments}
                cardTitle={cardTitle}
                isLoading={isLoading}
                displayCTA={displayCTA}
                ctaLabel={data.ctaLabel}
                onCTAClick={() =>
                    fireAnalytics('viewVehicleDetailsOnclickEvent')
                }
                secondaryBtnLink={data.ctaHref}
                booking={booking}
                odometerValue={mmotaData?.odometerValue}
                uomDistanceUnit={mmotaData?.uomDistanceUnit}
            >
                <>
                    {/* tooltip */}
                    <div
                        className={`block-second ${
                            tooltipCaretHighlight ? 'delete-hover' : ''
                        }`}
                        ref={tooltipRef}
                    >
                        <button
                            className="global-tooltip-hover hamburger-menu"
                            id={`tooltip-menu-${identification}`}
                            data-testid="kebab-menu"
                            aria-label="Vehicle Tooltip Menu"
                            onKeyDown={(e) => {
                                if (e.key === 'Tab') {
                                    onTabNavigation(identification - 1);

                                    if (e.shiftKey) {
                                        closeToolTipHandler();
                                    }
                                }
                            }}
                            onClick={openToolTipHandler}
                            onKeyUp={(e) => {
                                if (
                                    e.key === 'Tab' &&
                                    e.shiftKey &&
                                    !tabbedFromEditButton
                                ) {
                                    openToolTipHandler();
                                }
                            }}
                        >
                            <span className="fds-icon fds-icon--32 fds-font--ford-icons__ellipsis"></span>
                        </button>
                        {showToolTip && (
                            <div
                                className="tooltip-list tooltip-active"
                                role="tooltip"
                                aria-labelledby="iconRightHeader"
                                aria-describedby="iconRightContent"
                                id={editVehicle}
                            >
                                <ul className="tooltip-ul" role="list">
                                    <li role="listitem">
                                        <button
                                            id={`edit-vehicle-btn-${identification}`}
                                            data-testid="edit-vehicle-button"
                                            className="li-link-button"
                                            aria-label={
                                                cardContentFragments?.vehicleCardEditButtonAriaLabel
                                            }
                                            onClick={() => {
                                                closeToolTipHandler();
                                                fireAnalytics(
                                                    'editTooltipOnclickEvent'
                                                );
                                                setTooltipCaretHighlight(false);
                                                sessionStorage.setItem(
                                                    'isEditId',
                                                    data.description
                                                );
                                                isEditVehicleModalVisible.current =
                                                    true;
                                                setContext(
                                                    editVehicleModalProps
                                                );
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Tab') {
                                                    onTabNavigation(
                                                        identification - 1
                                                    );
                                                    if (e.shiftKey) {
                                                        setTabbedFromEditButton(
                                                            true
                                                        );
                                                        closeToolTipHandler();
                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                cardContentFragments?.tooltipedittext
                                            }
                                        </button>
                                    </li>

                                    <li role="listitem">
                                        <button
                                            id={`delete-vehicle-btn-${identification}`}
                                            data-testid="delete-vehicle-button"
                                            className="li-link-button"
                                            aria-label={
                                                cardContentFragments?.vehicleCardDeleteButtonAriaLabel
                                            }
                                            onClick={() => {
                                                closeToolTipHandler();
                                                fireAnalytics(
                                                    'deleteTooltipOnclickEvent'
                                                );
                                                setTooltipCaretHighlight(false);
                                                sessionStorage.setItem(
                                                    'isEditId',
                                                    data.description
                                                );
                                                setContext(
                                                    deleteVehicleModalProps
                                                );
                                            }}
                                            onMouseEnter={() =>
                                                setTooltipCaretHighlight(true)
                                            }
                                            onFocus={() =>
                                                setTooltipCaretHighlight(true)
                                            }
                                            onMouseLeave={() =>
                                                setTooltipCaretHighlight(false)
                                            }
                                            onKeyDown={(e) => {
                                                if (
                                                    e.shiftKey &&
                                                    e.key === 'Tab'
                                                ) {
                                                    setTooltipCaretHighlight(
                                                        false
                                                    );
                                                } else if (e.key === 'Tab') {
                                                    closeToolTipHandler();
                                                }
                                            }}
                                        >
                                            {
                                                cardContentFragments?.tooltipdeletetext
                                            }
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    {isConnectedVehicle && isConnectedVehicleAuthored ? (
                        <>
                            <div
                                className="mmota-container"
                                data-testid="mmota-container"
                            >
                                <div className="mmota-box">
                                    <h5>
                                        {
                                            cardContentFragments.mmotaTirePressureLabelText
                                        }
                                    </h5>
                                    <p data-testid="mmota-box1-value">
                                        {mmotaData?.tirePressureStatus}
                                    </p>
                                </div>
                                <div className="mmota-box">
                                    <h5>
                                        {isBatteryElectricVehicle
                                            ? cardContentFragments.mmotaChargeLevelLabelText
                                            : cardContentFragments.mmotaFuelLevelLabelText}
                                    </h5>
                                    <p data-testid="mmota-box2-value">
                                        {isBatteryElectricVehicle
                                            ? mmotaData?.chargeLevelValue
                                            : buildRemainingRangeString(
                                                  mmotaData?.fuelLevelDTE,
                                                  mmotaData?.uomDistanceUnit
                                              )}
                                    </p>
                                </div>
                                <div className="mmota-box">
                                    <h5>
                                        {isBatteryElectricVehicle
                                            ? cardContentFragments.mmotaRemainingRangeLabelText
                                            : cardContentFragments.mmotaOilLifeLabelText}
                                    </h5>
                                    <p data-testid="mmota-box3-value">
                                        {isBatteryElectricVehicle
                                            ? buildRemainingRangeString(
                                                  mmotaData?.electricDTE,
                                                  mmotaData?.uomDistanceUnit
                                              )
                                            : mmotaData?.oilLifeValue}
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`fmc-cards__menu-links ${
                                    showVehicleCardAlerts ? 'flipped' : ''
                                }`}
                            >
                                {cardContentFragments?.menuLink5LabelText &&
                                cardContentFragments?.menuLink5CtaHref ? (
                                    <div
                                        className="fmc-cards__menu-link"
                                        data-testid="card-menu-link-1"
                                    >
                                        <SecondaryButton
                                            link={
                                                cardContentFragments.menuLink5CtaHref
                                            }
                                            aria-label={
                                                cardContentFragments.menuLink5AriaLabel
                                            }
                                            className="fmc-type--body2 fds-color__text--primary"
                                            isFromCardMenu
                                            onClick={() => {
                                                setLocalStorageWithSelectedVIN(
                                                    data.description
                                                );
                                                setSessionStorageWithSelectedVIN(
                                                    data.description
                                                );
                                                fireAnalytics(
                                                    cardContentFragments.menuLink5AnalyticEventName
                                                );
                                            }}
                                        >
                                            <span>
                                                {
                                                    cardContentFragments.menuLink5LabelText
                                                }
                                            </span>
                                            <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                        </SecondaryButton>
                                    </div>
                                ) : null}
                                {cardContentFragments?.menuLink6LabelText &&
                                cardContentFragments?.menuLink6CtaHref &&
                                !showPrognosticsVehicleAlertsLink ? (
                                    <div
                                        className="fmc-cards__menu-link"
                                        data-testid="card-menu-link-2"
                                    >
                                        <SecondaryButton
                                            link={
                                                cardContentFragments.menuLink6CtaHref
                                            }
                                            aria-label={
                                                cardContentFragments.menuLink6AriaLabel
                                            }
                                            className="fmc-type--body2 fds-color__text--primary"
                                            isFromCardMenu
                                            onClick={() => {
                                                setLocalStorageWithSelectedVIN(
                                                    data.description
                                                );
                                                fireAnalytics(
                                                    cardContentFragments.menuLink6AnalyticEventName
                                                );
                                            }}
                                        >
                                            <span>
                                                {
                                                    cardContentFragments.menuLink6LabelText
                                                }
                                            </span>

                                            <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                        </SecondaryButton>
                                    </div>
                                ) : null}
                                {cardContentFragments?.menuLink7LabelText &&
                                cardContentFragments?.menuLink7CtaHref &&
                                showPrognosticsVehicleAlertsLink ? (
                                    <div
                                        className="fmc-cards__menu-link"
                                        data-testid="card-menu-link-1"
                                    >
                                        <SecondaryButton
                                            link={
                                                cardContentFragments.menuLink7CtaHref
                                            }
                                            aria-label={
                                                cardContentFragments.menuLink7AriaLabel
                                            }
                                            className="fmc-type--body2 fds-color__text--primary"
                                            isFromCardMenu
                                            onClick={() => {
                                                setLocalStorageWithSelectedVIN(
                                                    data.description
                                                );
                                                fireAnalytics(
                                                    cardContentFragments.menuLink7AnalyticEventName
                                                );
                                            }}
                                        >
                                            <span>
                                                {
                                                    cardContentFragments.menuLink7LabelText
                                                }
                                            </span>

                                            <StatusChip
                                                notificationCount={
                                                    vehiclePrognosticsAlerts?.length
                                                }
                                            />

                                            <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                        </SecondaryButton>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    ) : null}
                    {!isConnectedVehicle || !isConnectedVehicleAuthored ? (
                        <div className="fmc-cards__menu-links">
                            {cardContentFragments?.menuLink1LabelText &&
                            cardContentFragments?.menuLink1CtaHref ? (
                                <div
                                    className="fmc-cards__menu-link"
                                    data-testid="card-menu-link-1"
                                >
                                    <SecondaryButton
                                        link={
                                            cardContentFragments.menuLink1CtaHref
                                        }
                                        aria-label={
                                            cardContentFragments.menuLink1AriaLabel
                                        }
                                        className="fmc-type--body2 fds-color__text--primary"
                                        isFromCardMenu
                                        onClick={() => {
                                            setLocalStorageWithSelectedVIN(
                                                data.description
                                            );
                                            fireAnalytics(
                                                cardContentFragments.menuLink1AnalyticEventName
                                            );
                                        }}
                                    >
                                        <span>
                                            {
                                                cardContentFragments.menuLink1LabelText
                                            }
                                        </span>

                                        {checkNhtsaAndRecalls && (
                                            <StatusChip
                                                notificationCount={
                                                    recallsNhtsaVehicleAlerts?.length +
                                                    recallsVehicleAlerts?.length
                                                }
                                            />
                                        )}

                                        <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                    </SecondaryButton>
                                </div>
                            ) : null}
                            {cardContentFragments?.menuLink2LabelText &&
                            cardContentFragments?.menuLink2CtaHref ? (
                                <div
                                    className="fmc-cards__menu-link"
                                    data-testid="card-menu-link-2"
                                >
                                    <SecondaryButton
                                        link={
                                            cardContentFragments.menuLink2CtaHref
                                        }
                                        aria-label={
                                            cardContentFragments.menuLink2AriaLabel
                                        }
                                        className="fmc-type--body2 fds-color__text--primary"
                                        isFromCardMenu
                                        onClick={() => {
                                            setLocalStorageWithSelectedVIN(
                                                data.description
                                            );
                                            fireAnalytics(
                                                cardContentFragments.menuLink2AnalyticEventName
                                            );
                                        }}
                                    >
                                        <span>
                                            {
                                                cardContentFragments.menuLink2LabelText
                                            }
                                        </span>
                                        <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                    </SecondaryButton>
                                </div>
                            ) : null}
                            {cardContentFragments?.menuLink3LabelText &&
                            cardContentFragments?.menuLink3CtaHref ? (
                                <div
                                    className="fmc-cards__menu-link"
                                    data-testid="card-menu-link-3"
                                >
                                    <SecondaryButton
                                        link={
                                            cardContentFragments.menuLink3CtaHref
                                        }
                                        aria-label={
                                            cardContentFragments.menuLink3AriaLabel
                                        }
                                        className="fmc-type--body2 fds-color__text--primary"
                                        isFromCardMenu
                                        onClick={() => {
                                            setLocalStorageWithSelectedVIN(
                                                data.description
                                            );
                                            fireAnalytics(
                                                cardContentFragments.menuLink3AnalyticEventName
                                            );
                                        }}
                                    >
                                        <span>
                                            {
                                                cardContentFragments.menuLink3LabelText
                                            }
                                        </span>
                                        <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                    </SecondaryButton>
                                </div>
                            ) : null}
                            {cardContentFragments?.menuLink4LabelText &&
                            cardContentFragments?.menuLink4CtaHref ? (
                                <div
                                    className="fmc-cards__menu-link"
                                    data-testid="card-menu-link-4"
                                >
                                    <SecondaryButton
                                        link={
                                            cardContentFragments.menuLink4CtaHref
                                        }
                                        aria-label={
                                            cardContentFragments.menuLink4AriaLabel
                                        }
                                        className="fmc-type--body2 fds-color__text--primary"
                                        isFromCardMenu
                                        onClick={() => {
                                            setLocalStorageWithSelectedVIN(
                                                data.description
                                            );
                                            fireAnalytics(
                                                cardContentFragments.menuLink4AnalyticEventName
                                            );
                                        }}
                                    >
                                        <span>
                                            {
                                                cardContentFragments.menuLink4LabelText
                                            }
                                        </span>
                                        <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                    </SecondaryButton>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </>
            </Card>
        </>
    );
};
