import React, { useEffect, useState } from 'react';
import Slide from '@sections/account-portal/components/vehicle-carousel/slides/slide';
import SecondaryButton from '@common/secondary-button/secondary-button';
import {
    POSSIBLE_FUEL_TYPE_VALUES,
    setLocalStorageWithSelectedVIN,
    setSessionStorageWithSelectedVIN,
    VEHICLE_CARD_STORAGE_KEY,
} from '@constants';
import StatusChip from '@sections/account-portal/components/vehicle-carousel/vehicle-card/vha-notification/status-chip';
import { VehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { SwipePoint } from '@sections/account-portal/components/vehicle-carousel/hooks/use-swipe';
import { CustomerBooking } from '@services/gcct-service/gcct-service';
import { MmotaData } from '@sections/account-portal/components/vehicle-carousel/vehicle-card/vehicle-card';
import { Nhtsa, PrognosticsData, Recalls } from '@models/vehicle-health';
import ProfileService from '@services/profile-service/profile-service';
import { useAnalytics } from '@hooks/use-analytics';
import { useModalContext } from '@contexts/modalContext';
import ConnectedVehicleService from '@services/connected-vehicle-service/connected-vehicle-service';
import PrognosticsService from '@services/prognostics-service/prognostics-service';
import RecallsService from '@services/recalls-service/recalls-service';
import HttpService from '@services/http-service/http-service';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { CarouselSlideContent } from '@sections/account-portal/components/vehicle-carousel/slides/hooks/use-carousel-slide-content';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import DeletedVehicleService from '@services/deleted-vehicle-service/deleted-vehicle-service';

export interface VehicleSlideProps {
    data: any;
    updateVehiclesData?: (cachable: boolean) => void;
    identification?: number;
    carouselContentFragment?: VehicleCarouselContent;
    onRemove?: () => void;
    onTouchStart?: (swipePoint: SwipePoint) => void;
    onTouchEnd?: () => void;
    onTabNavigation?: (index: number) => void;
    booking: CustomerBooking;
    slideContent: CarouselSlideContent;
    deliveredVehicles: OrderedVehiclesItem[];
}
const VehicleSlide = ({
    data,
    updateVehiclesData,
    identification,
    carouselContentFragment,
    slideContent,
    onRemove,
    booking,
    deliveredVehicles,
}: VehicleSlideProps) => {
    const [fireAnalytics] = useAnalytics();
    const { resetContext } = useModalContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const profileService = new ProfileService();
    const httpService = HttpService;
    const authenticationService = new AuthenticationService();
    const deletedVehicleService = new DeletedVehicleService(httpService);
    const [isConnectedVehicle, setIsConnectedVehicle] =
        useState<boolean>(false);
    const [vehiclePrognosticsAlerts, setVehiclePrognosticsAlerts] =
        useState<PrognosticsData[]>(null);
    const isConnectedVehicleAuthored =
        carouselContentFragment.mmotaOdometerMilesText &&
        carouselContentFragment.mmotaOdometerKilometersText &&
        carouselContentFragment.mmotaFuelLevelLabelText &&
        carouselContentFragment.mmotaOilLifeLabelText &&
        carouselContentFragment.mmotaOilLifeNormalValue &&
        carouselContentFragment.mmotaOilLifeLowValue &&
        carouselContentFragment.mmotaTirePressureLabelText &&
        carouselContentFragment.mmotaTirePressureValueNormal &&
        carouselContentFragment.mmotaTirePressureValueLow &&
        carouselContentFragment.mmotaChargeLevelLabelText &&
        carouselContentFragment.mmotaRemainingRangeLabelText;
    const [mmotaData, setMmotaData] = useState<MmotaData>(null);
    const isBatteryElectricVehicle =
        data?.fuelType === 'E' || data?.fuelType?.toLowerCase() === 'electric';
    const isUnknownFuelType = !POSSIBLE_FUEL_TYPE_VALUES.includes(
        data?.fuelType
    );
    const buildRemainingRangeString = (
        remainingRangeValue: string,
        uomDistanceUnit: string
    ) => {
        if (
            remainingRangeValue &&
            remainingRangeValue !== '-' &&
            uomDistanceUnit
        ) {
            return `${remainingRangeValue} ${uomDistanceUnit}`.trim();
        }
        return '-';
    };
    const [recallsNhtsaVehicleAlerts, setRecallsNhtsaVehicleAlerts] =
        useState<Nhtsa[]>(null);

    const [recallsVehicleAlerts, setRecallsVehicleAlerts] =
        useState<Recalls[]>(null);
    const checkNhtsaAndRecalls =
        recallsNhtsaVehicleAlerts?.length > 0 ||
        recallsVehicleAlerts?.length > 0;
    const showVehicleServiceAlerts = vehiclePrognosticsAlerts
        ? vehiclePrognosticsAlerts?.map((prognosticsData) => {
              return prognosticsData?.shouldShow;
          })
        : null;
    const showPrognosticsVehicleAlertsLink = showVehicleServiceAlerts
        ? showVehicleServiceAlerts[0]
        : false;
    const showVehicleCardAlerts =
        showPrognosticsVehicleAlertsLink || checkNhtsaAndRecalls;
    const onCardRemove = async () => {
        const vinStorage = sessionStorage.getItem('isEditId');
        setIsLoading(true);
        fireAnalytics(['removeVehicleOnclickEvent']);
        if (vinStorage) {
            if (
                deliveredVehicles?.some((vehicle) => vehicle.vin === vinStorage)
            ) {
                await deletedVehicleService.addDeletedVehicleToDB(
                    vinStorage,
                    'Delivered'
                );
            }

            const response = await profileService.deleteVehicle(vinStorage);
            if (response.status == 200 || response.status == '200') {
                if (updateVehiclesData) {
                    updateVehiclesData(false);
                }
                onRemove();
                resetContext();
            }
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 2500);
    };
    const onModalClose = () => {
        resetContext();

        setTimeout(() => {
            const deleteButton = document.getElementById(
                `delete-vehicle-btn-${identification}`
            );
            deleteButton?.focus();
        }, 1000);
    };
    const convertMMOTAStatusValue = (
        value: string,
        normalStatusText: string,
        lowStatusText: string
    ): string => {
        if (value && value.includes('GOOD')) {
            return normalStatusText;
        } else if (value) {
            return lowStatusText;
        } else {
            return '-';
        }
    };
    //mapping distance string value with the received number
    const convertDistanceValueToUnit = (number) => {
        if (number === 2) {
            return carouselContentFragment?.mmotaOdometerKilometersText;
        }
        if (number === 1) {
            return carouselContentFragment?.mmotaOdometerMilesText;
        }
        return '';
    };

    const convertMMOTANumberValue = (value: any, isPercentage: boolean) => {
        if (value === null || value === undefined || value === '') {
            return '-';
        } else if (isPercentage) {
            return Math.round(value) + '%';
        } else {
            return value;
        }
    };
    useEffect(() => {
        localStorage.removeItem(VEHICLE_CARD_STORAGE_KEY);

        const connectedVehicleService = new ConnectedVehicleService(
            httpService
        );
        connectedVehicleService
            .getVehicleStatus(data?.description)
            .then((response) => {
                if (response.vehiclestatus) {
                    setIsConnectedVehicle(true);
                    const mmotaData: MmotaData = {
                        tirePressureStatus: convertMMOTAStatusValue(
                            response.vehiclestatus.tirePressure?.value,
                            carouselContentFragment.mmotaTirePressureValueNormal,
                            carouselContentFragment.mmotaTirePressureValueLow
                        ),
                        oilLifeStatus: convertMMOTAStatusValue(
                            response.vehiclestatus.oil?.oilLife,
                            carouselContentFragment.mmotaOilLifeNormalValue,
                            carouselContentFragment.mmotaOilLifeLowValue
                        ),
                        oilLifeValue: convertMMOTANumberValue(
                            response.vehiclestatus.oil?.oilLifeActual,
                            true
                        ),
                        fuelLevelValue: convertMMOTANumberValue(
                            response.vehiclestatus.fuel?.fuelLevel,
                            true
                        ),
                        fuelLevelDTE: convertMMOTANumberValue(
                            response.vehiclestatus.fuel?.distanceToEmpty,
                            false
                        ),
                        chargeLevelValue: convertMMOTANumberValue(
                            response.vehiclestatus.batteryFillLevel?.value,
                            true
                        ),
                        electricDTE: convertMMOTANumberValue(
                            response.vehiclestatus.elVehDTE?.value,
                            false
                        ),
                        odometerValue: Number(
                            response.vehiclestatus.odometer?.value
                        ),
                        uomDistanceUnit: convertDistanceValueToUnit(
                            response.vehiclestatus.uomDistance
                        ),
                    };
                    setMmotaData(mmotaData);
                }
            });
    }, []);

    useEffect(() => {
        const service = new PrognosticsService(
            httpService,
            authenticationService
        );

        service
            .getVehiclePrognosticsAlerts(data?.description)
            .then((response) => {
                setVehiclePrognosticsAlerts(response?.prognosticsData);
            });
    }, []);

    useEffect(() => {
        const service = new RecallsService(httpService);

        service.getRecallsAlerts(data?.description).then((response) => {
            setRecallsNhtsaVehicleAlerts(response?.nhtsa);
            setRecallsVehicleAlerts(response?.recalls);
        });
    }, []);
    return (
        <>
            {slideContent && (
                <Slide
                    data={data}
                    carouselContentFragment={carouselContentFragment}
                    isOrderCard={false}
                    slideContent={slideContent}
                    secondaryButtonLink={data.ctaHref}
                    isLoading={isLoading}
                    isConnectedVehicle={isConnectedVehicle}
                    onCardRemove={onCardRemove}
                    onModalClose={onModalClose}
                    booking={booking}
                >
                    {isConnectedVehicle &&
                    isConnectedVehicleAuthored &&
                    !isUnknownFuelType ? (
                        <>
                            <div
                                className="mmota-container"
                                data-testid="mmota-container"
                            >
                                <div className="mmota-box">
                                    <h5>
                                        {
                                            carouselContentFragment.mmotaTirePressureLabelText
                                        }
                                    </h5>
                                    <p data-testid="mmota-box1-value">
                                        {mmotaData?.tirePressureStatus}
                                    </p>
                                </div>
                                <div className="mmota-box">
                                    <h5>
                                        {isBatteryElectricVehicle
                                            ? carouselContentFragment.mmotaChargeLevelLabelText
                                            : carouselContentFragment.mmotaFuelLevelLabelText}
                                    </h5>
                                    <p data-testid="mmota-box2-value">
                                        {isBatteryElectricVehicle
                                            ? mmotaData?.chargeLevelValue
                                            : buildRemainingRangeString(
                                                  mmotaData?.fuelLevelDTE,
                                                  mmotaData?.uomDistanceUnit
                                              )}
                                    </p>
                                </div>
                                <div className="mmota-box">
                                    <h5>
                                        {isBatteryElectricVehicle
                                            ? carouselContentFragment.mmotaRemainingRangeLabelText
                                            : carouselContentFragment.mmotaOilLifeLabelText}
                                    </h5>
                                    <p data-testid="mmota-box3-value">
                                        {isBatteryElectricVehicle
                                            ? buildRemainingRangeString(
                                                  mmotaData?.electricDTE,
                                                  mmotaData?.uomDistanceUnit
                                              )
                                            : mmotaData?.oilLifeValue}
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`fmc-cards__menu-links ${
                                    showVehicleCardAlerts ? 'flipped' : ''
                                }`}
                            >
                                {carouselContentFragment?.menuLink5LabelText &&
                                carouselContentFragment?.menuLink5CtaHref ? (
                                    <div
                                        className="fmc-cards__menu-link"
                                        data-testid="card-menu-link-1"
                                    >
                                        <SecondaryButton
                                            link={
                                                carouselContentFragment.menuLink5CtaHref
                                            }
                                            aria-label={
                                                carouselContentFragment.menuLink5AriaLabel
                                            }
                                            className="fmc-type--body2"
                                            isFromCardMenu
                                            onClick={() => {
                                                setLocalStorageWithSelectedVIN(
                                                    data.description
                                                );
                                                setSessionStorageWithSelectedVIN(
                                                    data.description
                                                );

                                                fireAnalytics(
                                                    carouselContentFragment.menuLink5AnalyticEventName
                                                );
                                            }}
                                        >
                                            <span>
                                                {
                                                    carouselContentFragment.menuLink5LabelText
                                                }
                                            </span>
                                            <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                        </SecondaryButton>
                                    </div>
                                ) : null}
                                {carouselContentFragment?.menuLink6LabelText &&
                                carouselContentFragment?.menuLink6CtaHref &&
                                !showPrognosticsVehicleAlertsLink ? (
                                    <div
                                        className="fmc-cards__menu-link"
                                        data-testid="card-menu-link-2"
                                    >
                                        <SecondaryButton
                                            link={
                                                carouselContentFragment.menuLink6CtaHref
                                            }
                                            aria-label={
                                                carouselContentFragment.menuLink6AriaLabel
                                            }
                                            className="fmc-type--body2"
                                            isFromCardMenu
                                            onClick={() => {
                                                setLocalStorageWithSelectedVIN(
                                                    data.description
                                                );
                                                fireAnalytics(
                                                    carouselContentFragment.menuLink6AnalyticEventName
                                                );
                                            }}
                                        >
                                            <span>
                                                {
                                                    carouselContentFragment.menuLink6LabelText
                                                }
                                            </span>

                                            <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                        </SecondaryButton>
                                    </div>
                                ) : null}
                                {carouselContentFragment?.menuLink7LabelText &&
                                carouselContentFragment?.menuLink7CtaHref &&
                                showPrognosticsVehicleAlertsLink ? (
                                    <div
                                        className="fmc-cards__menu-link show-prognostics-alerts"
                                        data-testid="card-menu-link-1"
                                    >
                                        <SecondaryButton
                                            link={
                                                carouselContentFragment.menuLink7CtaHref
                                            }
                                            aria-label={
                                                carouselContentFragment.menuLink7AriaLabel
                                            }
                                            className="fmc-type--body2"
                                            isFromCardMenu
                                            onClick={() => {
                                                setLocalStorageWithSelectedVIN(
                                                    data.description
                                                );
                                                fireAnalytics(
                                                    carouselContentFragment.menuLink7AnalyticEventName
                                                );
                                            }}
                                        >
                                            <span>
                                                {
                                                    carouselContentFragment.menuLink7LabelText
                                                }
                                            </span>

                                            <StatusChip
                                                notificationCount={
                                                    vehiclePrognosticsAlerts?.length
                                                }
                                                servicesNeededText={
                                                    slideContent.vehicleHealthServicesNeededText
                                                }
                                            />

                                            <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                        </SecondaryButton>
                                    </div>
                                ) : null}
                                {carouselContentFragment?.menuLink8LabelText &&
                                carouselContentFragment?.menuLink8CtaHref ? (
                                    <div
                                        className="fmc-cards__menu-link"
                                        data-testid="card-menu-link-1"
                                    >
                                        <SecondaryButton
                                            link={
                                                carouselContentFragment.menuLink8CtaHref
                                            }
                                            aria-label={
                                                carouselContentFragment.menuLink8AriaLabel
                                            }
                                            className="fmc-type--body2"
                                            isFromCardMenu
                                            onClick={() => {
                                                setLocalStorageWithSelectedVIN(
                                                    data.description
                                                );
                                                fireAnalytics(
                                                    carouselContentFragment.menuLink8AnalyticEventName
                                                );
                                            }}
                                        >
                                            <span>
                                                {
                                                    carouselContentFragment.menuLink8LabelText
                                                }
                                            </span>
                                            <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                        </SecondaryButton>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    ) : null}
                    {!isConnectedVehicle || !isConnectedVehicleAuthored ? (
                        <div className="fmc-cards__menu-links">
                            {carouselContentFragment?.menuLink1LabelText &&
                            carouselContentFragment?.menuLink1CtaHref ? (
                                <div
                                    className="fmc-cards__menu-link"
                                    data-testid="card-menu-link-1"
                                >
                                    <SecondaryButton
                                        link={
                                            carouselContentFragment.menuLink1CtaHref
                                        }
                                        aria-label={
                                            carouselContentFragment.menuLink1AriaLabel
                                        }
                                        className="fmc-type--body2"
                                        isFromCardMenu
                                        onClick={() => {
                                            setLocalStorageWithSelectedVIN(
                                                data.description
                                            );
                                            fireAnalytics(
                                                carouselContentFragment.menuLink1AnalyticEventName
                                            );
                                        }}
                                    >
                                        <span>
                                            {
                                                carouselContentFragment.menuLink1LabelText
                                            }
                                        </span>

                                        {checkNhtsaAndRecalls && (
                                            <StatusChip
                                                notificationCount={
                                                    recallsNhtsaVehicleAlerts?.length +
                                                    recallsVehicleAlerts?.length
                                                }
                                            />
                                        )}

                                        <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                    </SecondaryButton>
                                </div>
                            ) : null}
                            {carouselContentFragment?.menuLink2LabelText &&
                            carouselContentFragment?.menuLink2CtaHref ? (
                                <div
                                    className="fmc-cards__menu-link"
                                    data-testid="card-menu-link-2"
                                >
                                    <SecondaryButton
                                        link={
                                            carouselContentFragment.menuLink2CtaHref
                                        }
                                        aria-label={
                                            carouselContentFragment.menuLink2AriaLabel
                                        }
                                        className="fmc-type--body2"
                                        isFromCardMenu
                                        onClick={() => {
                                            setLocalStorageWithSelectedVIN(
                                                data.description
                                            );
                                            fireAnalytics(
                                                carouselContentFragment.menuLink2AnalyticEventName
                                            );
                                        }}
                                    >
                                        <span>
                                            {
                                                carouselContentFragment.menuLink2LabelText
                                            }
                                        </span>
                                        <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                    </SecondaryButton>
                                </div>
                            ) : null}
                            {carouselContentFragment?.menuLink3LabelText &&
                            carouselContentFragment?.menuLink3CtaHref ? (
                                <div
                                    className="fmc-cards__menu-link"
                                    data-testid="card-menu-link-3"
                                >
                                    <SecondaryButton
                                        link={
                                            carouselContentFragment.menuLink3CtaHref
                                        }
                                        aria-label={
                                            carouselContentFragment.menuLink3AriaLabel
                                        }
                                        className="fmc-type--body2"
                                        isFromCardMenu
                                        onClick={() => {
                                            setLocalStorageWithSelectedVIN(
                                                data.description
                                            );
                                            fireAnalytics(
                                                carouselContentFragment.menuLink3AnalyticEventName
                                            );
                                        }}
                                    >
                                        <span>
                                            {
                                                carouselContentFragment.menuLink3LabelText
                                            }
                                        </span>
                                        <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                    </SecondaryButton>
                                </div>
                            ) : null}
                            {carouselContentFragment?.menuLink4LabelText &&
                            carouselContentFragment?.menuLink4CtaHref ? (
                                <div
                                    className="fmc-cards__menu-link"
                                    data-testid="card-menu-link-4"
                                >
                                    <SecondaryButton
                                        link={
                                            carouselContentFragment.menuLink4CtaHref
                                        }
                                        aria-label={
                                            carouselContentFragment.menuLink4AriaLabel
                                        }
                                        className="fmc-type--body2"
                                        isFromCardMenu
                                        onClick={() => {
                                            setLocalStorageWithSelectedVIN(
                                                data.description
                                            );
                                            fireAnalytics(
                                                carouselContentFragment.menuLink4AnalyticEventName
                                            );
                                        }}
                                    >
                                        <span>
                                            {
                                                carouselContentFragment.menuLink4LabelText
                                            }
                                        </span>
                                        <span className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-right"></span>
                                    </SecondaryButton>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </Slide>
            )}
        </>
    );
};

export default VehicleSlide;
