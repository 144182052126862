import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';
import { KEYBOARD_KEYS } from '@constants';
import { ExperienceFragmentModel } from '@services/content-service/content-service.interface';
import { Errors } from '@sections/personal-information/personal-information';
import { AccountSettingsFormsLabels } from '@sections/personal-information/hooks/use-personal-information-content';
import './dynamic-dropdown.scss';

export interface DynamicDropdownContent extends ExperienceFragmentModel {
    dropdownOptions: DynamicDropdownOptions[];
    ':type': string;
}

export interface DynamicDropdownOptions {
    countryDisplayName: string;
    countryRegex: string;
    countryIconPath: string;
    phoneNumberCountryCode: string;
}

export interface DynamicDropdownProps {
    dynamicDropdownContent: DynamicDropdownContent;
    focus: any;
    error: Errors;
    setFocusToField: (string, boolean) => void;
    accountSettingsFormsLabels: AccountSettingsFormsLabels;
    field: string;
    selectedCountry: DynamicDropdownOptions;
    setSelectedCountry: Dispatch<SetStateAction<DynamicDropdownOptions>>;
}

export const DynamicDropdown = (props: DynamicDropdownProps) => {
    const comboboxRef = useRef(null);
    const [value, setValue] = useState(
        props.selectedCountry?.countryDisplayName || ''
    );
    const [dropdownIsOpen, setIsDropdownOpen] = useState<boolean>(
        props.focus.countryCode
    );
    const filteredCountries =
        value === ''
            ? props.dynamicDropdownContent?.dropdownOptions
            : props.dynamicDropdownContent?.dropdownOptions?.filter((country) =>
                  country.countryDisplayName
                      .toLowerCase()
                      .includes(value.toLowerCase())
              );

    const clearFieldAndResetErrors = () => {
        props.setSelectedCountry(null);
        setValue('');
        props.error[props.field].status = false;
    };

    const chooseCountry = (country) => {
        setValue(country.countryDisplayName);
        props.setSelectedCountry(country);
        setIsDropdownOpen(false);
        props.setFocusToField('countryCode', true);
    };

    const handleClickOutsideCombobox = (e) => {
        if (comboboxRef?.current && !comboboxRef.current?.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    };

    const keydownHandler = (e) => {
        const focusableElements = comboboxRef?.current?.querySelectorAll(
            'input, [role="option"]'
        );
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement =
            focusableElements[focusableElements.length - 1];

        if (e.shiftKey && e.key === KEYBOARD_KEYS.TAB) {
            if (document?.activeElement === firstFocusableElement) {
                setIsDropdownOpen(false);
            }
        } else if (e.key === KEYBOARD_KEYS.TAB) {
            if (document?.activeElement === lastFocusableElement) {
                setIsDropdownOpen(false);
            }
        }
    };

    useEffect(() => {
        comboboxRef?.current?.addEventListener('keydown', keydownHandler, true);

        return () => {
            comboboxRef?.current?.removeEventListener(
                'keydown',
                keydownHandler,
                true
            );
        };
    }, []);

    useEffect(() => {
        document?.addEventListener('click', handleClickOutsideCombobox, true);

        return () => {
            document?.removeEventListener(
                'click',
                handleClickOutsideCombobox,
                true
            );
        };
    }, []);

    return (
        <div
            ref={comboboxRef}
            className="dynamic-dropdown-container"
            data-testid="dynamic-dropdown-container"
        >
            <label
                className="dynamic-dropdown-field-label"
                htmlFor={`country-code-${props.field}`}
                data-testid="dynamic-dropdown-field-label"
            >
                {props.accountSettingsFormsLabels.countryCodeFieldLabel || ''}
            </label>

            <div className="dynamic-dropdown-input-group">
                <input
                    id={`country-code-${props.field}`}
                    className={`fmc-input ${
                        !dropdownIsOpen ? 'invisible-text' : ''
                    }`}
                    type="text"
                    placeholder={
                        !props.selectedCountry
                            ? props.accountSettingsFormsLabels
                                  .countryCodeInputPlaceholder
                            : ''
                    }
                    data-testid="dynamic-dropdown-input"
                    list=""
                    name="country-list"
                    role="combobox"
                    aria-autocomplete="list"
                    aria-expanded={dropdownIsOpen ? 'true' : 'false'}
                    aria-controls="country-list"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onFocus={() => {
                        props.setFocusToField('countryCode', true);
                        setIsDropdownOpen(true);
                    }}
                    onKeyDown={(e) => {
                        if (e.shiftKey && e.key === KEYBOARD_KEYS.TAB) {
                            setIsDropdownOpen(false);
                        }
                    }}
                />
                {value && dropdownIsOpen && (
                    <button
                        type="button"
                        tabIndex={0}
                        className="clear-button"
                        data-testid="clear-button"
                        aria-label="Clear text"
                        onClick={clearFieldAndResetErrors}
                        onKeyDown={(e) =>
                            e.key === KEYBOARD_KEYS.ENTER &&
                            clearFieldAndResetErrors()
                        }
                    >
                        <span
                            className="fds-icon fds-icon--16 fds-font--ford-icons__clear"
                            aria-hidden={true}
                        ></span>
                    </button>
                )}
                {props.selectedCountry && (
                    <button
                        className={`menu-toggle ${
                            dropdownIsOpen ? 'open' : ''
                        }`}
                        type="button"
                        aria-label="Toggle Menu"
                        tabIndex={-1}
                        onClick={() => setIsDropdownOpen(!dropdownIsOpen)}
                        onKeyDown={(e) => {
                            if (
                                e.key === KEYBOARD_KEYS.ENTER ||
                                e.key === ' '
                            ) {
                                setIsDropdownOpen(!dropdownIsOpen);
                            }
                        }}
                    >
                        <span
                            className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-down"
                            aria-hidden={true}
                        ></span>
                        <span aria-hidden={true}>_</span>
                    </button>
                )}

                {!dropdownIsOpen && props.selectedCountry ? (
                    <div
                        tabIndex={-1}
                        className="selected-country"
                        data-testid="selected-country"
                        onClick={() => setIsDropdownOpen(true)}
                    >
                        <img
                            src={
                                process.env.REACT_APP_AEM_BASE_URL +
                                props.selectedCountry.countryIconPath
                            }
                            alt=""
                            className="flag-icon"
                        />
                        <p className="selected-country-text">
                            {props.selectedCountry.countryDisplayName}
                        </p>
                    </div>
                ) : null}
            </div>

            {dropdownIsOpen && value !== null && (
                <div className="dynamic-dropdown-results">
                    <ul
                        className="country-list"
                        id="country-list"
                        role="listbox"
                        aria-label="List of Countries"
                    >
                        {filteredCountries.map((country, index) => {
                            return (
                                <li
                                    key={index}
                                    tabIndex={0}
                                    role="option"
                                    className="country-option"
                                    data-testid="country-option"
                                    onClick={() => {
                                        chooseCountry(country);
                                    }}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === KEYBOARD_KEYS.ENTER ||
                                            e.key === ' '
                                        ) {
                                            chooseCountry(country);
                                        }
                                    }}
                                >
                                    <img
                                        src={
                                            process.env.REACT_APP_AEM_BASE_URL +
                                            country.countryIconPath
                                        }
                                        alt=""
                                        className="flag-icon"
                                    />
                                    {country.countryDisplayName}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};
