import React, { Dispatch, SetStateAction, useState } from 'react';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { CacheService } from '@services/cache-service/cache-service';
import { SecurityContent } from '@sections/sign-in-and-security/hook/use-security-content';
import MFAService, { MFAOptions } from '@services/mfa-service/mfa-service';
import { LinkButton } from '@common/modal/link-button/link-button';
import './sign-in-and-security.scss';
import SignInAndSecurityErrorContent from '@sections/sign-in-and-security/sign-in-and-security-error-content';
import { Location } from 'history';
import { useAnalytics } from '@/hooks/use-analytics';

interface SignInAndSecurityProps {
    userId: string;
    mfaOptions: MFAOptions[];
    mfaService: MFAService;
    securityContent: SecurityContent;
    fromWebview: boolean;
    setMFAOptions: Dispatch<SetStateAction<MFAOptions[]>>;
    showErrorPage: boolean;
    setShowErrorPage: Dispatch<SetStateAction<boolean>>;
    location: Location;
}

const SignInAndSecurity = (props: SignInAndSecurityProps) => {
    const authenticationService = new AuthenticationService();
    const cacheService = new CacheService();
    const {
        securityContent,
        mfaOptions,
        userId,
        fromWebview,
        mfaService,
        setMFAOptions,
        showErrorPage,
        setShowErrorPage,
        location,
    } = props;
    const [fireAnalytics] = useAnalytics();
    const userIdTypeIsEmail: boolean = userId.includes('@');
    const mfaEmail: MFAOptions = mfaOptions.find(
        (option) => option.type === 'email'
    );
    const mfaApp: MFAOptions = mfaOptions.find(
        (option) => option.type === 'totp'
    );
    const mfaSms: MFAOptions = mfaOptions.find(
        (option) => option.type === 'phone'
    );
    const hasEmailAuthentication = !!mfaEmail;
    const hasAppAuthentication = !!mfaApp;
    const hasSmsAuthentication = !!mfaSms;
    const userHasOnlyOneMFAOption: boolean = mfaOptions.length === 1;
    const [remainingMFAOptions, setRemainingMFAOptions] =
        useState<MFAOptions[]>(mfaOptions);

    const maskedEmailAddress = (userId): string => {
        const userIdSplit: Array<string> = userId.split('@');
        const prefix: string = userIdSplit[0];
        const firstChar: string = prefix[0];
        const lastChar: string = prefix[prefix.length - 1];
        const domain: string = userIdSplit[1];

        return `${firstChar}***${lastChar}@${domain}`;
    };

    const maskedPhoneNumber = (phoneNumber: string): string => {
        const lastFour = phoneNumber.substring(phoneNumber.length - 4);
        return `******${lastFour}`;
    };

    const maskedUserId = (userId): string => {
        if (userIdTypeIsEmail) return maskedEmailAddress(userId);
        return maskedPhoneNumber(userId);
    };

    const deleteMFAOption = async (optionType: string) => {
        if (userHasOnlyOneMFAOption) {
            setShowErrorPage(true);
            return;
        }

        const response = await mfaService.deleteMFAOption(optionType);

        setRemainingMFAOptions(
            mfaOptions.filter((option) => option.type !== optionType)
        );

        if (response.status === 204) {
            mfaService.getMFAOptions().then((mfaServiceResponse) => {
                if (remainingMFAOptions) {
                    setMFAOptions(
                        remainingMFAOptions.filter(
                            (option) => option.type !== optionType
                        )
                    );
                } else {
                    setMFAOptions(
                        mfaOptions.filter(
                            (option) => option.type !== optionType
                        )
                    );
                }
            });
        }
    };

    const configureWebviewLogout = () => {
        if (fromWebview) {
            authenticationService.updateStateWithDeepLink();
        }
    };

    return userId ? (
        <>
            <img
                alt=""
                className={`brand-logo ${fromWebview ? 'from-webview' : ''}`}
                src={
                    process.env.REACT_APP_AEM_BASE_URL +
                    securityContent.brandLogo
                }
            />

            <div className="sign-in-credentials__content">
                {!showErrorPage ? (
                    <>
                        <h1 className="sign-in-credentials__section-header">
                            {securityContent.signInCredentialsSectionHeader}
                        </h1>
                        <div className="sign-in-credentials__field-row">
                            <div className="sign-in-credentials__field-label">
                                <p className="sign-in-credentials__label">
                                    {securityContent.emailSectionLabel}
                                </p>
                                <p className="sign-in-credentials__value">
                                    {maskedUserId(userId)}{' '}
                                </p>
                            </div>

                            <LinkButton
                                label={securityContent.editButtonText}
                                ariaLabel={`${securityContent.editButtonText} ${securityContent.emailSectionLabel}`}
                                dataTestId="email-edit-button"
                                class="edit-link"
                                onClick={() => {
                                    fireAnalytics(
                                        'signInCredentialsEditOnclickEvent'
                                    );
                                    cacheService.evictProfileServiceCache();
                                    configureWebviewLogout();
                                    authenticationService.changeUsername();
                                }}
                                id="email-edit-button"
                                ariaHidden={false}
                                tabIndex={0}
                            />
                        </div>
                        <hr />
                        <div className="sign-in-credentials__field-row">
                            <div className="sign-in-credentials__field-label">
                                <p className="sign-in-credentials__label">
                                    {securityContent.passwordSectionLabel}
                                </p>
                                <p className="sign-in-credentials__value">
                                    ********
                                </p>
                            </div>

                            <LinkButton
                                label={securityContent.editButtonText}
                                ariaLabel={`${securityContent.editButtonText} ${securityContent.passwordSectionLabel}`}
                                dataTestId="password-edit-button"
                                class="edit-link"
                                onClick={() => {
                                    fireAnalytics(
                                        'signInCredentialsEditOnclickEvent'
                                    );
                                    cacheService.evictProfileServiceCache();
                                    configureWebviewLogout();
                                    authenticationService.changePassword();
                                }}
                                id="password-edit-button"
                                ariaHidden={false}
                                tabIndex={0}
                            />
                        </div>
                        <hr />
                        {securityContent.MFASectionHeader &&
                        mfaOptions.length ? (
                            <div
                                className="mfa-section"
                                data-testid="mfa-section"
                            >
                                <h1 className="sign-in-credentials__section-header">
                                    {securityContent.MFASectionHeader}
                                </h1>
                                <p className="sign-in-credentials__mfa--description">
                                    {securityContent.MFADescription}
                                </p>
                                <div className="sign-in-credentials__field-row">
                                    <div className="sign-in-credentials__field-label">
                                        <p className="sign-in-credentials__label">
                                            {
                                                securityContent.MFAEmailAuthenticationSectionLabel
                                            }
                                        </p>
                                        <p className="sign-in-credentials__value">
                                            {hasEmailAuthentication && (
                                                <span>
                                                    {maskedUserId(
                                                        mfaEmail.value
                                                    )}{' '}
                                                </span>
                                            )}
                                            {!hasEmailAuthentication && (
                                                <span>
                                                    {
                                                        securityContent.MFAEmailAuthenticationDescriptionText
                                                    }
                                                </span>
                                            )}
                                        </p>
                                    </div>

                                    <div className="cta-button-box">
                                        {!hasEmailAuthentication && (
                                            <LinkButton
                                                label={
                                                    securityContent.addButtonText
                                                }
                                                ariaLabel={`${securityContent.addButtonText} ${securityContent.MFAEmailAuthenticationSectionLabel}`}
                                                dataTestId="email-authentication-add-button"
                                                class="edit-link"
                                                onClick={() => {
                                                    fireAnalytics(
                                                        'signInCredentialsAddOnclickEvent'
                                                    );
                                                    cacheService.evictProfileServiceCache();
                                                    authenticationService.addMFAEmailAuthentication();
                                                }}
                                                id="email-authentication-add-button"
                                                ariaHidden={false}
                                                tabIndex={0}
                                            />
                                        )}
                                        {hasEmailAuthentication && (
                                            <>
                                                <LinkButton
                                                    label={
                                                        securityContent.editButtonText
                                                    }
                                                    ariaLabel={`${securityContent.editButtonText} ${securityContent.MFAEmailAuthenticationSectionLabel}`}
                                                    dataTestId="email-authentication-edit-button"
                                                    class="edit-link"
                                                    onClick={() => {
                                                        fireAnalytics(
                                                            'signInCredentialsEditOnclickEvent'
                                                        );
                                                        cacheService.evictProfileServiceCache();

                                                        if (userIdTypeIsEmail) {
                                                            authenticationService.changeUsername();
                                                        } else {
                                                            authenticationService.changeMFAEmailAuthentication();
                                                        }
                                                    }}
                                                    id="email-authentication-edit-button"
                                                    ariaHidden={false}
                                                    tabIndex={0}
                                                />

                                                <LinkButton
                                                    label={
                                                        securityContent.removeButtonText
                                                    }
                                                    ariaLabel={`${securityContent.removeButtonText} ${securityContent.MFAEmailAuthenticationSectionLabel}`}
                                                    dataTestId="email-authentication-remove-button"
                                                    class="edit-link"
                                                    onClick={() => {
                                                        fireAnalytics(
                                                            'signInCredentialsRemoveOnclickEvent'
                                                        );
                                                        deleteMFAOption(
                                                            mfaEmail.type
                                                        );
                                                    }}
                                                    id="email-authentication-remove-button"
                                                    ariaHidden={false}
                                                    tabIndex={0}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>

                                <hr />
                                <div className="sign-in-credentials__field-row">
                                    <div className="sign-in-credentials__field-label">
                                        <p className="sign-in-credentials__label">
                                            {
                                                securityContent.MFAAppAuthenticationSectionLabel
                                            }
                                        </p>
                                        <p className="sign-in-credentials__value">
                                            {
                                                securityContent.MFAAppAuthenticationDescriptionText
                                            }
                                        </p>
                                    </div>
                                    {!hasAppAuthentication && (
                                        <LinkButton
                                            label={
                                                securityContent.addButtonText
                                            }
                                            ariaLabel={`${securityContent.addButtonText} ${securityContent.MFAAppAuthenticationSectionLabel}`}
                                            dataTestId="app-authentication-add-button"
                                            class="edit-link"
                                            onClick={() => {
                                                fireAnalytics(
                                                    'signInCredentialsAddOnclickEvent'
                                                );
                                                cacheService.evictProfileServiceCache();
                                                authenticationService.addMFAAppAuthentication();
                                            }}
                                            id="app-authentication-add-button"
                                            ariaHidden={false}
                                            tabIndex={0}
                                        />
                                    )}

                                    {hasAppAuthentication && (
                                        <div className="cta-button-box">
                                            <LinkButton
                                                label={
                                                    securityContent.editButtonText
                                                }
                                                ariaLabel={`${securityContent.editButtonText} ${securityContent.MFAAppAuthenticationSectionLabel}`}
                                                dataTestId="app-authentication-edit-button"
                                                class="edit-link"
                                                onClick={() => {
                                                    fireAnalytics(
                                                        'signInCredentialsEditOnclickEvent'
                                                    );
                                                    cacheService.evictProfileServiceCache();
                                                    authenticationService.changeMFAAppAuthentication();
                                                }}
                                                id="app-authentication-edit-button"
                                                ariaHidden={false}
                                                tabIndex={0}
                                            />
                                            <LinkButton
                                                label={
                                                    securityContent.removeButtonText
                                                }
                                                ariaLabel={`${securityContent.removeButtonText} ${securityContent.MFAAppAuthenticationSectionLabel}`}
                                                dataTestId="app-authentication-remove-button"
                                                class="edit-link"
                                                onClick={() => {
                                                    fireAnalytics(
                                                        'signInCredentialsRemoveOnclickEvent'
                                                    );
                                                    deleteMFAOption(
                                                        mfaApp.type
                                                    );
                                                }}
                                                id="app-authentication-remove-button"
                                                ariaHidden={false}
                                                tabIndex={0}
                                            />
                                        </div>
                                    )}
                                </div>
                                <hr />
                                <div className="sign-in-credentials__field-row">
                                    <div className="sign-in-credentials__field-label">
                                        <p className="sign-in-credentials__label">
                                            {
                                                securityContent.MFASmsAuthenticationSectionLabel
                                            }
                                        </p>
                                        <p className="sign-in-credentials__value">
                                            {!hasSmsAuthentication && (
                                                <span>
                                                    {
                                                        securityContent.MFASmsAuthenticationDescriptionText
                                                    }
                                                </span>
                                            )}
                                            {hasSmsAuthentication && (
                                                <span>
                                                    {maskedPhoneNumber(
                                                        mfaSms.value
                                                    )}
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    {!hasSmsAuthentication && (
                                        <LinkButton
                                            label={
                                                securityContent.addButtonText
                                            }
                                            ariaLabel={`${securityContent.addButtonText} ${securityContent.MFASmsAuthenticationSectionLabel}`}
                                            dataTestId="sms-authentication-add-button"
                                            class="edit-link"
                                            onClick={() => {
                                                fireAnalytics(
                                                    'signInCredentialsAddOnclickEvent'
                                                );
                                                cacheService.evictProfileServiceCache();
                                                authenticationService.addMFASmsAuthentication();
                                            }}
                                            id="sms-authentication-add-button"
                                            ariaHidden={false}
                                            tabIndex={0}
                                        />
                                    )}

                                    {hasSmsAuthentication && (
                                        <div className="cta-button-box">
                                            <LinkButton
                                                label={
                                                    securityContent.editButtonText
                                                }
                                                ariaLabel={`${securityContent.editButtonText} ${securityContent.MFASmsAuthenticationSectionLabel}`}
                                                dataTestId="sms-authentication-edit-button"
                                                class="edit-link"
                                                onClick={() => {
                                                    fireAnalytics(
                                                        'signInCredentialsEditOnclickEvent'
                                                    );
                                                    cacheService.evictProfileServiceCache();

                                                    if (!userIdTypeIsEmail) {
                                                        authenticationService.changeUsername();
                                                    } else {
                                                        authenticationService.changeMFASmsAuthentication();
                                                    }
                                                }}
                                                id="sms-authentication-edit-button"
                                                ariaHidden={false}
                                                tabIndex={0}
                                            />
                                            <LinkButton
                                                label={
                                                    securityContent.removeButtonText
                                                }
                                                ariaLabel={`${securityContent.removeButtonText} ${securityContent.MFASmsAuthenticationSectionLabel}`}
                                                dataTestId="sms-authentication-remove-button"
                                                class="edit-link"
                                                onClick={() => {
                                                    fireAnalytics(
                                                        'signInCredentialsRemoveOnclickEvent'
                                                    );
                                                    deleteMFAOption(
                                                        mfaSms.type
                                                    );
                                                }}
                                                id="sms-authentication-remove-button"
                                                ariaHidden={false}
                                                tabIndex={0}
                                            />
                                        </div>
                                    )}
                                </div>
                                <hr />
                            </div>
                        ) : null}
                    </>
                ) : (
                    <SignInAndSecurityErrorContent
                        securityContent={securityContent}
                        location={location}
                        setShowErrorPage={setShowErrorPage}
                    />
                )}
            </div>
        </>
    ) : null;
};

export default SignInAndSecurity;
