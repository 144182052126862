import { useContent } from '@hooks/use-server-content';

export interface VoiBannerContent {
    hide: boolean;
    headerText: string;
    vinText: string;
    dealerNoteText: string;
    primaryButtonLabel: string;
    primaryButtonAriaLabel: string;
    secondaryButtonLabel: string;
    secondaryButtonHref: string;
    secondaryButtonAriaLabel: string;
}

export const useVoiBannerContent = (): VoiBannerContent | null => {
    const [content, getValueByName] = useContent('common', 'voi-banner');
    let contentFragment: any = {};
    content?.elements.forEach((element) => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as VoiBannerContent;
};
