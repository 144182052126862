import React, { Fragment, useEffect, useState } from 'react';
import { SearchBar } from '@sections/index';
import MarketingOptionSection, {
    MarketingOptionContent,
} from '@sections/marketing-options/marketing-options-section';
import './marketing-options-view.scss';
import AuthenticationService from '@services/authentication-service/authentication-service';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { useAnalytics } from '@hooks/use-analytics';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useExperienceContent } from '@hooks/use-server-content';
import { useReturnButtonContent } from '@sections/return-button/hook/use-return-button-content';
import {
    useProfileData,
    useProfileDataForWebview,
} from '@sections/account-portal/hooks/use-profile-data';
import { useDynamicDropdownContent } from '@sections/personal-information/hooks/use-dynamic-dropdown-content';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { findPathByAlias } from '@routes/routesList';
import { useLocation } from 'react-router-dom';

const MarketingOptionsView = () => {
    useAnalytics('ownerAccountCommPrefs');
    const appConfig = new AppConfigurationService();
    const authenticationService = new AuthenticationService();
    const returnButtonContent = useReturnButtonContent();
    const [pageLoaded, setLoaded] = useState(false);
    const location = useLocation();
    const isWebview: boolean = location.search.includes('webview');
    const profile = isWebview ? useProfileDataForWebview() : useProfileData();
    const [marketingOptionsContent] =
        useExperienceContent<MarketingOptionContent>(
            'common',
            'marketing-options',
            'communicationprefere'
        );
    const dynamicDropdownContent = useDynamicDropdownContent(
        appConfig.get2LetterCountryCode()
    );
    authenticationService.updateState(findPathByAlias('MarketingOptionsView'));
    const scrollUtil = new ScrollUtil();

    useEffect(() => {
        if (!isWebview) {
            authenticationService
                .onIsAuthenticated()
                .then((isAuthenticated: boolean) => {
                    const win = window as any;
                    const webviewData =
                        win !== undefined && typeof window !== undefined
                            ? (window as any).SERVER_CONTEXT?.webviewData
                            : {};
                    if (isAuthenticated) {
                        setLoaded(true);
                    } else if (isWebview) {
                        setLoaded(true);
                        return {
                            access_token: webviewData.accessToken,
                        };
                    } else {
                        authenticationService.login();
                    }
                });
            scrollUtil.scrollPageToTop();
        }
    }, []);

    useEffect(() => {
        if (isWebview && profile && marketingOptionsContent) {
            setLoaded(true);
        }
    }, [isWebview, profile, marketingOptionsContent]);

    return pageLoaded &&
        profile &&
        marketingOptionsContent &&
        returnButtonContent ? (
        <Fragment>
            <MarketingOptionSection
                showPostRegistrationForm={false}
                marketingOptionsContent={marketingOptionsContent}
                profileData={profile}
                dynamicDropdownContent={dynamicDropdownContent}
                returnButtonContent={returnButtonContent}
            />
            {!isWebview && <SearchBar />}
        </Fragment>
    ) : (
        <ActivityIndicator className={'full-height'} />
    );
};
export default MarketingOptionsView;
