import {
    SmartTilesFragment,
    transformSmartTileContent,
} from '@models/experiencefragments/smarttiles';

import SmartTileIcon from '@smart-tiles/smart-tile-icon/smart-tile-icon';
import React from 'react';

import './smart-tile-grid.scss';

import { useExperienceContent } from '@hooks/use-server-content';
import SmartTileFPR from '@smart-tiles/smart-tile-fpr/smart-tile-fpr';
import SmartTileAccount from '@smart-tiles/smart-tile-account/smart-tile-account';
import SmartTileConnectedServices from '@smart-tiles/smart-tile-connected-services/smart-tile-connected-services';
import { useLoyaltyPointsDataForTile } from '../account-portal/hooks/use-loyalty-points-data-for-tile';
import SmartTileFordCredit from '@smart-tiles/smart-tile-ford-credit/smart-tile-ford-credit';
import SmartTileFPRLincoln from '@smart-tiles/smart-tile-fpr/smart-tile-fpr-lincoln';
import SmartTilePreferredDealer from '@smart-tiles/smart-tile-preferred-dealer/smart-tile-preferred-dealer';

const determineGridPage = (page: string): number => {
    let gridPage = 2;

    if (page === 'dashboard') {
        gridPage = 0;
    } else if (page === 'generic-grid') {
        gridPage = 1;
    }

    return gridPage;
};

export const SmartTileGrid = (props: any) => {
    const appConfig = props.appConfig;
    const isFord = appConfig.brand === 'ford';
    const [experienceContent] = useExperienceContent<SmartTilesFragment>(
        'common',
        'smart-tiles',
        'smarttiles'
    );

    const sectionIds = ['HR', 'G', 'UA'];
    let gridPage: number;

    const generateSmartTileGridClass = (): string => {
        let className = 'no-carousel';

        const totalVehicleCount: number =
            props.vehiclesCount + props.ordersCount;
        const largeMargin: boolean =
            (props.hasVehicles || props.hasOrders) && !props.hideCarousel;

        if (totalVehicleCount < 3 && !props.isMobile) {
            className = 'no-indicators-margin';
        } else if (largeMargin) {
            className = 'lg-margin';
        }

        return className;
    };

    const sectionId = experienceContent?.informationGridTitle
        ?.replace(/ /g, '-')
        .toLowerCase();

    const startIndex = 0;
    const ownerState = 1;
    const smartTilesArray = transformSmartTileContent(
        experienceContent,
        ownerState,
        props.page === 'DASHBOARD',
        startIndex
    );
    let filteredSmartTilesArray = smartTilesArray;

    if (isFord && !props.hasVehicles) {
        filteredSmartTilesArray = smartTilesArray.filter(
            (tile) => tile.type !== 'preferred-dealer'
        );
    }

    let tilesToRender;

    if (filteredSmartTilesArray) {
        gridPage = determineGridPage(props.className);

        tilesToRender = filteredSmartTilesArray.map((tile) => {
            if (
                tile.type === 'ford-pass-rewards' &&
                props.page === 'DASHBOARD'
            ) {
                if (appConfig.brand === 'lincoln') {
                    return (
                        <SmartTileFPRLincoln
                            key={tile.type}
                            ctaTitle={tile.dynamicCTATitle}
                            ctaHref={tile.dynamicCTAPath}
                            {...tile}
                            pageType={props.page}
                            isMobile={props.isMobile}
                            analyticEventName={tile.analyticEventName}
                            useLoyaltyPointsDataForTile={
                                useLoyaltyPointsDataForTile
                            }
                        />
                    );
                }
                return (
                    <SmartTileFPR
                        key={tile.type}
                        appConfig={appConfig}
                        ctaTitle={tile.dynamicCTATitle}
                        ctaHref={tile.dynamicCTAPath}
                        {...tile}
                        pageType={props.page}
                        isMobile={props.isMobile}
                        analyticEventName={tile.analyticEventName}
                        useLoyaltyPointsDataForTile={
                            useLoyaltyPointsDataForTile
                        }
                    />
                );
            } else if (
                tile.type === 'ford-credit' &&
                props.page === 'DASHBOARD'
            ) {
                return (
                    <SmartTileFordCredit
                        key={tile.type}
                        {...tile}
                        isMobile={props.isMobile}
                    />
                );
            } else if (
                tile.type === 'account-settings' &&
                props.page === 'DASHBOARD'
            ) {
                return (
                    <SmartTileAccount
                        key={tile.type}
                        ctaTitle={tile.dynamicCTATitle}
                        ctaHref={tile.dynamicCTAPath}
                        {...tile}
                        pageType={props.page}
                        isMobile={props.isMobile}
                        analyticEventName={'tilesAccountSettingsOnclickEvent'}
                    />
                );
            } else if (
                tile.type === 'subscription' &&
                props.page === 'DASHBOARD'
            ) {
                return (
                    <SmartTileConnectedServices
                        key={tile.type}
                        displayAsModal={true}
                        hasVehicles={props.currentVehiclesCount > 0}
                        vehiclesCount={props.vehiclesCount}
                        {...tile}
                        pageType={props.page}
                        isMobile={props.isMobile}
                        analyticEventName={tile.analyticEventName}
                        updateAddVehicleStatusWithVin={
                            props.updateAddVehicleStatusWithVin
                        }
                        setIsLoading={props.setIsLoading}
                        connectedServicesModalContent={
                            props.connectedServicesModalContent
                        }
                        addVehicleContent={props.addVehicleContent}
                        currentVehiclesCount={props.currentVehiclesCount}
                        vehiclesOnOrder={props.vehiclesOnOrder}
                        fromConnectedServicesParam={
                            props.fromConnectedServicesParam
                        }
                        setCurrentVehiclesCount={props.setCurrentVehiclesCount}
                        garageVehicles={props.garageVehicles}
                        openAddVehicleModal={props.openAddVehicleModal}
                        handleAddVehicleModal={props.handleAddVehicleModal}
                        setIsNewVehicleAdded={props.setIsNewVehicleAdded}
                    />
                );
            } else if (
                tile.type === 'preferred-dealer' &&
                props.page === 'DASHBOARD'
            ) {
                return (
                    <SmartTilePreferredDealer
                        key={tile.type}
                        hasVehicles={props.currentVehiclesCount > 0}
                        {...tile}
                        pageType={props.page}
                        isMobile={props.isMobile}
                        analyticEventName={tile.analyticEventName}
                        setIsLoading={props.setIsLoading}
                        preferredDealerModalContent={
                            props.preferredDealerModalContent
                        }
                        addVehicleContent={props.addVehicleContent}
                        currentVehiclesCount={props.currentVehiclesCount}
                        vehiclesOnOrder={props.vehiclesOnOrder}
                        fromPreferredDealerParam={
                            props.fromPreferredDealerParam
                        }
                        setCurrentVehiclesCount={props.setCurrentVehiclesCount}
                        garageVehicles={props.garageVehicles}
                        openAddVehicleModal={props.openAddVehicleModal}
                        handleAddVehicleModal={props.handleAddVehicleModal}
                    />
                );
            } else
                return (
                    <SmartTileIcon
                        key={tile.staticTitle}
                        {...tile}
                        isMobile={props.isMobile}
                        pageType={props.page}
                    />
                );
        });
    } else {
        tilesToRender = null;
    }

    return (
        <>
            {experienceContent && (
                <div
                    className={`smart-tile-grid ${generateSmartTileGridClass()}`}
                    id={sectionId}
                    data-testid="smart-tile-grid"
                >
                    <div className={`fds-layout-grid`}>
                        <h2
                            className={`${
                                !props.isMobile
                                    ? 'fmc-type--heading3'
                                    : 'fmc-type--heading5'
                            } fmc-mb-6 fmc-mb-5-xs fmc-mb-5-sm fmc-pb-1 fmc-pb-0-xs`}
                        >
                            {experienceContent.informationGridTitle}
                        </h2>
                        <div className="information-grid">
                            {tilesToRender
                                ? tilesToRender.map((tile, index) => {
                                      return (
                                          <div
                                              className={`${
                                                  isFord
                                                      ? 'smart-tile__clickable'
                                                      : ''
                                              }`}
                                              key={index}
                                              id={
                                                  sectionIds[gridPage] +
                                                  (index + 1)
                                              }
                                          >
                                              {tile}
                                          </div>
                                      );
                                  })
                                : 'Unavailable'}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
