import { useContent } from '@hooks/use-server-content';

export interface PreferredDealerDrawerContent {
    headerText: string;
    subtitleText: string;
    editButtonLabelText: string;
    editButtonAriaLabel: string;
    selectButtonLabelText: string;
    selectButtonAriaLabel: string;
    partsAndAccessoriesHeaderText: string;
    partsAndAccessoriesSubcopy: string;
    dealerInfoRetrievalError: string;
}

export const usePreferredDealerDrawerContent =
    (): PreferredDealerDrawerContent | null => {
        const [content, getValueByName] = useContent(
            'common',
            'preferred-dealer-drawer'
        );
        const contentFragment: any = {};
        if (content) {
            content?.elements.forEach((element) => {
                contentFragment[`${element.name}`] = getValueByName(
                    `${element.name}`
                );
            });
        }
        return contentFragment as PreferredDealerDrawerContent;
    };
