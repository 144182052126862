import React from 'react';
import { useTerritoryContent } from './hooks/use-country-content';
import {
    EU_COUNTRIES_WITH_THREE_DIGIT_COUNTRY_CODES,
    getFieldsConfig,
} from '@utils/values-by-country/value-by-country';
import { EU_COUNTRIES, IMG_COUNTRIES } from '@constants';
import { useTitleContent } from './hooks/use-title-content';

export interface Props {
    [key: string]: any;
}

const PersonalInformationDetails = (props: Props) => {
    const {
        user,
        countryCode,
        countryName,
        contentFragments,
        additionalAddress,
        isEdit,
        userIdentity,
    } = props;
    const experienceFragmentTerritory = useTerritoryContent(countryCode);
    const experienceFragmentTitles = useTitleContent(countryCode);
    const accountSettingLabels = contentFragments;
    const fieldsConfig: any = getFieldsConfig(countryCode);
    const isEu: boolean = EU_COUNTRIES.includes(countryCode);
    const firstFourCharactersMobile = user?.mobilePhoneNumber?.slice(0, 4);
    const firstFourCharactersPrimary = user?.phoneNumber?.slice(0, 4);

    const getAddress2FromProfileResponse = (additionalAddressList: any[]) => {
        if (Array.isArray(additionalAddressList)) {
            return additionalAddressList.find(
                (addressObject) =>
                    addressObject.id?.toLowerCase() === 'address2'
            );
        }
        return null;
    };
    const userAdditionalAddress =
        getAddress2FromProfileResponse(additionalAddress);

    // use display name for State instead of API value
    const getConvertedState = () => {
        let stateValue = '';
        if (user.state) {
            const filteredStates = experienceFragmentTerritory?.options.filter(
                (option) => option.value === user.state
            );
            stateValue = filteredStates[0]?.name;
        }
        return stateValue;
    };
    const getConvertedTitle = () => {
        let titleValue = '';
        if (countryCode === 'th' && user.title) {
            return user.title;
        } else if (user.title) {
            const filteredTitles = experienceFragmentTitles?.options.filter(
                (option) => option.titleValue === user.title
            );
            titleValue = filteredTitles[0]?.titleName || '';
        }
        return titleValue;
    };

    // display phone number for all countries with the corresponding formats
    const getFormattedPhoneNumber = () => {
        let phoneNumber;
        const isEuAndHasNumber = isEu && user.phoneNumber;
        const isNumberHasCountryCode =
            user.phoneNumber && user.phoneNumber[0] == '+';
        if (IMG_COUNTRIES.includes(countryCode) && user.phoneNumber) {
            phoneNumber =
                user.phoneNumber &&
                user.phoneNumber?.charAt(0) != '0' &&
                `0${user.phoneNumber}`;
        } else if (
            isEuAndHasNumber &&
            EU_COUNTRIES_WITH_THREE_DIGIT_COUNTRY_CODES.includes(
                firstFourCharactersPrimary
            )
        ) {
            phoneNumber =
                isNumberHasCountryCode && user.phoneNumber[4] == '0'
                    ? user.phoneNumber.slice(0, 4) + user.phoneNumber.slice(5)
                    : user.phoneNumber;
            phoneNumber =
                phoneNumber?.slice(0, 4) +
                ' ' +
                phoneNumber?.slice(4, 8) +
                ' ' +
                phoneNumber?.slice(8);
        } else if (isEuAndHasNumber) {
            phoneNumber =
                isNumberHasCountryCode && user.phoneNumber[3] == '0'
                    ? user.phoneNumber.slice(0, 3) + user.phoneNumber.slice(4)
                    : user.phoneNumber;
            phoneNumber =
                phoneNumber &&
                phoneNumber?.slice(0, 3) +
                    ' ' +
                    phoneNumber?.slice(3, 7) +
                    ' ' +
                    phoneNumber?.slice(7);
        } else if (user.phoneNumber) {
            phoneNumber =
                user.phoneNumber?.slice(0, 3) +
                '-' +
                user.phoneNumber?.slice(3, 6) +
                '-' +
                user.phoneNumber?.slice(6);
        } else {
            phoneNumber = '-';
        }
        return phoneNumber;
    };

    const formattedPhoneNumber = getFormattedPhoneNumber();

    // display Mobile phone number for all countries with the corresponding formats
    const getFormattedMobilePhoneNumber = () => {
        let mobilePhoneNumber;
        const isEuAndHasNumber = isEu && user.mobilePhoneNumber;
        const isNumberHasCountryCode =
            user.mobilePhoneNumber && user.mobilePhoneNumber[0] == '+';
        if (
            isEuAndHasNumber &&
            EU_COUNTRIES_WITH_THREE_DIGIT_COUNTRY_CODES.includes(
                firstFourCharactersMobile
            )
        ) {
            mobilePhoneNumber =
                isNumberHasCountryCode && user.mobilePhoneNumber[4] == '0'
                    ? user.mobilePhoneNumber.slice(0, 4) +
                      user.mobilePhoneNumber.slice(5)
                    : user.mobilePhoneNumber;
            mobilePhoneNumber =
                mobilePhoneNumber &&
                mobilePhoneNumber?.slice(0, 4) +
                    ' ' +
                    mobilePhoneNumber.slice(4, 8) +
                    ' ' +
                    mobilePhoneNumber.slice(8);
        } else if (isEuAndHasNumber) {
            mobilePhoneNumber =
                isNumberHasCountryCode && user.mobilePhoneNumber[3] == '0'
                    ? user.mobilePhoneNumber.slice(0, 3) +
                      user.mobilePhoneNumber.slice(4)
                    : user.mobilePhoneNumber;
            mobilePhoneNumber =
                mobilePhoneNumber &&
                mobilePhoneNumber?.slice(0, 3) +
                    ' ' +
                    mobilePhoneNumber?.slice(3, 7) +
                    ' ' +
                    mobilePhoneNumber?.slice(7);
        } else if (user.mobilePhoneNumber) {
            mobilePhoneNumber =
                user.mobilePhoneNumber?.slice(0, 3) +
                '-' +
                user.mobilePhoneNumber?.slice(3, 6) +
                '-' +
                user.mobilePhoneNumber?.slice(6);
        } else {
            mobilePhoneNumber = '-';
        }
        return mobilePhoneNumber;
    };

    const formattedMobilePhoneNumber = getFormattedMobilePhoneNumber();

    // display second last name for mexico alone
    const secondLastName =
        countryCode === 'mx' && user.secondLastName ? user.secondLastName : '';

    const getUserAddress1 = () => {
        const userAddress1 = [];
        if (user.address1) {
            userAddress1.push(user.address1);
        }
        if (user.address2) {
            userAddress1.push(user.address2);
        }
        if (user.city) {
            userAddress1.push(user.city);
        }
        if (isEu) {
            if (user.state) {
                userAddress1.push(user.state);
            }
            if (user.zip) {
                userAddress1.push(user.zip);
            }
            if (countryName) {
                userAddress1.push(countryName);
            }
        } else {
            if (
                IMG_COUNTRIES.includes(countryCode) &&
                experienceFragmentTerritory
            ) {
                if (getConvertedState()) {
                    userAddress1.push(getConvertedState());
                }
            } else if (user.state) {
                userAddress1.push(user.state);
            }
            if (user.zip) {
                userAddress1.push(user.zip);
            }
        }

        return userAddress1;
    };

    const getName = (): string => {
        let fullName: string;

        if (countryCode === 'vn' && experienceFragmentTitles) {
            fullName = `${user.firstName}`;
        } else {
            fullName = `${user.firstName} ${
                user.middleName ? user.middleName + ' ' : ''
            } ${user.lastName} ${secondLastName}`;
        }

        return experienceFragmentTitles && getConvertedTitle()
            ? `${getConvertedTitle()} ${fullName}`
            : fullName;
    };

    const isImgMobilePhoneRegions = IMG_COUNTRIES.includes(countryCode);

    const userAddress1Value = getUserAddress1();
    return (
        <div
            className="personal-information-view user-personal-info-container fds-layout-grid fmc-mb-4"
            data-testid="personal-information-details"
            aria-expanded={isEdit}
        >
            <div className="user-information-wrapper fds-layout-grid__inner fmc-pt-7">
                <h3 className="fmc-type--heading6 fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-4 fmc-mb-3-xs fmc-pb-1-xs">
                    {accountSettingLabels?.name}
                </h3>
                <div
                    className="fmc-type--body1 fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-8"
                    data-testid="personal-information-view-name-value"
                >
                    {getName()}
                </div>
            </div>
            {/* Show National ID if label is authored */}
            {accountSettingLabels && accountSettingLabels.nationalIdName && (
                <div
                    className="user-information-wrapper fds-layout-grid__inner fmc-pt-5"
                    data-testid="national-id-details-view"
                >
                    <h3 className="fmc-type--heading6 fds-layout-grid__cell--span-4 fmc-mb-3-xs fmc-pb-1-xs">
                        {accountSettingLabels?.nationalIdName}
                    </h3>
                    <div className="fmc-type--body1 fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-8">
                        {userIdentity?.identificationSubType &&
                        userIdentity?.identificationValue
                            ? `${userIdentity?.identificationSubType.toUpperCase()} ${
                                  userIdentity?.identificationValue
                              }`
                            : '-'}
                    </div>
                </div>
            )}

            <div className="user-information-wrapper fds-layout-grid__inner fmc-pt-5">
                <h3 className="fmc-type--heading6 user-information-name fds-layout-grid__cell--span-4 fmc-mb-3-xs fmc-pb-1-xs">
                    {accountSettingLabels?.addressOne}
                </h3>
                <div
                    className="fmc-type--body1 user-information-value fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-8"
                    data-testid="personal-information-view-address1-value"
                >
                    {userAddress1Value?.length
                        ? userAddress1Value.join(', ')
                        : '-'}
                </div>
            </div>
            {countryCode === 'us' ? (
                <div
                    className="user-information-wrapper fds-layout-grid__inner fmc-pt-5"
                    data-testid="address-two"
                >
                    <h3 className="fmc-type--heading6 user-information-name fds-layout-grid__cell--span-4 fmc-mb-3-xs fmc-pb-1-xs">
                        {accountSettingLabels.addressTwo}
                    </h3>
                    <div
                        className="fmc-type--body1 user-information-value fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-8"
                        data-testid="personal-information-view-address2-value"
                    >
                        {fieldsConfig?.additionalAddress &&
                        userAdditionalAddress
                            ? `${userAdditionalAddress?.addressLine1},${
                                  userAdditionalAddress.addressLine2
                                      ? ' ' +
                                        userAdditionalAddress.addressLine2 +
                                        ', '
                                      : ' '
                              }${userAdditionalAddress?.city}, ${
                                  userAdditionalAddress?.stateOrProvince
                              }, ${userAdditionalAddress?.postalCode}`
                            : '-'}
                    </div>
                </div>
            ) : null}
            {accountSettingLabels?.emailField && (
                <div className="user-information-wrapper fds-layout-grid__inner fmc-pt-5">
                    <h3 className="fmc-type--heading6 user-information-name fds-layout-grid__cell--span-4 fmc-mb-3-xs fmc-pb-1-xs">
                        {accountSettingLabels.email}
                    </h3>
                    <div
                        className="fmc-type--body1 user-information-value fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-8"
                        data-testid="personal-information-view-email-value"
                    >
                        {user.email || '-'}
                    </div>
                </div>
            )}
            {accountSettingLabels.phoneNumber && (
                <div className="user-information-wrapper fds-layout-grid__inner fmc-pt-5">
                    <h3 className="fmc-type--heading6 user-information-name fds-layout-grid__cell--span-4 fmc-mb-3-xs fmc-pb-1-xs">
                        {accountSettingLabels.phoneNumber}
                    </h3>
                    <div className="fmc-type--body1 user-information-value fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-8">
                        <div data-testid="personal-information-view-phone-number-value">
                            {isEu
                                ? formattedMobilePhoneNumber
                                : formattedPhoneNumber}
                        </div>

                        {!isImgMobilePhoneRegions && (
                            <div
                                className="fmc-pt-3"
                                data-testid="personal-information-view-phone-number-value"
                            >
                                {isEu
                                    ? formattedPhoneNumber
                                    : formattedMobilePhoneNumber}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PersonalInformationDetails;
