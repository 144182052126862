import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import {
    DSL_API_PATH,
    DSL_BASE_URL,
    EU_COUNTRIES,
    EU_VPHD_FAKE_VIN,
    generateRandomEuVin,
    VALID_ORDER_STATUSES,
} from '@constants';
import { HttpsProxyAgent } from 'https-proxy-agent';
import httpAdapter from 'axios/lib/adapters/http';
import {
    CONFIG_FOR_ORDERS_TO_FILTER_OUT,
    GobOrdersItem,
    OrderedVehiclesItem,
    SapOrdersItem,
} from '@models/orders-and-reservations';
import { ORDER_TYPE } from '@sections/account-portal/components/vehicle-carousel/vehicle-order-card/vehicle-order-card';

export interface OrderedVehiclesResponse {
    orders: OrderedVehiclesItem[];
    gobOrders?: GobOrdersItem[];
    sapOrders?: SapOrdersItem[];
}

export interface OrderStatusCodes {
    [market: string]: {
        [status: string]: string[];
    };
}

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export default class OrdersService {
    private httpService: any;
    private appConfig: AppConfigurationService = new AppConfigurationService();
    private countryCode = this.appConfig.current3LetterCountryCapitalizedCode;
    private url = DSL_BASE_URL + DSL_API_PATH.RESERVATIONS;
    private isUS: boolean = this.appConfig.get2LetterCountryCode() === 'us';
    private isCA: boolean = this.appConfig.get2LetterCountryCode() === 'ca';
    private isEU: boolean = EU_COUNTRIES.includes(
        this.appConfig.get2LetterCountryCode()
    );

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public getOrderedVehicles = async (userGuid?: string) => {
        return this.httpService
            .get(`${this.url + '?countryCode=' + this.countryCode}`, true, {
                headers:
                    this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
            })
            .then((response) => {
                return this.transformOrdersAndReservationsResponse(
                    response.data,
                    userGuid
                );
            })
            .catch((e) => {
                throw new Error('Orders service failure - Status: ', e);
            });
    };

    private checkForCX740 = (sapOrder: SapOrdersItem) => {
        const catalogId = sapOrder.entries[0].product.catalogId.split('-');
        const isCX740: boolean = catalogId[3].includes('CX740');

        if (isCX740) {
            return {
                id: sapOrder.code,
                imageUrl: sapOrder.entries[0].product.imageUrl,
                catalogId: sapOrder.entries[0].product.catalogId,
                make: 'ford',
                model: 'CX740',
                orderType: sapOrder.orderType,
                purchaseDate: sapOrder.reservationDate,
                readyToOrder: sapOrder.entries[0].readyToOrder,
                status: sapOrder.status,
                vehicleStatusCode: sapOrder.entries[0].vehicleStatusCode,
                vin: sapOrder.vin,
                year:
                    sapOrder.entries[0].product.marketingIdentity?.modelYear.substring(
                        0,
                        4
                    ) || catalogId[2].substring(0, 4),
                showroomJourneyType: sapOrder.showroomJourneyType,
            };
        }
    };

    private convertSapOrder = (
        sapOrderInput: SapOrdersItem
    ): OrderedVehiclesItem => {
        return {
            id: sapOrderInput.code,
            imageUrl: sapOrderInput.entries[0].product.imageUrl,
            catalogId: sapOrderInput.entries[0].product.catalogId,
            make:
                sapOrderInput.entries[0].product.marketingIdentity?.brand ||
                null,
            model:
                sapOrderInput.entries[0].product.marketingIdentity?.nameplate ||
                null,
            orderType: sapOrderInput.orderType,
            purchaseDate: sapOrderInput.reservationDate,
            readyToOrder: sapOrderInput.entries[0].readyToOrder,
            status: sapOrderInput.status,
            vehicleStatusCode: sapOrderInput.entries[0].vehicleStatusCode,
            vin: sapOrderInput.vin,
            year:
                sapOrderInput.entries[0].product.marketingIdentity?.modelYear.substring(
                    0,
                    4
                ) || null,
            showroomJourneyType: sapOrderInput.showroomJourneyType,
        };
    };

    private convertGobOrder = (
        gobOrderInput: GobOrdersItem
    ): OrderedVehiclesItem => {
        let dealerOrderNumber: string;

        if (gobOrderInput.ecomId === null) {
            dealerOrderNumber = gobOrderInput.regionalIds
                .find((regionalId) => regionalId.regionalIdType === 'USOB')
                .regionalIdValue.split(' ')[2];
        }

        return {
            id: gobOrderInput.ecomId || dealerOrderNumber,
            catalogId: null,
            imageUrl: null,
            make: gobOrderInput.make,
            model: gobOrderInput.model,
            orderType: ORDER_TYPE.ORDER,
            purchaseDate: gobOrderInput.saleDate,
            readyToOrder: null,
            status: ORDER_TYPE.ORDERED,
            vehicleStatusCode: gobOrderInput.state,
            vin: gobOrderInput.vin,
            year: gobOrderInput.engModelYear?.substring(0, 4),
            showroomJourneyType: null,
        };
    };

    private convertOrderFromBothSapAndGob = (
        sapOrderInput: SapOrdersItem,
        gobOrderInput: GobOrdersItem
    ): OrderedVehiclesItem => {
        return {
            id: sapOrderInput.code,
            imageUrl: sapOrderInput.entries[0].product.imageUrl,
            catalogId: sapOrderInput.entries[0].product.catalogId,
            make: sapOrderInput.entries[0].product.marketingIdentity?.brand,
            model: sapOrderInput.entries[0].product.marketingIdentity
                ?.nameplate,
            orderType: sapOrderInput.orderType,
            purchaseDate: sapOrderInput.reservationDate,
            readyToOrder: sapOrderInput.entries[0].readyToOrder,
            status: sapOrderInput.status,
            vehicleStatusCode: EU_COUNTRIES.includes(
                this.appConfig.get2LetterCountryCode()
            )
                ? sapOrderInput.entries[0].vehicleStatusCode
                : gobOrderInput.state,
            vin: gobOrderInput.vin,
            year: sapOrderInput.entries[0].product.marketingIdentity?.modelYear.substring(
                0,
                4
            ),
            showroomJourneyType: sapOrderInput.showroomJourneyType,
        };
    };

    private getRegionCodeString = (): string => {
        if (this.isUS) return 'us';
        if (this.isCA) return 'ca';
        if (this.isEU) return 'eu';

        return '';
    };

    public transformOrdersAndReservationsResponse = (
        data: OrderedVehiclesResponse,
        userGuid: string
    ) => {
        const { gobOrders, sapOrders } = data;
        const finalOrdersAndReservations: OrderedVehiclesItem[] = [];
        let finalOrder: OrderedVehiclesItem;

        if (gobOrders?.length && sapOrders?.length) {
            for (const gobOrder of gobOrders) {
                for (const sapOrder of sapOrders) {
                    if (gobOrder.ecomId === sapOrder.code) {
                        finalOrder = this.convertOrderFromBothSapAndGob(
                            sapOrder,
                            gobOrder
                        );
                        finalOrder &&
                            finalOrdersAndReservations.push(finalOrder);
                    }
                }
                const filtered = finalOrdersAndReservations.filter(
                    (order) =>
                        order.id === gobOrder.ecomId ||
                        order.id === gobOrder.gobId
                );
                if (
                    !filtered.length &&
                    gobOrder.orderingDealerCountryCode ===
                        this.appConfig.current3LetterCountryCapitalizedCode
                ) {
                    finalOrder = this.convertGobOrder(gobOrder);

                    if (
                        finalOrder &&
                        VALID_ORDER_STATUSES(
                            this.getRegionCodeString()
                        ).includes(finalOrder.vehicleStatusCode)
                    ) {
                        finalOrdersAndReservations.push(finalOrder);
                    }
                }
            }

            for (const sapOrder of sapOrders) {
                const filteredSap = finalOrdersAndReservations.filter(
                    (order) => order.id === sapOrder.code
                );
                if (this.isEU && !filteredSap.length) {
                    finalOrder = this.checkForCX740(sapOrder);

                    if (!finalOrder) {
                        finalOrder = this.convertSapOrder(sapOrder);
                    }

                    finalOrdersAndReservations.push(finalOrder);
                } else if (!filteredSap.length) {
                    finalOrder = this.convertSapOrder(sapOrder);
                    finalOrdersAndReservations.push(finalOrder);
                }
            }
        } else if (gobOrders?.length) {
            let finalGobOrder: OrderedVehiclesItem;

            for (const gobOrder of gobOrders) {
                finalGobOrder = this.convertGobOrder(gobOrder);

                if (
                    VALID_ORDER_STATUSES(this.getRegionCodeString()).includes(
                        finalGobOrder.vehicleStatusCode
                    )
                ) {
                    finalOrdersAndReservations.push(finalGobOrder);
                }
            }
        } else if (sapOrders?.length || this.isEU) {
            let finalSapOrder: OrderedVehiclesItem;

            for (const sapOrder of sapOrders) {
                const cx740Order = this.checkForCX740(sapOrder);
                finalSapOrder = this.convertSapOrder(sapOrder);
                if (cx740Order) {
                    finalOrdersAndReservations.push(cx740Order);
                } else if (finalSapOrder) {
                    finalOrdersAndReservations.push(finalSapOrder);
                }
            }
        }

        const ordersIds = finalOrdersAndReservations.map(({ id }) => id);
        const uniqueFinalOrdersAndReservations =
            finalOrdersAndReservations.filter(
                ({ id }, index) => !ordersIds.includes(id, index + 1)
            );

        return {
            orders: uniqueFinalOrdersAndReservations.filter(
                (orderedVehicle) =>
                    orderedVehicle?.make ===
                        this.appConfig.brand.toUpperCase()[0] ||
                    orderedVehicle?.make === this.appConfig.brand.toLowerCase()
            ),
        } as OrderedVehiclesResponse;
    };

    public addFakeVINsToOrders(
        uniqueFinalOrdersAndReservations: OrderedVehiclesItem[]
    ): OrderedVehiclesItem[] {
        const vphdCount = uniqueFinalOrdersAndReservations.reduce(
            (accumulator, currentValue) =>
                currentValue.vehicleStatusCode === 'VPHD'
                    ? ++accumulator
                    : accumulator,
            0
        );
        uniqueFinalOrdersAndReservations.forEach((order) => {
            if (
                VALID_ORDER_STATUSES('eu').includes(order.vehicleStatusCode) &&
                !order.vin
            ) {
                if (vphdCount === 1 && order.vehicleStatusCode === 'VPHD') {
                    order.vin = EU_VPHD_FAKE_VIN;
                } else {
                    order.vin = generateRandomEuVin();
                }
                console.log(
                    `Order ID ${order.id} was given a mock VIN - ${order.vin}`
                );
            }
        });
        return uniqueFinalOrdersAndReservations;
    }

    public userMatchesGuidInConfig = (userGuid: string): boolean => {
        return !!CONFIG_FOR_ORDERS_TO_FILTER_OUT.find(
            (user) => user.userGuid === userGuid
        );
    };
}
