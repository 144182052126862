export default class AuthenticationService {
    mockCATBundle: any;

    constructor() {
        this.mockCATBundle = {
            access_token: '',
            cat1_token: '',
            refresh_token: '',
            UserProfile: { firstName: 'John' },
        };
    }

    public getCatBundle = (): any | null => {
        return this.mockCATBundle;
    };

    public getAccessToken = (): string | null => {
        return '';
    };

    public getSwapToken = (): any | null => {
        return this.mockCATBundle;
    };

    public onIsAuthenticated(): Promise<boolean> {
        return Promise.resolve(true);
    }

    public handlePostLogin = () => jest.fn();

    public login = () => jest.fn();

    public updateState = () => jest.fn();
}
