import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ErrorPage from '@sections/profile-error/error-page';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useProfileData } from '@sections/account-portal/hooks/use-profile-data';
import seoService from '@services/seo-service/seo-service';
import ServerContext from '@contexts/serverContext';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import SignInAndSecurity from '@sections/sign-in-and-security/sign-in-and-security';
import ReturnButton from '@sections/return-button/return-button';
import './sign-in-credentials-view.scss';
import {
    SecurityContent,
    useSecurityContent,
} from '@sections/sign-in-and-security/hook/use-security-content';
import MFAService, { MFAOptions } from '@services/mfa-service/mfa-service';
import HttpService from '@services/http-service/http-service';
import { findPathByAlias } from '@routes/routesList';
import AuthenticationService from '@services/authentication-service/authentication-service';
import {
    FORDPASS_AUTHORIZED_DEEPLINK,
    LINCOLNWAY_AUTHORIZED_DEEPLINK,
} from '@constants';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import { useAnalytics } from '@/hooks/use-analytics';

const SignInCredentialsView = () => {
    const { setNotificationContext } = useNotificationContext();
    useAnalytics('ownerAccountSignIn');
    const serverContext = useContext(ServerContext);
    const appConfig = new AppConfigurationService();
    const httpService = HttpService;
    const authenticationService = new AuthenticationService();
    const mfaService: MFAService = new MFAService(httpService, appConfig);
    const getUserProfileData = useProfileData();
    const securityContent: SecurityContent = useSecurityContent();
    const appInformationContent = useAppInformationContent();
    const scrollUtil = new ScrollUtil();
    const location = useLocation();
    const fromWebview =
        new URLSearchParams(useLocation().search).get('webview') || undefined;
    const fromAppLogout = new URLSearchParams(useLocation().search).get(
        'appLogout'
    );
    const [mfaOptions, setMfaOptions] = useState<MFAOptions[]>(null);
    const [userId, setUserId] = useState<string>(null);
    const [showErrorPage, setShowErrorPage] = useState<boolean>(false);

    const [profileServiceError, setProfileError] = useState({
        status: false,
        message: '',
    });
    const showSignInAndSecurity = Boolean(
        userId && mfaOptions && securityContent
    );

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
        if (fromAppLogout) {
            const brand = appConfig.getBrand();
            if (brand === 'ford')
                window.location.assign(FORDPASS_AUTHORIZED_DEEPLINK);
            else if (brand === 'lincoln')
                window.location.assign(LINCOLNWAY_AUTHORIZED_DEEPLINK);
        }
    }, []);

    authenticationService.updateState(
        findPathByAlias('SignInCredentialsView') + useLocation().search
    );

    seoService.set(
        {
            pageTitle: 'Sign In and Security',
            applicationName: appInformationContent?.applicationName,
            pageDescription: 'Sign In and Security Description',
        },
        serverContext
    );

    useEffect(() => {
        if (getUserProfileData?.message) {
            setProfileError({
                status: true,
                message: getUserProfileData.message,
            });
        } else if (getUserProfileData) {
            setUserId(getUserProfileData.profile.userId);

            scrollUtil.scrollPageToTop();
            setProfileError({
                status: false,
                message: '',
            });
            mfaService
                .getMFAOptions()
                .then((mfaServiceResponse) => {
                    setMfaOptions(mfaServiceResponse.mfaOptions);
                })
                .catch(() => {
                    setMfaOptions([]);
                });
        }
    }, [getUserProfileData]);

    return (
        <div className="sign-in-credentials-view">
            <ReturnButton
                returnButtonContent={{
                    returnText: showErrorPage
                        ? securityContent?.errorPageReturnButtonText
                        : securityContent?.returnButtonText,
                }}
                fromSignInAndSecurityErrorPage={showErrorPage}
                onClick={() => setShowErrorPage(false)}
            />

            {showSignInAndSecurity && (
                <SignInAndSecurity
                    userId={userId}
                    mfaService={mfaService}
                    mfaOptions={mfaOptions}
                    securityContent={securityContent}
                    fromWebview={!!fromWebview}
                    setMFAOptions={setMfaOptions}
                    showErrorPage={showErrorPage}
                    setShowErrorPage={setShowErrorPage}
                    location={location}
                />
            )}
            {!showSignInAndSecurity && profileServiceError.status ? (
                <ErrorPage />
            ) : null}
            {!showSignInAndSecurity && !profileServiceError.status ? (
                <ActivityIndicator className="full-height" />
            ) : null}
        </div>
    );
};

export default SignInCredentialsView;
