import { useContent } from '@hooks/use-server-content';

export interface ReturnButtonProps {
    returnText: string;
}

export const useReturnButtonContent = (): ReturnButtonProps | null => {
    const [content, getValueByName] = useContent('common', 'return-link');
    let contentFragment: any = {};
    content?.elements.forEach((element) => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as ReturnButtonProps;
};
