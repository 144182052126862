import React, { Fragment, useEffect, useState } from 'react';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { CacheService } from '@services/cache-service/cache-service';
import { ExperienceFragmentModel } from '@services/content-service/content-service.interface';
import UserAccountService from '@services/user-account-service/user-account-service';
import { PrimaryButton } from '@common/index';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { InputField, RadioButtonGroup } from '@common/form-fields/form-fields';
import Validator from '@utils/validator/validator';
import { MarketingOptionsMessagingProps } from '../marketing-options-messaging-modal/hook/use-marketing-options-messaging';
import { AccountSettingsFormsLabels } from '../personal-information/hooks/use-personal-information-content';
import {
    DynamicDropdown,
    DynamicDropdownContent,
    DynamicDropdownOptions,
} from '@common/dynamic-dropdown/dynamic-dropdown';
import {
    EU_COUNTRIES_WITH_THREE_DIGIT_COUNTRY_CODES,
    formatPhoneNumberByCountry,
} from '@utils/values-by-country/value-by-country';
import { Errors } from '../personal-information/personal-information';

interface DefaultData {
    address1: string | null;
    address2: string | null;
    city: string | null;
    zip: string | null;
    country?: string | null;
    mobilePhoneNumber: string | null;
}

interface Focus {
    [key: string]: boolean;
}

interface Options {
    optionName: string;
    optionDescription?: string;
    apiVariable?: string;
    optionShow: boolean;
}

interface MarketingOptionContent extends ExperienceFragmentModel {
    sectionHeading: string;
    sectionDescription: string;
    yesOptionLabel: string;
    noOptionLabel: string;
    selectAll: string;
    deselectAll: string;
    saveButtonLabel: string;
    contactDetailsModalHeader: string;
    options: Options[];
}

interface Props {
    dynamicDropdownContent: DynamicDropdownContent;
    marketingOptionsContent: MarketingOptionContent;
    options: { [key: string]: string };
    radioButtonOptions?: {};
    handleSave: (flag: boolean, data: { [key: string]: string }) => void;
    fieldsLabels: AccountSettingsFormsLabels;
    marketingOptionsMessagingContent: MarketingOptionsMessagingProps;
}

const defaultContactDetails = {
    address1: '',
    address2: '',
    city: '',
    zip: '',
    mobilePhoneNumber: null,
};

const defaultErrorObj = {
    address1: {
        status: false,
        message: '',
    },
    address2: {
        status: false,
        message: '',
    },
    city: {
        status: false,
        message: '',
    },
    zip: {
        status: false,
        message: '',
    },
    mobilePhoneNumber: {
        status: false,
        message: '',
    },
};

const classname = 'marketing-options';
const selected = '1';
const unSelected = '0';

const PostRegistrationFields = ({
    dynamicDropdownContent,
    marketingOptionsContent,
    options,
    radioButtonOptions,
    handleSave: savePreferences,
    fieldsLabels,
    marketingOptionsMessagingContent,
}: Props) => {
    const [selectedMobileCountry, setSelectedMobileCountry] =
        useState<DynamicDropdownOptions>(null);
    const inputValidations = {
        address1: {
            validationRules: ['validateNonEmpty', 'validateMatchesPattern'],
            validationPattern: fieldsLabels.addressPatternRules,
            label: fieldsLabels?.addressField,
        },
        address2: {
            validationRules: [],
            validationPattern: '',
            label: fieldsLabels?.addressTwoField,
        },
        city: {
            validationRules: ['validateNonEmpty'],
            validationPattern: '',
            label: fieldsLabels?.cityField,
        },
        zip: {
            validationRules: ['validateNonEmpty', 'validateMatchesPattern'],
            validationPattern: fieldsLabels?.postalCodePatternRules,
            label: fieldsLabels?.postalCodeField,
        },
        mobilePhoneNumber: {
            validationRules: selectedMobileCountry
                ? ['validateNonEmpty', 'validateMatchesPattern']
                : ['validateMatchesPattern'],
            validationPattern:
                selectedMobileCountry?.countryRegex ||
                fieldsLabels?.phoneNumberPatternRules,
            label: fieldsLabels?.mobilePhoneField,
        },
    };
    const [focus, setFocusToField] = useState<Focus>({
        countryCode: false,
        mobilePhoneNumber: false,
    });
    const [userData, setUserData] = useState<DefaultData>(
        defaultContactDetails
    );

    const [errors, handleErrors] = useState<Errors>(defaultErrorObj);
    const [optionValues, setOptionValues] = useState(options);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessageFromApiResponse, handleServerValidationMessage] =
        useState<any>(null);
    const appConfig = new AppConfigurationService();
    const country = appConfig.get3LetterCountryCode();

    const cacheService = new CacheService();

    // validation messages
    const validationMessages = {
        nonEmpty: fieldsLabels?.validationEnter,
        pattern: fieldsLabels?.validPhoneNumber,
    };

    const handleSelection = (value: any, buttonOptions: any) => {
        setOptionValues((prevState) => ({
            ...prevState,
            [buttonOptions.apiVariable]: value,
        }));
    };

    const getFormattedPhoneNumber = (phoneNumber: string) => {
        const firstFourCharacters = phoneNumber.slice(0, 4);
        const hasCountryCode = phoneNumber.charAt(0) == '+';
        const hasThreeLetterCountryCode =
            EU_COUNTRIES_WITH_THREE_DIGIT_COUNTRY_CODES.includes(
                firstFourCharacters
            );

        if (hasThreeLetterCountryCode && phoneNumber[4] === '0') {
            return phoneNumber.slice(0, 4) + phoneNumber.slice(5);
        } else if (hasCountryCode && phoneNumber[3] === '0') {
            return phoneNumber.slice(0, 3) + phoneNumber.slice(4);
        }

        return phoneNumber;
    };

    const handleChange = (e) => {
        const updateUserData = { ...userData };
        const fieldErrors = { ...errors };
        const { name: fieldName, value } = e.target;
        updateUserData[fieldName] = value;
        setUserData(updateUserData);
        const dataAttributes = { ...e.currentTarget.dataset };

        if (Object.keys(dataAttributes).length > 0) {
            const validationRules = dataAttributes.validationRules
                .split(',')
                .filter(Boolean);
            const fieldLabel = dataAttributes.label;
            const pattern = dataAttributes.pattern;
            validationRules.length > 0 &&
                (fieldErrors[fieldName] = Validator.checkValidations(
                    validationRules,
                    value,
                    fieldLabel,
                    pattern,
                    validationMessages
                ));
            handleErrors(fieldErrors);
        }
    };

    const getPayload = () => {
        const { address1, address2, city, zip, mobilePhoneNumber } = {
            ...userData,
        };
        const formattedMobilePhoneNumber =
            getFormattedPhoneNumber(mobilePhoneNumber);

        const payload = {
            address1: address1 ? address1.trim() : null,
            address2: address2 ? address2.trim() : null,
            country: country ? country.toUpperCase() : null,
            city: city ? city.trim() : null,
            mobilePhoneNumber: formattedMobilePhoneNumber,
            zip,
        };
        if (optionValues.MobilePermission != selected) {
            delete payload.mobilePhoneNumber;
        }
        if (optionValues.MailPermission != selected) {
            delete payload.address1;
            delete payload.address2;
            delete payload.country;
            delete payload.city;
            delete payload.zip;
        }
        return payload;
    };

    const isFormValid = (fieldErrors: Errors) => {
        const validateForm = Object.values(fieldErrors).filter(
            (err) => err.status
        );
        return validateForm.length === 0;
    };

    // validate all input fields on click of update button
    const validateAllFields = () => {
        const fieldErrors = { ...errors };
        const validate = { ...inputValidations };

        Object.entries(validate).forEach((field) => {
            const {
                validationRules: rules,
                validationPattern: pattern,
                label,
            } = field[1];
            const [fieldName] = field;

            fieldErrors[fieldName] = Validator.checkValidations(
                rules,
                userData[fieldName],
                label,
                pattern,
                validationMessages
            );
        });
        if (optionValues.MailPermission == unSelected) {
            fieldErrors.address1.status = false;
            fieldErrors.address2.status = false;
            fieldErrors.city.status = false;
            fieldErrors.zip.status = false;
        }
        if (optionValues.MobilePermission == unSelected) {
            fieldErrors.mobilePhoneNumber.status = false;
        }
        handleErrors(fieldErrors);
        return { ...fieldErrors };
    };

    const handleSubmit = async () => {
        const accountService = new UserAccountService();
        const fieldErrors = validateAllFields();
        const isInputsEnabled =
            optionValues.MailPermission == selected ||
            optionValues.MobilePermission == selected;

        if (isInputsEnabled && isFormValid(fieldErrors)) {
            setIsLoading(true);
            // save user preferences
            const payload = getPayload();
            // save user address and phone numbers
            const response = await accountService.updateUserPersonalInfo(
                payload
            );

            const isProfileUpdated =
                response.status === '200' && response.httpStatus === '200';

            if (isProfileUpdated) {
                setIsLoading(false);
                cacheService.evictProfileServiceCache();
                savePreferences(true, optionValues);
            }
            // Handle server side validation
            if (response.httpStatus === '400') {
                if (response.error) {
                    const { error: { message = '' } = {} } = response;
                    message && handleServerValidationMessage(message);
                }
            }
            setIsLoading(false);
        }
        if (!isInputsEnabled) {
            savePreferences(true, optionValues);
        }
    };

    useEffect(() => {
        setUserData((user) => {
            user.mobilePhoneNumber =
                formatPhoneNumberByCountry(
                    appConfig.get2LetterCountryCode(),
                    user.mobilePhoneNumber,
                    selectedMobileCountry?.phoneNumberCountryCode
                ) || '';
            return user;
        });
        if (!selectedMobileCountry) {
            handleErrors({ mobilePhoneNumber: { status: false } });
        }
    }, [selectedMobileCountry, setSelectedMobileCountry]);

    const getInputField = (name: string, value: string) => {
        if (name === 'MobilePermission' && value == selected) {
            return (
                <div className="">
                    <DynamicDropdown
                        dynamicDropdownContent={dynamicDropdownContent}
                        focus={focus}
                        error={errors}
                        setFocusToField={setFocusToField}
                        accountSettingsFormsLabels={fieldsLabels}
                        field="mobilePhoneNumber"
                        selectedCountry={selectedMobileCountry}
                        setSelectedCountry={setSelectedMobileCountry}
                    />
                    <InputField
                        id="mobilePhoneNumber"
                        name="mobilePhoneNumber"
                        label={inputValidations['mobilePhoneNumber'].label}
                        showLabel={false}
                        handleChange={handleChange}
                        value={formatPhoneNumberByCountry(
                            appConfig.get2LetterCountryCode(),
                            userData.mobilePhoneNumber,
                            selectedMobileCountry?.phoneNumberCountryCode
                        )}
                        className={`${classname}-user-input-field`}
                        validationRules={
                            inputValidations['mobilePhoneNumber']
                                .validationRules
                        }
                        validationPattern={
                            inputValidations['mobilePhoneNumber']
                                .validationPattern
                        }
                        error={errors['mobilePhoneNumber']}
                        onFocus={() =>
                            setFocusToField({ mobilePhoneNumber: true })
                        }
                        onBlur={() =>
                            setFocusToField({ mobilePhoneNumber: false })
                        }
                        placeholder={
                            inputValidations['mobilePhoneNumber'].label
                        }
                    />
                </div>
            );
        } else if (name === 'MailPermission' && value == selected) {
            return (
                <Fragment>
                    <div className={`${classname}-address-fields-container`}>
                        <InputField
                            id={`address1`}
                            label={inputValidations['address1'].label}
                            showLabel={false}
                            handleChange={handleChange}
                            name={'address1'}
                            value={userData['address1']}
                            className={`${classname}-user-input-field`}
                            validationRules={
                                inputValidations['address1'].validationRules
                            }
                            validationPattern={
                                inputValidations['address1'].validationPattern
                            }
                            error={errors['address1']}
                            placeholder={inputValidations['address1'].label}
                        />
                        <InputField
                            id={`address2`}
                            label={inputValidations['address2'].label}
                            showLabel={false}
                            handleChange={handleChange}
                            name={'address2'}
                            value={userData['address2']}
                            className={`${classname}-user-input-field two-fields`}
                            validationRules={
                                inputValidations['address2'].validationRules
                            }
                            error={errors['address2']}
                            placeholder={inputValidations['address2'].label}
                        />
                    </div>
                    <div className={`${classname}-address-fields-container`}>
                        <InputField
                            id={`city`}
                            label={inputValidations['city'].label}
                            showLabel={false}
                            handleChange={handleChange}
                            name={'city'}
                            className={`${classname}-user-input-field`}
                            value={userData['city']}
                            validationRules={
                                inputValidations['city'].validationRules
                            }
                            error={errors['city']}
                            placeholder={inputValidations['city'].label}
                        />
                        <InputField
                            id={`zip`}
                            label={inputValidations['zip'].label}
                            showLabel={false}
                            handleChange={handleChange}
                            name={'zip'}
                            className={`${classname}-user-input-field two-fields`}
                            value={userData['zip']}
                            validationRules={
                                inputValidations['zip'].validationRules
                            }
                            validationPattern={
                                inputValidations['zip'].validationPattern
                            }
                            error={errors['zip']}
                            placeholder={inputValidations['zip'].label}
                        />
                    </div>
                    {errorMessageFromApiResponse && (
                        <div className="server-errors">
                            <span className="error-text">
                                {errorMessageFromApiResponse}
                            </span>
                        </div>
                    )}
                </Fragment>
            );
        } else return null;
    };

    const isSaveButtonDisabled = !Object.values(optionValues).some(
        (value) => value === null
    );
    return (
        <Fragment>
            {isLoading && (
                <ActivityIndicator
                    className={'fds-activity-indicator__center'}
                />
            )}
            <div className={`${classname}-wrapper`}>
                {marketingOptionsContent.options.map((option, index) => {
                    if (option.optionShow === true) {
                        return (
                            <div
                                className={`${classname}-list-wrapper`}
                                key={`option-${index}`}
                            >
                                <div
                                    className={`${classname}-list eu-post-registration`}
                                    key={`${index}-option`}
                                    data-testid="marketing-option"
                                >
                                    <div
                                        className={`${classname}-name-description-ctn`}
                                    >
                                        <div
                                            className={`${classname}-name fmc-mb-1-xs`}
                                        >
                                            {option.optionName}
                                        </div>
                                        {option.optionDescription && (
                                            <div
                                                className={`${classname}-description`}
                                            >
                                                {option.optionDescription}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <RadioButtonGroup
                                            RadioButtonOptions={
                                                radioButtonOptions[
                                                    option.apiVariable
                                                ]
                                            }
                                            defaultCheckedOption={
                                                optionValues[option.apiVariable]
                                            }
                                            handleChange={(value) =>
                                                handleSelection(value, option)
                                            }
                                        />
                                    </div>
                                </div>
                                {getInputField(
                                    option.apiVariable,
                                    optionValues[option.apiVariable]
                                )}
                            </div>
                        );
                    }
                })}
            </div>
            <div
                className={`${classname}-phone-disclaimer`}
                dangerouslySetInnerHTML={{
                    __html: marketingOptionsMessagingContent?.phoneNumberPrivacyDescription,
                }}
            />

            <div className={`${classname}-button-save`}>
                <PrimaryButton
                    onClick={handleSubmit}
                    disabled={!isSaveButtonDisabled}
                >
                    {marketingOptionsContent.saveButtonLabel}
                </PrimaryButton>
            </div>
        </Fragment>
    );
};

export default PostRegistrationFields;
