import { useContent } from '@hooks/use-server-content';

export interface VehicleCarouselContent {
    addvehiclebutton: string;
    addvehiclecardcta: string;
    addvehiclecarddescription: string;
    addvehiclecardheader: string;
    addvehicleimage: string;
    btnCancel: string;
    btnConfirm: string;
    btnUpdate: string;
    carddetailscta: string;
    carddetailsctaPath: string;
    defaultvehicleimage: string;
    deletevehiclemodalheader: string;
    editvehiclemodalheader: string;
    hide: boolean;
    nicknamefieldlabel: string;
    tooltipdeletetext: string;
    tooltipedittext: string;
    ofLabel: string;
    myLabel: string;
    updateVehicleBtnCancelAriaLabel: string;
    deleteVehicleBtnCancelAriaLabel: string;
    btnConfirmAriaLabel: string;
    btnUpdateAriaLabel: string;
    vehicleCardEditButtonAriaLabel: string;
    vehicleCardDeleteButtonAriaLabel: string;
    modalCloseButtonAriaLabel: string;
    getStartedAriaLabel: string;
    vinText: string;
    menuLink1LabelText: string;
    menuLink1CtaHref: string;
    menuLink1AriaLabel: string;
    menuLink1AnalyticEventName: string;
    menuLink2LabelText: string;
    menuLink2CtaHref: string;
    menuLink2AriaLabel: string;
    menuLink2AnalyticEventName: string;
    menuLink3LabelText: string;
    menuLink3CtaHref: string;
    menuLink3AriaLabel: string;
    menuLink3AnalyticEventName: string;
    menuLink4LabelText: string;
    menuLink4CtaHref: string;
    menuLink4AriaLabel: string;
    menuLink4AnalyticEventName: string;
    menuLink5LabelText: string;
    menuLink5CtaHref: string;
    menuLink5AriaLabel: string;
    menuLink5AnalyticEventName: string;
    menuLink6LabelText: string;
    menuLink6CtaHref: string;
    menuLink6AriaLabel: string;
    menuLink6AnalyticEventName: string;
    menuLink7LabelText: string;
    menuLink7CtaHref: string;
    menuLink7AriaLabel: string;
    menuLink7AnalyticEventName: string;
    menuLink8LabelText: string;
    menuLink8CtaHref: string;
    menuLink8AriaLabel: string;
    menuLink8AnalyticEventName: string;
    eComVehicleCardOrderNumberLabel?: string;
    eComVehicleCardReservationNumberLabel?: string;
    eComVehicleCardCtaLabel?: string;
    eComVehicleCardCtaHrefECOM?: string;
    eComVehicleCardCtaHrefECOMAlternate?: string;
    eComVehicleCardCtaHrefCVOT?: string;
    eComVehicleCardOrderTitle?: string;
    eComVehicleCardReservationTitle?: string;
    eComOrderStatusBoxText?: string;
    eComVehicleCardModelUnavailable?: string;
    eComVehicleCardStatusReservation?: string;
    eComVehicleCardStatusPlaceOrder?: string;
    eComVehicleCardStatusOrdered?: string;
    eComVehicleCardStatusInProduction?: string;
    eComVehicleCardStatusShipped?: string;
    eComVehicleCardCompletedPurchase?: string;
    eComVehicleCardDelivered?: string;
    orderCardEyfCreateHandoverChecklistLabel?: string;
    orderCardEyfCreateHandoverChecklistAriaLabel?: string;
    orderCardEyfViewHandoverChecklistLabel?: string;
    orderCardEyfViewHandoverChecklistAriaLabel?: string;
    orderCardEyfCTAHref?: string;
    gvtIconPath?: string;
    gvtMainCopy?: string;
    gvtSubCopy?: string;
    gvtCTAText?: string;
    gvtCTAHref?: string;
    gvtCTAAriaLabel?: string;
    mmotaOdometerMilesText: string;
    mmotaOdometerKilometersText: string;
    mmotaTirePressureLabelText?: string;
    mmotaTirePressureValueNormal?: string;
    mmotaTirePressureValueLow?: string;
    mmotaFuelLevelLabelText?: string;
    mmotaOilLifeLabelText?: string;
    mmotaOilLifeNormalValue?: string;
    mmotaOilLifeLowValue?: string;
    mmotaChargeLevelLabelText?: string;
    mmotaRemainingRangeLabelText?: string;
    deleteVehicleModalBody?: string;
    activeOrdersHeader?: string;
    orderLabelText?: string;
    orderDateLabelText?: string;
    orderItemsLabelText?: string;
    orderDetailsCtaText?: string;
    orderDetailsAriaLabel?: string;
    viewOrderHistoryCtaText?: string;
    viewOrderHistoryAriaLabel?: string;
    accessoriesImageUrl?: string;
    performancePartsImageUrl?: string;
}

export const useVehicleCarouselContent = (): VehicleCarouselContent | null => {
    const [content, getValueByName] = useContent('common', 'vehicle-carousel');
    const contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    return contentFragment as VehicleCarouselContent;
};
