import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MarketingOptionSection, {
    MarketingOptionContent,
} from '../marketing-options/marketing-options-section';
import '@views/marketing-options-view/marketing-options-view.scss';
import {
    useProfileData,
    useProfileDataForWebview,
} from '../account-portal/hooks/use-profile-data';
import { useReturnButtonContent } from '@sections/return-button/hook/use-return-button-content';
import { useExperienceContent } from '@hooks/use-server-content';
import { useDynamicDropdownContent } from '../personal-information/hooks/use-dynamic-dropdown-content';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { findPathByAlias } from '@routes/routesList';
import { useAccountSettingsFormsContent } from '@sections/personal-information/hooks/use-personal-information-content';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useMarketingOptionsMessaging } from '@sections/marketing-options-messaging-modal/hook/use-marketing-options-messaging';

const EuPostRegistrationRedirect = () => {
    const appConfig = new AppConfigurationService();
    const navigate = useNavigate();
    const location = useLocation();
    const returnButtonContent = useReturnButtonContent();
    const dynamicDropdownContent = useDynamicDropdownContent(
        appConfig.get2LetterCountryCode()
    );
    const [pageLoaded, setLoaded] = useState(false);
    const isWebview: boolean = location.search.includes('webview');
    const profile = isWebview ? useProfileDataForWebview() : useProfileData();
    const [marketingOptionsContent] =
        useExperienceContent<MarketingOptionContent>(
            'common',
            'marketing-options',
            'communicationprefere'
        );

    const fieldsLabels = useAccountSettingsFormsContent();
    const marketingOptionsMessagingContent = useMarketingOptionsMessaging();
    const [webviewStyling, setWebviewStyling] = useState(false);
    useEffect(() => {
        const { postRegistration = false }: any = location.state || {
            postRegistration: false,
        };
        setWebviewStyling(isWebview);
        // if user directly eu preference route then it will redirect to the marketing options page
        if (!postRegistration && !isWebview) {
            navigate(findPathByAlias('MarketingOptionsView'));
        }
        return () => {
            // replaces history state without re-render
            window.history.replaceState({}, document.title);
        };
    }, []);

    useEffect(() => {
        if (
            isWebview &&
            profile &&
            marketingOptionsContent &&
            fieldsLabels &&
            marketingOptionsMessagingContent
        ) {
            setLoaded(true);
        } else if (
            !isWebview &&
            profile &&
            marketingOptionsContent &&
            fieldsLabels &&
            marketingOptionsMessagingContent
        ) {
            setLoaded(true);
        }
    }, [
        isWebview,
        profile,
        marketingOptionsContent,
        fieldsLabels,
        marketingOptionsMessagingContent,
    ]);

    return pageLoaded ? (
        <div
            className={`marketing-preference-container${
                webviewStyling ? '-webview' : ''
            }`}
        >
            <MarketingOptionSection
                showPostRegistrationForm
                dynamicDropdownContent={dynamicDropdownContent}
                marketingOptionsContent={marketingOptionsContent}
                profileData={profile}
                returnButtonContent={returnButtonContent}
                fieldsLabels={fieldsLabels}
                marketingOptionsMessagingContent={
                    marketingOptionsMessagingContent
                }
            />
        </div>
    ) : (
        <ActivityIndicator className={'full-height'} />
    );
};

export default EuPostRegistrationRedirect;
