import React from 'react';
import { PreferredDealerContent } from '@views/preferred-dealer-view/hooks/use-preferred-dealer-content';

interface ReplaceDealerModalContentProps {
    preferredDealerContent: PreferredDealerContent;
}

const ReplaceDealerModalContent = (props: ReplaceDealerModalContentProps) => {
    return (
        <>
            <h2 data-testid="replace-modal-header-text">
                {props.preferredDealerContent.replaceMyDealerModalHeaderText}
            </h2>

            <p data-testid="replace-modal-body-text">
                {props.preferredDealerContent.replaceMyDealerModalBodyText}
            </p>
        </>
    );
};

export default ReplaceDealerModalContent;
